import React, { lazy, useMemo } from "react"
import { useRoutes, Navigate } from "react-router-dom"
import Loadable from "./Loadable"
import WrapperLayout from "./layouts/WrapperLayout"
import PerformanceReportLayout from "./layouts/PerformanceReportLayout"
import { accessControlFunc } from "src/components/accessControl"

const Locations = Loadable(lazy(() => import("src/pages/buildings")))
const BuildingSummaryPage = Loadable(
  lazy(() => import("src/pages/building/summary"))
)
const Building = Loadable(lazy(() => import("src/pages/building")))
const BuildingPerformance = Loadable(
  lazy(() => import("src/pages/building/performance"))
)
const BuildingServiceAdvisoriesPage = Loadable(
  lazy(() => import("src/pages/building/service-advisories"))
)

/* Equipment Pages */
const Equipment = Loadable(lazy(() => import("src/pages/building/equipment")))
const EquipmentSummaryPage = Loadable(
  lazy(() => import("src/pages/building/equipment/summary"))
)
const EquipmentPerformancePage = Loadable(
  lazy(() => import("src/pages/building/equipment/performance"))
)
const EquipmentDIReportPage = Loadable(
  lazy(() => import("src/pages/building/equipment/di-report"))
)
const EquipmentServiceAdvisoriesPage = lazy(() =>
  import("src/pages/building/equipment/service-advisories")
)
const EquipmentDigitalInspectionPage = lazy(() =>
  import("src/pages/building/equipment/digital-inspection")
)
const EquipmentDigitalInspectionPageV2 = lazy(() =>
  import("src/pages/building/equipment/digital-inspection-v2")
)
const DigitalInspectionReportV2 = lazy(() =>
  import("src/pages/building/DigitalInspectionReportV2/Digital-inspection-Report")
)
/* Admin Pages */
// Analytics pages
const AdminSettingsActionBar = Loadable(
  lazy(() => import("src/pages/admin-settings"))
)

const AnalyticsActionBar = Loadable(
  lazy(() => import("src/pages/admin-settings/analytics"))
)

const AnalyticsSetupPage = Loadable(
  lazy(() => import("src/pages/admin-settings/analytics/setup"))
)
const AnalyticsSubscriptionPage = Loadable(
  lazy(() => import("src/pages/admin-settings/analytics/subscription"))
)
const AnalyticsReprocessingPage = Loadable(
  lazy(() => import("src/pages/admin-settings/analytics/reprocessing"))
)
const AnalyticsExecuterPage = Loadable(
  lazy(() => import("src/pages/admin-settings/analytics/executer"))
)
const AnalyticsExecuterV2Page = Loadable(
  lazy(() => import("src/pages/admin-settings/analytics/executer-v2"))
)
const AnalyticsDebuggerPage = Loadable(
  lazy(() => import("src/pages/admin-settings/analytics/debugger"))
)
const AddUpdateAnalytics = Loadable(
  lazy(() => import("src/pages/admin-settings/analytics/add-update-analytic"))
)
const TimeStreamAnalytics = Loadable(
  lazy(() => import("src/pages/admin-settings/analytics/time-stream-analytics"))
)
// Entities Page and sub pages
const EntitiesActionBar = Loadable(
  lazy(() => import("src/pages/admin-settings/entities"))
)
const EntitiesPage = Loadable(
  lazy(() => import("src/pages/admin-settings/entities/entities-common-page"))
)

// Permissions Page and sub pages
const PermissionActionBar = Loadable(
  lazy(() => import("src/pages/admin-settings/permissions"))
)
const PermissionsBuildingPage = Loadable(
  lazy(() => import("src/pages/admin-settings/permissions/buildings"))
)
const PermissionsApplicationsPage = Loadable(
  lazy(() => import("src/pages/admin-settings/permissions/applications"))
)
const PermissionsUserTypesPage = Loadable(
  lazy(() => import("src/pages/admin-settings/permissions/user-types"))
)
const PermissionsUserPage = Loadable(
  lazy(() => import("src/pages/admin-settings/permissions/users"))
)
const PermissionGroupPage = Loadable(
  lazy(() => import("src/pages/admin-settings/permissions/groups"))
)
const UiPermissionsPage = Loadable(
  lazy(() => import("src/pages/admin-settings/permissions/ui-permissions"))
)
const PermissionGroupPermissionPage = Loadable(
  lazy(() => import("src/pages/admin-settings/permissions/group-permissions"))
)
const PermissionModal = Loadable(
  lazy(() => import("src/pages/admin-settings/permissions/permissions-modal"))
)

// Subscriptions Page and sub pages
const SubscriptionsActionBar = Loadable(
  lazy(() => import("src/pages/admin-settings/subscriptions"))
)

const SubscriptionOfferingsPage = Loadable(
  lazy(() => import("src/pages/admin-settings/subscriptions/offerings"))
)
const BuildingsSubscriptionPage = Loadable(
  lazy(() =>
    import("src/pages/admin-settings/subscriptions/building-subscriptions")
  )
)

const NotFound = Loadable(lazy(() => import("src/pages/notfound")))
const ProtectedRoute = Loadable(lazy(() => import("src/pages/protectedRoute")))
const Reports = Loadable(lazy(() => import("src/pages/building/reports")))
const EquipmentReports = Loadable(
  lazy(() => import("src/pages/building/equipment/reports"))
)

const AppRoutes = ({
  errorMessage,
  userAccess,
  userAccessLoaing: accessLoading
}) => {
  const access = accessControlFunc({ id: "cc.pages.adminSettings", userAccess })
  const routes = useRoutes([
    {
      path: "report",
      element: <PerformanceReportLayout />,
      children: [
        {
          path: "building/:buildingId/performance",
          element: <BuildingPerformance />
        },
        //building/95d50391-348e-4e96-8c6f-f34123085c2b/equipment/7f2b7e66-3716-4922-b6d4-a856cc3a6ee7/digital-inspection-report-v2
        {
          path: "building/:buildingId/equipment/:equipmentId/performance",
          element: <EquipmentPerformancePage />
        },
        {
          path: "building/:buildingId/equipment/:equipmentId/digital-inspection-report-v2",
          element: <DigitalInspectionReportV2 />
        },
        {
          path: "building/:buildingId/digital-inspection-report-v2",
          element: <DigitalInspectionReportV2 />
        },
      ]
    },
    
    {
      path: "DIreport",
      element: <PerformanceReportLayout />,
      children: [
        {
          path: "building/:buildingId/equipment/:equipmentId",
          element: <EquipmentDIReportPage />
        }
      ]
    },
    {
      path: "/",
      element: <WrapperLayout errorMessage={errorMessage} />,
      children: [
        {
          path: "/",
          element: <Navigate to="/buildings?view=tiles" replace />
        },
        {
          path: "buildings",
          element: <Locations />
        },
        {
          path: "building/:buildingId",
          element: <Building />,
          children: [
            {
              path: "",
              element: <Navigate to="summary" replace />
            },
            {
              path: "summary",
              element: <BuildingSummaryPage />
            },
            {
              path: "performance",
              element: <BuildingPerformance />
            },
            {
              path: "advisories",
              element: <BuildingServiceAdvisoriesPage />
            },
            {
              path: "reports",
              element: <Reports />
            },
            {
              path: "digital-inspection-report-v2",
              element: <DigitalInspectionReportV2 />
            },
          ]
        },
        {
          path: "building/:buildingId/equipment/:equipmentId",
          element: <Equipment />,
          children: [
            { path: "summary", element: <EquipmentSummaryPage /> },
            {
              path: "performance",
              element: <EquipmentPerformancePage />
            },
            {
              path: "advisories",
              element: <EquipmentServiceAdvisoriesPage />
            },
            {
              path: "digital-inspection",
              element: <EquipmentDigitalInspectionPage />
            },
            {
              path: "digital-inspection-v2",
              element: <EquipmentDigitalInspectionPageV2 />
            },
            {
              path: "digital-inspection-report-v2",
              element: <DigitalInspectionReportV2 />
            },
            {
              path: "reports",
              element: <EquipmentReports />
            }
          ]
        },
        {
          path: "/admin-settings",
          element: (
            <ProtectedRoute
              Route={AdminSettingsActionBar}
              access={access}
              accessLoading={accessLoading}
            />
          ),
          children: [
            {
              path: "permissions",
              element: (
                <ProtectedRoute
                  Route={PermissionActionBar}
                  access={access}
                  accessLoading={accessLoading}
                />
              ),
              children: [
                {
                  path: "buildings",
                  element: (
                    <ProtectedRoute
                      Route={PermissionsBuildingPage}
                      access={access}
                      accessLoading={accessLoading}
                      basePage="permissions"
                    />
                  ),
                  children: [
                    {
                      path: "modal",
                      element: (
                        <ProtectedRoute
                          Route={PermissionModal}
                          access={access}
                          accessLoading={accessLoading}
                        />
                      )
                    }
                  ]
                },
                {
                  path: "applications",
                  element: (
                    <ProtectedRoute
                      Route={PermissionsApplicationsPage}
                      access={access}
                      accessLoading={accessLoading}
                      basePage="permissions"
                    />
                  ),
                  children: [
                    {
                      path: "modal",
                      element: (
                        <ProtectedRoute
                          Route={PermissionModal}
                          access={access}
                          accessLoading={accessLoading}
                        />
                      )
                    }
                  ]
                },
                {
                  path: "user-types",
                  element: (
                    <ProtectedRoute
                      Route={PermissionsUserTypesPage}
                      access={access}
                      accessLoading={accessLoading}
                      basePage="permissions"
                    />
                  ),
                  children: [
                    {
                      path: "modal",
                      element: (
                        <ProtectedRoute
                          Route={PermissionModal}
                          access={access}
                          accessLoading={accessLoading}
                        />
                      )
                    }
                  ]
                },
                {
                  path: "group-permissions",
                  element: (
                    <ProtectedRoute
                      Route={PermissionsUserTypesPage}
                      access={access}
                      accessLoading={accessLoading}
                      basePage="permissions"
                    />
                  )
                },
                {
                  path: "users",
                  element: (
                    <ProtectedRoute
                      Route={PermissionsUserPage}
                      access={access}
                      accessLoading={accessLoading}
                    />
                  ),
                  children: [
                    {
                      path: "modal",
                      element: (
                        <ProtectedRoute
                          Route={PermissionModal}
                          access={access}
                          accessLoading={accessLoading}
                        />
                      )
                    }
                  ]
                },
                {
                  path: "groups",
                  element: (
                    <ProtectedRoute
                      Route={PermissionGroupPage}
                      access={access}
                      accessLoading={accessLoading}
                    />
                  ),
                  children: [
                    {
                      path: "modal",
                      element: (
                        <ProtectedRoute
                          Route={PermissionModal}
                          access={access}
                          accessLoading={accessLoading}
                        />
                      )
                    }
                  ]
                },
                {
                  path: "ui-permissions-demo",
                  element: (
                    <ProtectedRoute
                      Route={UiPermissionsPage}
                      access={access}
                      accessLoading={accessLoading}
                    />
                  )
                }
              ]
            },
            {
              path: "analytics",
              element: (
                <ProtectedRoute
                  Route={AnalyticsActionBar}
                  access={access}
                  accessLoading={accessLoading}
                />
              ),
              children: [
                {
                  path: "setup",
                  element: (
                    <ProtectedRoute
                      Route={AnalyticsSetupPage}
                      access={access}
                      accessLoading={accessLoading}
                    />
                  ),
                  children: [
                    {
                      path: "add-analytic",
                      element: (
                        <ProtectedRoute
                          Route={AddUpdateAnalytics}
                          access={access}
                          accessLoading={accessLoading}
                        />
                      )
                    },
                    {
                      path: "update-analytic",
                      element: (
                        <ProtectedRoute
                          Route={AddUpdateAnalytics}
                          access={access}
                          accessLoading={accessLoading}
                        />
                      )
                    }
                  ]
                },
                {
                  path: "subscribe",
                  element: (
                    <ProtectedRoute
                      Route={AnalyticsSubscriptionPage}
                      access={access}
                      accessLoading={accessLoading}
                    />
                  )
                },
                {
                  path: "reprocessing",
                  element: (
                    <ProtectedRoute
                      Route={AnalyticsReprocessingPage}
                      access={access}
                      accessLoading={accessLoading}
                    />
                  )
                },
                {
                  path: "debugger",
                  element: (
                    <ProtectedRoute
                      Route={AnalyticsDebuggerPage}
                      access={access}
                      accessLoading={accessLoading}
                    />
                  )
                },
                {
                  path: "executer",
                  element: (
                    <ProtectedRoute
                      Route={AnalyticsExecuterPage}
                      access={access}
                      accessLoading={accessLoading}
                    />
                  )
                },
                {
                  path: "executer-v2",
                  element: (
                    <ProtectedRoute
                      Route={AnalyticsExecuterV2Page}
                      access={access}
                      accessLoading={accessLoading}
                    />
                  )
                },
                {
                  path: "time-stream-analytics",
                  element: (
                    <ProtectedRoute
                      Route={TimeStreamAnalytics}
                      access={access}
                      accessLoading={accessLoading}
                    />
                  )
                }
              ]
            },
            {
              path: "subscriptions",
              element: <SubscriptionsActionBar />,
              children: [
                {
                  path: "buildings",
                  element: (
                    <ProtectedRoute
                      Route={PermissionsBuildingPage}
                      access={access}
                      accessLoading={accessLoading}
                      basePage="subscriptions"
                    />
                  )
                },
                {
                  path: "offerings",
                  element: (
                    <ProtectedRoute
                      Route={SubscriptionOfferingsPage}
                      access={access}
                      accessLoading={accessLoading}
                    />
                  )
                },
                {
                  path: "building-subscriptions",
                  element: (
                    <ProtectedRoute
                      Route={BuildingsSubscriptionPage}
                      access={access}
                      accessLoading={accessLoading}
                    />
                  )
                }
              ]
            },
            {
              path: "entities",
              element: (
                <ProtectedRoute
                  Route={EntitiesActionBar}
                  access={access}
                  accessLoading={accessLoading}
                />
              ),
              children: [
                {
                  path: ":pageId",
                  element: (
                    <ProtectedRoute
                      Route={EntitiesPage}
                      access={access}
                      accessLoading={accessLoading}
                    />
                  )
                }
              ]
            }
          ]
        }
      ].filter((e) => e)
    },
    {
      path: "*",
      element: <NotFound />
    }
  ])
  return routes
}
export default AppRoutes
