import { cloneDeep, flatten, groupBy, isArray, sortBy } from "lodash";
import { getChartObject, legendIDGenerator, legendName } from "./common";
import { SHAPES_TYPES } from "src/blitz/Charts/constants";

const defaultRepeat = 1;
export class AssignColorsForDefaultProperties {

    public assigned = false

    constructor(assigned = false) {
        this.assigned = assigned
    }

    assignDefaultLegendColors(structuredObject: any = {}, axisConfig = [], chartSettings = [], legendAttributesCombination: any, info: any = {}) {
        const { buildingId = "", equipmentId = "", equipmentList = {} } = info;
        const defaultUtilisedColors: any = []
        let normalLegends: any = []
        let timelineLegends: any = []// timeline
        let exceptions: any = [] //  (exceptions, suppressions)
        let otherLegends: any = [] // others (settings, characteristics)

        if (structuredObject?.equipment?.length) {
            structuredObject?.equipment?.forEach((obj) => {
                const pids = obj?.pId || [];
                const eids = obj?.eId || [];

                if (pids?.length && eids?.length) {
                    pids?.forEach((key) => {
                        const axis = axisConfig?.find((obj) =>
                            getChartObject(obj, key, obj?.type)
                        )
                        const propertyDefaults = chartSettings?.find((obj) => obj?.id === key)
                        const propertyName = propertyDefaults?.propertyName

                        eids?.forEach((eObj) => {
                            const id = eObj?.id;
                            const selectedEquipment = equipmentId === id
                            const building = buildingId === id
                            const equipmentName = equipmentList?.find((obj) => obj?.id === id)?.name
                            const name = propertyDefaults ? legendName({ building, selectedEquipment, propertyName, equipmentName }) : `${key}${id}`
                            if (axis?.axisName === "timeline") {
                                timelineLegends.push({ id: legendIDGenerator(id, key), name, key })
                            } else {
                                const obj: any = { id: legendIDGenerator(id, key), name, key }
                                if (selectedEquipment) {
                                    obj.selectedEquipment = true
                                }
                                obj.selectedEquipment = selectedEquipment
                                normalLegends.push({ id: legendIDGenerator(id, key), name, key })
                            }
                        })
                    })
                }
            })
        }

        if (isArray(structuredObject?.exceptions)) {
            const exceptions = structuredObject?.exceptions
            if (exceptions?.length) {
                exceptions?.forEach((excep) => {
                    const id = excep?.eId;
                    const properties = excep?.exName
                    properties?.forEach((key) => {
                        exceptions.push({ id: legendIDGenerator("", key), name: `${key}${id}`, key })
                    })
                })
            }
        } else {
            const id = structuredObject?.exceptions?.eId;
            const properties = structuredObject?.exceptions?.exName
            properties?.forEach((key) => {
                exceptions.push({ id: legendIDGenerator("", key), name: `${key}${id}`, key })
            })
        }

        if (structuredObject?.characteristicCurve?.id && structuredObject?.characteristicCurve?.parameters?.length && structuredObject?.characteristicCurve?.instances?.length) {
            structuredObject?.characteristicCurve?.parameters?.forEach((obj) => {
                const curveType = obj?.curveType || ""
                if (curveType) {
                    for (let i = 0; i < structuredObject?.characteristicCurve?.instances?.length; i++) {
                        otherLegends.push({ id: legendIDGenerator(structuredObject?.characteristicCurve?.id, curveType, structuredObject?.characteristicCurve?.instances?.[i]?.toLowerCase()), name: `${curveType}${structuredObject?.characteristicCurve?.instances?.[i]}` })
                    }
                }
            })
        }

        if (structuredObject?.settings?.length) {
            structuredObject?.settings?.forEach((key) => {
                otherLegends.push({ id: key, name: key })
            })
        }

        if (structuredObject?.building?.id && structuredObject?.weather?.length) {
            structuredObject?.weather?.forEach((key) => {
                normalLegends.push({ id: legendIDGenerator(structuredObject?.building?.id, key), name: `${key}${structuredObject?.building?.id}`, key })
            })
        }

        normalLegends = sortBy(normalLegends, ["name"])
        timelineLegends = sortBy(timelineLegends, ["name"])
        exceptions = sortBy(exceptions, ["name"])
        otherLegends = sortBy(otherLegends, ["name"])

        const groupByKeyNor = groupBy(normalLegends, "key")
        const groupByKeyTimeline = groupBy(timelineLegends, "key")
        const groupByKeyExeptions = groupBy(exceptions, "key")

        const defaultAvailablePropertiesL: any = {}
        const defaultUnAvailablePropertiesL: any = {}

        const defaultAvailablePropertiesT: any = {}
        const defaultUnAvailablePropertiesT: any = {}

        const defaultAvailablePropertiesE: any = {}
        const defaultUnAvailablePropertiesE: any = {}

        Object.keys(groupByKeyNor)?.forEach((key) => {
            const defaultAvailable = chartSettings?.find((obj) => obj?.id === key)?.settings?.colour?.length > 0
            if (defaultAvailable) {
                defaultAvailablePropertiesL[key] = groupByKeyNor[key]
            } else {
                defaultUnAvailablePropertiesL[key] = groupByKeyNor[key]
            }
        })

        Object.keys(groupByKeyTimeline)?.forEach((key) => {
            const defaultAvailable = chartSettings?.find((obj) => obj?.id === key)?.multiSettings?.length > 0
            if (defaultAvailable) {
                defaultAvailablePropertiesT[key] = groupByKeyTimeline[key]
            } else {
                defaultUnAvailablePropertiesT[key] = groupByKeyTimeline[key]
            }
        })

        Object.keys(groupByKeyExeptions)?.forEach((key) => {
            const defaultAvailable = chartSettings?.find((obj) => obj?.id === key)?.settings?.colour?.length > 0
            if (defaultAvailable) {
                defaultAvailablePropertiesE[key] = groupByKeyExeptions[key]
            } else {
                defaultUnAvailablePropertiesE[key] = groupByKeyExeptions[key]
            }
        })



        const colorAssigned = []
        let colorUnAssigned = []

        Object.keys(defaultAvailablePropertiesL)?.forEach((key) => {
            const colors = chartSettings?.find((obj) => obj?.id === key)?.settings?.colour || []
            const localLegendAttributesCombination = new LegendAttributesCombination({ color: colors, shape: SHAPES_TYPES }, ["color", "shape"]);
            let props = defaultAvailablePropertiesL?.[key] || []
            props = sortBy(props, ["selectedEquipment", "name"], ["desc", "asc"]);
            props?.forEach((obj, index) => {
                if (index < defaultRepeat) {
                    if (localLegendAttributesCombination?.getLegendAttributeCombination) {
                        const legendAtr = localLegendAttributesCombination?.getLegendAttributeCombination()
                        defaultUtilisedColors.push(legendAtr?.color)
                        if (legendAttributesCombination?.markCombinationAsUsed) {
                            legendAttributesCombination.markCombinationAsUsed(legendAtr)
                        }
                        const newObj = {
                            ...obj,
                            legendAttribute: {
                                ...legendAtr
                            }
                        }
                        colorAssigned.push(newObj)
                    }
                } else {
                    colorUnAssigned.push(obj)
                }
            })
        })

        Object.keys(defaultAvailablePropertiesT)?.forEach((key) => {
            const colors = chartSettings?.find((obj) => obj?.id === key)?.multiSettings || []
            const props = defaultAvailablePropertiesT?.[key] || []
            const color1 = legendAttributesCombination?.getTimeLineColor()
            const color2 = hexToRgba(color1, 0.5);
            props?.forEach((obj) => {
                const newObj = {
                    ...obj,
                    legendAttribute: {
                        multiColor: colors,
                        color: [color1, color2]
                    }
                }
                colorAssigned.push(newObj)
            })
        })

        Object.keys(defaultAvailablePropertiesE)?.forEach((key) => {
            const colors = chartSettings?.find((obj) => obj?.id === key)?.settings?.colour || []
            const props = defaultAvailablePropertiesE?.[key] || []
            const color = colors?.[0]
            props?.forEach((obj) => {
                const newObj = {
                    ...obj,
                    legendAttribute: {
                        multiColor: colors,
                        color: [color]
                    }
                }
                colorAssigned.push(newObj)
            })
        })

        const defaultUnL: any = flatten(Object.values(defaultUnAvailablePropertiesL))
        colorUnAssigned = colorUnAssigned.concat(...defaultUnL)
        const defaultUnT: any = sortBy(flatten(Object.values(defaultUnAvailablePropertiesT)), ["name"])
        const defaultUnE: any = sortBy(flatten(Object.values(defaultUnAvailablePropertiesE)), ["name"])
        defaultUnT?.forEach((obj) => {
            if (legendAttributesCombination?.getTimeLineColor) {
                const color1 = legendAttributesCombination?.getTimeLineColor()
                const color2 = hexToRgba(color1, 0.5);
                const newObj = {
                    ...obj,
                    legendAttribute: {
                        color: [color1, color2]
                    }
                }
                colorAssigned.push(newObj)
            }
        })
        defaultUnE?.forEach((obj) => {
            if (legendAttributesCombination?.getTimeLineColor) {
                const color1 = legendAttributesCombination?.getTimeLineColor()
                const color2 = hexToRgba(color1, 0.5);
                const newObj = {
                    ...obj,
                    legendAttribute: {
                        color: [color1, color2]
                    }
                }
                colorAssigned.push(newObj)
            }
        })

        otherLegends?.forEach((obj) => {
            const newObj = {
                ...obj,
                legendAttribute: {
                    color: legendAttributesCombination?.getTimeLineColor()
                }
            }
            colorAssigned.push(newObj)
        })
        colorUnAssigned?.forEach((obj) => {
            if (legendAttributesCombination?.getLegendAttributeCombination) {
                const legendAtr = legendAttributesCombination?.getLegendAttributeCombination()
                const newObj = {
                    ...obj,
                    legendAttribute: {
                        ...legendAtr
                    }
                }
                colorAssigned.push(newObj)
            }
        })

        this.assigned = true
        if (legendAttributesCombination?.saveCombNumberAfterDefaults) {
            legendAttributesCombination?.saveCombNumberAfterDefaults()
        }

        return colorAssigned
    }

}

export class LegendAttributesCombination {
    private attributes: { [key: string]: string[] };
    private attributeOrder: string[];
    private totalCombinations: number;
    private colorsCount: number
    private presentCombinationNumber: number
    private presentCombNumTimeLine: number
    private combNumberAfterDefaults: { [key: string]: any }
    private allCombinations: { [key: string]: string }[];
    private combinationState: { [key: string]: boolean }

    constructor(...args: any[]) {
        if (args[0] instanceof LegendAttributesCombination) {
            // Copy constructor
            const [inst] = args;
            const clonedInstance = cloneDeep(inst);
            this.attributes = clonedInstance?.attributes
            this.attributeOrder = clonedInstance?.attributeOrder
            this.totalCombinations = clonedInstance?.totalCombinations
            this.colorsCount = clonedInstance?.colorsCount
            this.presentCombinationNumber = clonedInstance?.presentCombinationNumber
            this.presentCombNumTimeLine = clonedInstance?.presentCombNumTimeLine
            this.allCombinations = clonedInstance?.allCombinations;
            this.combinationState = clonedInstance?.combinationState;
        } else {
            if (args?.length) {
                this.initiateState(args)
            }
        }
    }

    initiateState(args: any[]) {
        const [attributeGroups, attributeOrder] = args;
        this.attributes = attributeGroups || {};
        this.attributeOrder = attributeOrder.length > 0 ? attributeOrder : Object.keys(attributeGroups);
        this.totalCombinations = this.calculateTotalCombinations();
        this.colorsCount = this.attributes?.color ? this.attributes?.color.length : 0;
        this.presentCombinationNumber = 0
        this.presentCombNumTimeLine = 0
        this.allCombinations = this.generateCombinations();
        this.combinationState = {};
        this.resetCombinationsToTrue();
    }

    getState(stateName: string): any {
        return this?.[stateName]
    }

    private calculateTotalCombinations(): number {
        return this.attributeOrder.reduce((total, attributeName) => {
            return total * (this.attributes[attributeName]?.length || 1);
        }, 1);
    }

    private resetCombinationsToTrue() {
        for (let i = 0; i < this.totalCombinations; i++) {
            this.combinationState[i] = true; // Reset all to true
        }
    }

    private generateCombinations(): { [key: string]: string }[] {
        const combinations: { [key: string]: string }[] = [];
        const totalCombinations = this.calculateTotalCombinations();

        for (let i = 0; i < totalCombinations; i++) {
            let remainingCombination = i;
            const combination: { [key: string]: string } = {};

            for (const attributeName of this.attributeOrder) {
                const attributeValues = this.attributes[attributeName];
                const attributeCount = attributeValues.length;
                const index = Math.floor(remainingCombination % attributeCount);
                combination[attributeName] = attributeValues[index];
                remainingCombination = Math.floor(remainingCombination / attributeCount);
            }

            combinations.push(combination);
        }

        return combinations;
    }

    getLegendAttributeCombination(): { [key: string]: string } {
        let combinationFound = false;
        let combinationResult: { [key: string]: string } | null = null;

        while (!combinationFound) {
            const number = this.presentCombinationNumber;
            const adjustedNumber = number < this.totalCombinations ? number : ((number) % this.totalCombinations + this.totalCombinations) % this.totalCombinations;

            if (this.combinationState?.[adjustedNumber]) {
                combinationResult = this.allCombinations?.[adjustedNumber];
                this.combinationState[adjustedNumber] = false;
                combinationFound = true;
            } else {
                this.presentCombinationNumber++;
            }

            if (Object.values(this.combinationState).every((used) => !used)) {
                for (let i = 0; i < this.totalCombinations; i++) {
                    this.combinationState[i] = true;
                }
                this.presentCombinationNumber = 0;
            }
        }

        this.presentCombinationNumber++;

        return combinationResult!;
    }

    getTimeLineColor() {
        if (!this.attributes.color) {
            return null;
        }

        const color = this.attributes.color[this.presentCombNumTimeLine % this.colorsCount];

        this.presentCombNumTimeLine++;

        return color;
    }

    markCombinationAsUsed(combination: { [key: string]: string }): void {
        const index = this.allCombinations.findIndex((comb) => {
            return Object.keys(combination || {}).every((key) => combination[key] === comb[key]);
        });
        if (index !== -1) {
            this.combinationState[index] = false;
        }
    }

    saveCombNumberAfterDefaults() {
        this.combNumberAfterDefaults = {
            presentCombinationNumber: cloneDeep(this.presentCombinationNumber),
            presentCombNumTimeLine: cloneDeep(this.presentCombNumTimeLine),
            combinationState: cloneDeep(this.combinationState)
        }
    }

    resetCombination(reset: boolean[]) {
        const [normal, timeline] = reset || []
        if (normal) {
            this.presentCombinationNumber = 0
        }
        if (timeline) {
            this.presentCombNumTimeLine = 0
        }
        this.resetCombinationsToTrue();
    }

    resetCombinationToDefaults() {
        this.presentCombinationNumber = this.combNumberAfterDefaults?.presentCombinationNumber
        this.presentCombNumTimeLine = this?.combNumberAfterDefaults?.presentCombNumTimeLine
        this.combinationState = cloneDeep(this?.combNumberAfterDefaults?.combinationState)
    }
}


export const hexToRgba = (hex, opacity = 0.5) => {
    hex = hex.replace(/^#/, "");

    if (hex.length === 3) {
        hex = hex.split("").map(char => char + char).join("");
    }

    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);

    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export const resetColorSetup = (chartColor: any = {}, controls = []) => {
    const [dontResetDefaults, dontResetcomb, dontResetToDefaults] = controls;
    if (chartColor?.assignColorsForDefaultProperties && !dontResetDefaults) {
        chartColor.assignColorsForDefaultProperties.assigned = false
    }
    if (chartColor?.legendAttributesCombination && !dontResetcomb) {
        chartColor?.legendAttributesCombination?.resetCombination && chartColor?.legendAttributesCombination?.resetCombination([true, true])
    }
    if (chartColor?.legendAttributesCombination && !dontResetToDefaults) {
        chartColor?.legendAttributesCombination?.resetCombinationToDefaults && chartColor?.legendAttributesCombination?.resetCombinationToDefaults()
    }
}
