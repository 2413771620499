export const getChartData = /* GraphQL */ `
  query getChartData($body: AWSJSON) {
    getChartData(body: $body)
  }
`
export const getChartDefaultSettings = /* GraphQL */ `
  query getChartDefaultSettings($chartId: Int!) {
    listChartSettingsByChart(chartId: $chartId) {
      items {
        bId
        eId
        chartId
        chartType
        id
        propertyName
        settings
      }
    }
  }
`

export const getChartList = `
query MyQuery {
  listCharts(limit: 10000) {
    items {
      axisConfig
      category
      equipmentType
      id
      isEditable
      subType
      title
      type
    }
    nextToken
  }
}
`

export const generatePerformanceChartExport = /* GraphQL */ `
  mutation GenerateChartPDF(
    $url: String!
    $type: String!
    $viewport: AWSJSON
    $extraParams: AWSJSON
    $buildingId: String
    $returnType: String
  ) {
    generateChartPDF(
      url: $url
      type: $type
      viewport: $viewport
      extraParams: $extraParams
      buildingId: $buildingId
      returnType: $returnType
    )
  }
`

export const getChillerProperties = /* GraphQL */ `
  query GET_DATA_CHARACTERISTICS($id: ID!) {
    getPropertyMappingByEquipmentID(
      equipmentId: $id
      propertyType: { eq: "Characteristic" }
      limit: 1000
    ) {
      items {
        id
        buildingId
        tisDisplayName
        targetMeasureUnit
        targetMeasureType
        propertyType
        value
        targetDataType
        targetKey
      }
    }
  }
`

export const listConfigsAxes = /* GraphQL */ `
  query listConfigsAxes {
    listConfigs(filter: { type: { eq: "Dimension" } }, limit: 100000) {
      items {
        type
        subType
        name
        value
      }
      nextToken
    }
  }
`


export const listChartSettings = /* GraphQL */ `
  query listChartSettings($nextToken: String) {
    listChartSettings(limit: 1000, nextToken: $nextToken) {
      items {
        id
        propertyName
        settings
        multiSettings
      }
      nextToken
    }
  }
`