/// Raw Data report
export const getEquipmentsByBuilding = /* GraphQL */ `
  query ListEquipmentsByBuildingAndType($buildingId: ID!, $type: String, $filter:ModelEquipmentFilterInput, $nextToken: String ) {
    listEquipmentsByBuildingAndActiveAndType(
      buildingId: $buildingId
      filter: $filter
      nextToken: $nextToken
      isActiveIsTISType: { beginsWith: { isActive: 1, isTIS: 1, type: $type }
       }
      limit: 2000
    ) {
      items {
        id
        uri
        imageUrl
        type
        name
        parentId
        family
        isParent
        isActive
        deviceId
        compCondType
        instance
      }
      nextToken
    }
  }
`

export const generateRawDataReport = /* GraphQL */ `
  mutation generateRawDataReport($requestBody: AWSJSON) {
    generateRawDataReport(requestBody: $requestBody)
  }
`

export const getPropsByEquipmentType = /* GraphQL */ `
  query PropertiesSearch($filter: SearchablePropertyMetadataFilterInput) {
    searchPropertyMetadata(limit: 1000, filter: $filter) {
      items {
        tisDisplayName
        targetKey
        propertyType
        id
        sourceKey
        targetDataType
        targetDataGroup
        targetMeasureType
        targetMeasureUnit
        targetMeasureUnitDescription
      }
      total
    }
  }
`
export const searchAccounts = /* GraphQL */ `
  query searchAccounts(
    $filter: SearchableAccountFilterInput
    $sort: [SearchableAccountSortInput]
    $limit: Int
    $nextToken: String
  ) {
    searchAccounts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
      }
      nextToken
      total
    }
  }
`

export const generateEHRDataReport = /* GraphQL */ `
  mutation generateEHRDataReport($requestBody: AWSJSON) {
    generateEHRDataReport(requestBody: $requestBody)
  }
`
export const generateEquipmentPerformanceReport = /* GraphQL */ `
  mutation generateEquipmentPerformanceReport($requestBody: AWSJSON) {
    generateEquipmentPerformanceReport(requestBody: $requestBody)
  }
`

export const searchBuildings = /* GraphQL */ `
  query SearchBuildings(
    $filter: SearchableBuildingFilterInput
    $sort: [SearchableBuildingSortInput]
    $limit: Int
    $nextToken: String
  ) {
    searchBuildings(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        isActive
        accountId
        accountName
      }
      nextToken
      total
    }
  }
`
/// Exception report
export const fetchAnalytics = /* GraphQL */ `
  query getByAnalyticByEquipmentType($equipmentType: String!) {
    getByAnalyticByEquipmentType(filter: {isActive: {eq: 1}}, equipmentType: $equipmentType) {
      items {
        equipmentType
        equipmentComponent
        exceptionInputs
      }
    }
  }
`
export const fetchListAnalytics = /* GraphQL */ `
  query listAnalyticConfigs(
    $filter: ModelAnalyticConfigFilterInput
    $nextToken: String
  ) {
    listAnalyticConfigs(filter: $filter, nextToken: $nextToken) {
      items {
        equipmentType
        exceptionInputs
        equipmentComponent
      }
      nextToken
    }
  }
`

export const getEquipmentTypesByBuilding = /* GraphQL */ `
  query getBuildingEquipmentTypes($requestBody: AWSJSON) {
    getBuildingEquipmentTypes(body: $requestBody)
  }
`

export const getEquipmentPropertiesByBuilding = /* GraphQL */ `
  query getEquipmentPropertiesByBuilding($id: ID!, $equipmentType: String){ listEquipmentPropertiesByBuilding(buildingId: $id, limit: 10000, propertyType: {eq: "Characteristic"}, filter:{equipmentType:{eq: $equipmentType}}) {
    items {
      value
      targetKey
    }
  }
  }
`
export const getDevicesByBuilding = /* GraphQL */ `
  query getDevicesByBuilding($id: ID!){
    listDevicesByBuilding(buildingId: $id, limit: 2000, filter: {isActive: {eq: 1}, type: {ne: "ES"}}) {
      items {
        id
        name
        serial
      }
    }
  }
`
