import css from "./Typography.module.scss"
import { ITypography, ITypographyFontType } from "./types"
import { ITypographyStyleType } from "./types"
import clx from "classnames"
import { SkeltonLoader } from "./../Skelton"
import Highlighter from "react-highlight-words"
import { TranslateComponent, translate } from "src/common/translations"

const Typography = ({
  tagType = "div",
  styleType = "p2",
  className = "",
  children,
  color = "default",
  testId = undefined,
  fontType = "",
  isAfter = false,
  loaderProps = {},
  highLightedText = "",
  textToHighlight = "",
  translateText = false,
  customFunction,
  ...rest
}: ITypography) => {
  const { loading = false, width = 100, theme = "dark" } = loaderProps

  const styleClassName = clx(
    getElementTag(styleType, color, fontType, isAfter),
    className
  )
  switch (tagType) {
    case "h1":
      return (
        <h1 className={styleClassName} data-testid={testId} {...rest}>
          {loading ? (
            <SkeltonLoader width={width} loading={loading} theme={theme} />
          ) : (
            children
          )}
        </h1>
      )
    case "h2":
      return (
        <h2 className={styleClassName} data-testid={testId} {...rest}>
          {loading ? (
            <SkeltonLoader width={width} loading={loading} theme={theme} />
          ) : (
            children
          )}
        </h2>
      )
    case "h3":
      return (
        <h3 className={styleClassName} data-testid={testId} {...rest}>
          {loading ? (
            <SkeltonLoader width={width} loading={loading} theme={theme} />
          ) : (
            translateText && typeof children === "string" ? <TranslateComponent>{children}</TranslateComponent> : children
          )}
        </h3>
      )
    case "h4":
      return (
        <h4 className={styleClassName} data-testid={testId} {...rest}>
          {loading ? (
            <SkeltonLoader width={width} loading={loading} theme={theme} />
          ) : (
            children
          )}
        </h4>
      )
    case "h5":
      return (
        <h5 className={styleClassName} data-testid={testId} {...rest}>
          {loading ? (
            <SkeltonLoader width={width} loading={loading} theme={theme} />
          ) : (
            children
          )}
        </h5>
      )
    case "h6":
      return (
        <h6 className={styleClassName} data-testid={testId} {...rest}>
          {loading ? (
            <SkeltonLoader width={width} loading={loading} theme={theme} />
          ) : (
            children
          )}
        </h6>
      )
    case "span":
      {
        const tooltip = typeof children === "string" ? translateText ? translate(children) : children : ""
        const value = translateText && typeof children === "string" ? <TranslateComponent>{children}</TranslateComponent> : children
        return (
          <span className={styleClassName} title={tooltip} data-testid={testId} {...rest}>
            {loading ? (
              <SkeltonLoader width={width} loading={loading} theme={theme} />
            ) : highLightedText?.length ? (
              <Highlighter
                highlightClassName={css?.searchHighlightColor}
                searchWords={[highLightedText]}
                autoEscape={true}
                textToHighlight={textToHighlight}
              />
            ) : (
              value
            )}
          </span>
        )
      }
    case "p":
      return (
        <p className={styleClassName} data-testid={testId} {...rest}>
          {loading ? (
            <SkeltonLoader width={width} loading={loading} theme={theme} />
          ) : highLightedText?.length ? (
            <Highlighter
              highlightClassName={css?.searchHighlightColor}
              searchWords={[highLightedText]}
              autoEscape={true}
              textToHighlight={textToHighlight}
            />
          ) : (
            children
          )}
        </p>
      )
    case "label":
      return (
        <label className={styleClassName} data-testid={testId} {...rest}>
          {loading ? (
            <SkeltonLoader width={width} loading={loading} theme={theme} />
          ) : (
            children
          )}
        </label>
      )
    case "custom":
      return (
        <div className={styleClassName} data-testid={testId} {...rest}>
          {customFunction(children)}
        </div>
      )
    default:
      return (
        <div className={styleClassName} data-testid={testId} {...rest}>
          {typeof children === "string" && !translateText ?
            <TranslateComponent>{children}</TranslateComponent> :
            children}
        </div>
      )
  }
}

function getElementTag(
  styleType: ITypographyStyleType,
  color: "primary" | "secondary" | "default" | "graphite" | "black" | "blue",
  fontType: ITypographyFontType,
  isAfter
) {
  return clx(
    css?.[styleType] || css.p2,
    // @ts-ignore
    css?.[color.toLowerCase() + "TextColor"] || css.defaultTextColor,
    // @ts-ignore
    css?.[fontType] || "",
    { [css.afterLabel]: isAfter }
  )
}

export default Typography
