import { uniq } from "lodash"

export const transformTimeLinechartNew = (
  timeLineLegends: any,
  timestamps: any,
  data: any
) => {
  const timeLineBars = []
  const timelines = []
  Object.keys(timeLineLegends)?.forEach((key: any, index: number) => {
    const obj = timeLineLegends[key]
    const newObj = { ...obj }
    if (obj?.show) {
      newObj.lane = index + 1
    }
    const objData = data?.[obj?.id]?.data || []
    const uniqValues = uniq(objData)
    const colorByValue: any = {}
    uniqValues?.filter(v => v)?.forEach((v: any, i) => {
      const color = (obj?.multiColor || [])?.find(o => o?.value === v)?.colour || obj?.assignedColor?.[i]
      colorByValue[v] = color
    })
    let colors = Object.values(colorByValue)
    colors = colors?.length === 0 ? obj?.assignedColors : colors?.length === 1 ? [colors?.[0], obj?.assignedColor?.[1]] : colors
    newObj.color = colors
    obj.color = colors
    newObj.colorByValue = colorByValue
    timelines.push(newObj)
  })
  for (let i = 0; i < timelines?.length; i++) {
    createBarObjects(timelines?.[i])
  }

  function createBarObjects(object) {
    let currentObject = null
    const objData = data?.[object?.id]?.data || []
    for (let i = 0; i < timestamps.length; i++) {
      const timestamp = timestamps[i]
      const value = objData?.[i]

      if (currentObject === null || currentObject?.value !== value) {
        if (currentObject !== null) {
          currentObject.endTimestamp = timestamp
          timeLineBars.push(currentObject)
        }
        const color = !value ? "#000" : object?.colorByValue?.[value]
        currentObject = {
          startTimestamp: timestamp,
          endTimestamp: null,
          value: value || null,
          lane: object?.lane,
          id: object?.id,
          name: object?.name,
          color,
          show: object?.show,
          visible: object?.visible,
          propertyName: object?.propertyName
        }
      }

      if (i === timestamps?.length - 1) {
        currentObject.endTimestamp = timestamp
        timeLineBars.push(currentObject)
      }
    }
  }

  const totalLanes = Math.max(...(timeLineBars?.map((obj) => obj?.lane) || []))

  return { timeLineBars: timeLineBars, totalLanes: totalLanes }
}

export const TIMELINE_PROPERTY_TYPE = "timelinePropertyType"
