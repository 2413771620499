import { createSlice } from "@reduxjs/toolkit"
import { TEST_STATUS_FIELD } from "src/components/equipmentSummary/AutomatedTests/constant"
import { ACTIONS } from "src/constants"
const initialReportStatus = {
  error: false,
  completed: false
}
export const equipmentSummarySlice = createSlice({
  name: "equipment",
  initialState: {
    searchText: "",
    selectedEquipment: null,
    dateRange: {
      startDate: null,
      endDate: null
    },
    equipmentId: null,
    equipmentSummary: {
      loading: true,
      data: null,
      error: false
    },
    equipmentWeatherInfo: {
      loading: true,
      data: null,
      error: false
    },
    automatedTests: {
      loading: true,
      data: [],
      responseTime: 0,
      suppressedByDate: {},
      error: false
    },
    serviceAdvisories: {
      loading: true,
      data: [],
      hasMore: false,
      nextToken: null,
      error: false
    },
    serviceAdvisoriesAggregates: {
      loading: true,
      data: [],
      error: false
    },
    serviceAdvisoriesTotal: {
      loading: true,
      data: null,
      error: false
    },
    digitalInspectionDetails: {
      loading: true,
      data: [],
      aggregateData: [],
      filteredData: [],
      error: false
    },
    digitalInspectionDetailsFilter: {},
    listNotesByEquipmentTotal: {
      loading: true,
      data: null,
      error: false
    },
    digitalInspectionV2: {
      filters: {
        activeTiles: [],
        checkedAts: [],
        hiddenAutomatedTests: [],
      }
    },
    serviceAdvisoriesFilter: {},
    generateServiceAdviosriesReport: false,
    status: initialReportStatus,
    selectedAdvisories: [],
    updatedAdvisories: [],
    equipmentProperties: {
      loading: true,
      error: false,
      data: []
    },
    listAnalyticConfigs: {
      loading: true,
      error: false,
      data: {}
    },
    toggleSuppressions: false,
    columnSearch: {},
    columnSelected: {
      [TEST_STATUS_FIELD]: null
    }
  },
  reducers: {
    setSearchText: (state, action) => {
      state.searchText = action.payload
    },
    setDateRange: (state, action) => {
      state.dateRange = action.payload
    },
    setSelectedEquipment: (state, action) => {
      state.selectedEquipment = action.payload
    },
    setEquipmentId: (state, action) => {
      state.equipmentId = action.payload
    },
    setEquipmentSummary: (state, action) => {
      const { type, data = {} } = action.payload
      switch (type) {
        case "Loading":
          state.equipmentSummary = {
            loading: true,
            data: null,
            error: false
          }
          break
        case "Success":
          state.equipmentSummary = {
            loading: false,
            data,
            error: false
          }
          break
        case "Failure":
          state.equipmentSummary = {
            loading: false,
            data: null,
            error: true
          }
          break
      }
    },
    setWeatherInfo: (state, action) => {
      const { type, data = {} } = action.payload
      switch (type) {
        case "Loading":
          state.equipmentWeatherInfo = {
            loading: true,
            data: null,
            error: false
          }
          break
        case "Success":
          state.equipmentWeatherInfo = {
            loading: false,
            data,
            error: false
          }
          break
        case "Failure":
          state.equipmentWeatherInfo = {
            loading: false,
            data: null,
            error: true
          }
          break
      }
    },
    setAutomatedTests: (state, action) => {
      const {
        type,
        data = [],
        responseTime = 0,
        suppressedByDate = {}
      } = action.payload
      switch (type) {
        case "Loading":
          state.automatedTests = {
            loading: true,
            data: [],
            suppressedByDate,
            responseTime: 0,
            error: false
          }
          break
        case "Success":
          state.automatedTests = {
            loading: false,
            data,
            responseTime,
            suppressedByDate,
            error: false
          }
          break
        case "Failure":
          state.automatedTests = {
            loading: true,
            data: [],
            responseTime: 0,
            suppressedByDate,
            error: true
          }
          break
        case "UpdatingResponseTime":
          state.automatedTests.responseTime = responseTime
          break
      }
    },
    setServiceAdvisories: (state, action) => {
      const {
        type = "Sucesss",
        data = [],
        nextToken = null,
        hasMore = false,
        loadMore = false
      } = action.payload
      switch (type) {
        case "Loading":
          state.serviceAdvisories = {
            loading: true,
            data: loadMore ? state.serviceAdvisories.data : [],
            nextToken,
            hasMore: false,
            error: undefined
          }
          break
        case "Success":
          state.serviceAdvisories = {
            loading: false,
            data,
            nextToken,
            hasMore,
            error: false
          }
          break
        case "Failure":
          state.serviceAdvisories = {
            loading: false,
            data,
            nextToken,
            hasMore: false,
            error: true
          }
          break
      }
    },
    setDigitalInspectionDetails: (state, action) => {
      const {
        type = "Sucesss",
        data = [],
        aggregateData = [],
        filteredData = []
      } = action.payload
      switch (type) {
        case "Loading":
          state.digitalInspectionDetails = {
            loading: true,
            data: [],
            aggregateData: [],
            filteredData: [],
            error: undefined
          }
          break
        case "Success":
          state.digitalInspectionDetails = {
            loading: false,
            data,
            aggregateData,
            filteredData,
            error: false
          }
          break
        case "Failure":
          state.digitalInspectionDetails = {
            loading: false,
            data,
            aggregateData,
            filteredData: data,
            error: true
          }
          break
      }
    },
    setlistNotesByEquipmentTotal: (state, action) => {
      const { type, data = {} } = action.payload
      switch (type) {
        case "Loading":
          state.listNotesByEquipmentTotal = {
            loading: true,
            data: null,
            error: false
          }
          break
        case "Success":
          state.listNotesByEquipmentTotal = {
            loading: false,
            data,
            error: false
          }
          break
        case "Failure":
          state.listNotesByEquipmentTotal = {
            loading: false,
            data: null,
            error: true
          }
          break
      }
    },
    setServiceAdvisoriesTotal: (state, action) => {
      const { type, data = {} } = action.payload
      switch (type) {
        case "Loading":
          state.serviceAdvisoriesTotal = {
            loading: true,
            data: null,
            error: false
          }
          break
        case "Success":
          state.serviceAdvisoriesTotal = {
            loading: false,
            data,
            error: false
          }
          break
        case "Failure":
          state.serviceAdvisoriesTotal = {
            loading: false,
            data: null,
            error: true
          }
          break
      }
    },
    setServiceAdvisoriesAggregates: (state, action) => {
      const { type = "Sucesss", data = [] } = action.payload
      switch (type) {
        case "Loading":
          state.serviceAdvisoriesAggregates = {
            loading: true,
            data: [],
            error: undefined
          }
          break
        case "Success":
          state.serviceAdvisoriesAggregates = {
            loading: false,
            data,
            error: false
          }
          break
        case "Failure":
          state.serviceAdvisoriesAggregates = {
            loading: false,
            data,
            error: true
          }
          break
      }
    },
    setServiceAdvisoriesFilter: (state, action) => {
      state.serviceAdvisoriesFilter = { ...action.payload }
    },
    setDigitalInspectionDetailsFilter: (state, action) => {
      state.digitalInspectionDetailsFilter = { ...action.payload }
    },
    setGenerateServiceAdviosriesReport: (state, action) => {
      state.generateServiceAdviosriesReport = action.payload
    },
    setReportStatus: (state, { payload }) => {
      switch (payload) {
        case ACTIONS.COMPLETE:
          state.status = {
            ...initialReportStatus,
            completed: true
          }
          break
        case ACTIONS.ERROR:
          state.status = {
            ...initialReportStatus,
            error: true
          }
          break
        case ACTIONS.RESET:
          state.status = {
            ...initialReportStatus
          }
          break
      }
      return state
    },
    setSelectedAdvisories: (state, action) => {
      state.selectedAdvisories = action.payload
    },
    setUpdatedAdvisories: (state, action) => {
      state.updatedAdvisories = action.payload
    },
    setEquipmentProperties: (state, action) => {
      const { type, data = [] } = action.payload
      switch (type) {
        case "Loading":
          state.equipmentProperties = {
            ...state.equipmentProperties,
            loading: true,
            error: undefined,
            data: []
          }
          break
        case "Success":
          state.equipmentProperties = {
            loading: false,
            data,
            error: false
          }
          break
        case "Failure":
          state.equipmentProperties = {
            ...state.equipmentProperties,
            loading: false,
            data: [],
            error: true
          }
          break
      }
    },
    setAnalyticConfigs: (state, action) => {
      const { type, data = {} } = action.payload
      switch (type) {
        case "Loading":
          state.listAnalyticConfigs = {
            ...state.listAnalyticConfigs,
            loading: true,
            error: undefined,
            data: {}
          }
          break
        case "Success":
          state.listAnalyticConfigs = {
            loading: false,
            data,
            error: false
          }
          break
        case "Failure":
          state.listAnalyticConfigs = {
            ...state.listAnalyticConfigs,
            loading: false,
            data: {},
            error: true
          }
          break
      }
    },
    setToggleSuppressions: (state, action) => {
      const { payload = false } = action || {}
      state.toggleSuppressions = payload ? true : !state.toggleSuppressions
    },
    setColumnSearch: (state, action) => {
      const { id, searchValue } = action.payload || {}
      state.columnSearch = {
        ...(state.columnSearch || {}),
        [id]: searchValue
      }
    },
    setColumnSelected: (state, action) => {
      const { field, values = [], type } = action.payload || {}
      if (type === "change") {
        state.columnSelected[field] = values
      } else if (type === "remove") {
        if (state.columnSelected?.[field]) {
          delete state.columnSelected[field]
        }
      }
    },
    setActiveSystemCard: (state, action) => {
      const system = action.payload
      const activeTiles = state?.digitalInspectionV2?.filters?.activeTiles || []
      if (activeTiles?.includes(system)) {
        state.digitalInspectionV2.filters.activeTiles = state?.digitalInspectionV2?.filters?.activeTiles?.filter((s) => s !== system)
      } else {
        state.digitalInspectionV2.filters.activeTiles[0] = system
      }
    },
    clearDIV2Filters: (state) => {
      state.digitalInspectionV2.filters = {
        activeTiles: [],
        hiddenAutomatedTests: [],
        checkedAts: []
      }
    },
    setHiddenAutomatedTests: (state, action) => {
      const { hiddenAtsList, check, atsName, allCheckableAts } = action.payload
      const hiddenAutomatedTests = state?.digitalInspectionV2?.filters?.hiddenAutomatedTests || []

      if (check) {
        if (hiddenAutomatedTests?.includes(atsName)) {
          state.digitalInspectionV2.filters.hiddenAutomatedTests = state?.digitalInspectionV2?.filters?.hiddenAutomatedTests?.filter((s) => s !== atsName)
        } else {
          state.digitalInspectionV2.filters.hiddenAutomatedTests = [...hiddenAutomatedTests, atsName]
        }
      } else {
        state.digitalInspectionV2.filters.hiddenAutomatedTests = hiddenAtsList
      }

      state.digitalInspectionV2.filters.checkedAts = allCheckableAts.filter(item => !hiddenAutomatedTests?.includes(item))
    }
  }
})

export const {
  setToggleSuppressions,
  setColumnSearch,
  setColumnSelected,
  setEquipmentSummary,
  setWeatherInfo,
  setSearchText,
  setEquipmentId,
  setAutomatedTests,
  setDateRange,
  setSelectedEquipment,
  setServiceAdvisories,
  setServiceAdvisoriesTotal,
  setServiceAdvisoriesAggregates,
  setServiceAdvisoriesFilter,
  setGenerateServiceAdviosriesReport,
  setlistNotesByEquipmentTotal,
  setReportStatus,
  setSelectedAdvisories,
  setUpdatedAdvisories,
  setEquipmentProperties,
  setAnalyticConfigs,
  setDigitalInspectionDetails,
  setDigitalInspectionDetailsFilter,
  setActiveSystemCard,
  clearDIV2Filters,
  setHiddenAutomatedTests
} = equipmentSummarySlice.actions
export const selectEquipmentSummary = (state: any) =>
  state.equipment.equipmentSummary
export const selectEquipmentWeatherInfo = (state: any) =>
  state.equipment.equipmentWeatherInfo
export const selectAutomatedTests = (state: any) =>
  state.equipment.automatedTests
export const selectSearchText = (state: any) => state.equipment.searchText
export const selectDateRange = (state: any) => state.equipment.dateRange
export const selectServiceAdvisories = (state: any) =>
  state.equipment.serviceAdvisories
export const selectServiceAdvisoriesTotal = (state: any) =>
  state.equipment.serviceAdvisoriesTotal
export const selectDigitalInspectionDetails = (state: any) =>
  state.equipment.digitalInspectionDetails
export const setNotesByEquipmentTotal = (state: any) =>
  state.equipment.listNotesByEquipmentTotal
export const getGenerateServiceAdviosriesReport = (state: any) =>
  state.equipment.generateServiceAdviosriesReport
export const getReportStatus = (state: any) => state.equipment.status
export const selectSelectedServiceAdvisories = (state: any) =>
  state.equipment.selectedAdvisories
export const selectServiceAdvisoriesAggregates = (state: any) =>
  state.equipment.serviceAdvisoriesAggregates
export const selectServiceAdvisoriesFilter = (state: any) =>
  state.equipment.serviceAdvisoriesFilter
export const selectDigitalInspectionDetailsFilter = (state: any) =>
  state.equipment.digitalInspectionDetailsFilter
export const selectUpdatedServiceAdvisories = (state: any) =>
  state.equipment.updatedAdvisories
export const selectEquipmentProperties = (state: any) =>
  state.equipment.equipmentProperties
export const analyticsListConfigs = (state: any) =>
  state.equipment.listAnalyticConfigs
export const selectToggleSuppressions = (state: any) =>
  state.equipment.toggleSuppressions
export const selectColumnSearch = (state: any) => state.equipment.columnSearch
export const selectColumnSelected = (state: any) =>
  state.equipment.columnSelected
export const selectDigitalInspectionV2Filter = (state: any) => state?.equipment?.digitalInspectionV2?.filters