/* eslint-disable no-unused-vars */
import { Fragment, useState, useMemo, useEffect } from "react"
import { Dropdown } from "react-bootstrap"
import { ModalDialog, Spinner, Typography } from "src/blitz"
import MultiRangeSlider from "src/blitz/MultiRangeSlider/MultiRangeSlider"
import {
  DATATYPE_DATE,
  DATATYPE_NUMBER,
  DATATYPE_STRING,
  TYPE_MAX,
  TYPE_MIN,
  TYPE_TEXT
} from "src/components/buildingSummary/constants"
import { APPLY, ASC, BUTTON, DESC, DONE } from "../constants"
import styles from "./FilterModal.module.scss"
import DateRangePicker from "src/blitz/DateRange/DateRangePicker"
import { CUSTOM_RANGE, DATE } from "src/blitz/DateRange/constant"
import TextFilter from "./TextFilter/TextFilter"
import { addDays } from "date-fns"
import { sortBy } from "lodash"
import clsx from "clsx"
import translate, { TranslateComponent } from "src/common/translations"

const FilterModal = (props) => {
  const {
    columnObj,
    handleSortingChange,
    handleFilterChange,
    handleRemoveFilter,
    currentFilter,
    sortOrder,
    activeSort,
    aggregateData,
    aggregatesLoading,
    getFilterAggregates,
    clientFilter = null,
    disabled
  } = props
  // Common State
  const [showFilterModal, setShowFilterModal] = useState(false)
  const [clearAllFilters, setClearAllFiletrs] = useState(false)

  const getAggregateValue = (dataType: string, valueType: string) => {
    const aggeregateObject = aggregateData?.find(
      (agg: any) =>
        agg?.name?.split(":")[0] == dataType &&
        agg?.name?.split(":")[2] == valueType
    )
    if (
      aggeregateObject &&
      Object.keys(aggeregateObject)?.length > 0 &&
      dataType == DATATYPE_NUMBER
    ) {
      return aggeregateObject?.result?.value || 0
    } else if (
      aggeregateObject &&
      Object.keys(aggeregateObject)?.length > 0 &&
      dataType == DATATYPE_DATE
    ) {
      return new Date(aggeregateObject?.result?.value) || new Date()
    } else if (
      aggeregateObject &&
      Object.keys(aggeregateObject)?.length > 0 &&
      dataType == DATATYPE_STRING
    ) {
      return aggeregateObject?.result?.buckets || []
    }
  }

  const { otherButtons = [] } = columnObj

  // Number Related
  const minRange = getAggregateValue(DATATYPE_NUMBER, TYPE_MIN) || 0
  const maxRange = getAggregateValue(DATATYPE_NUMBER, TYPE_MAX) || 0
  const updatedMinRange =
    (currentFilter &&
      currentFilter[columnObj?.id]?.range &&
      currentFilter[columnObj?.id]?.range[0] > minRange &&
      currentFilter[columnObj?.id]?.range[0]) ||
    minRange
  const updatedMaxRange =
    (currentFilter &&
      currentFilter[columnObj?.id]?.range &&
      currentFilter[columnObj?.id]?.range[1] < maxRange &&
      currentFilter[columnObj?.id]?.range[1]) ||
    maxRange
  const [updateRange, setUpdateRange] = useState({
    min: minRange,
    max: maxRange
  })

  // Date Related
  const startDate =
    getAggregateValue(DATATYPE_DATE, TYPE_MIN) || addDays(new Date(), 1)
  const endDate = getAggregateValue(DATATYPE_DATE, TYPE_MAX)
  const updatedStartDate =
    (currentFilter &&
      currentFilter[columnObj?.id]?.range &&
      new Date(currentFilter[columnObj?.id]?.range[0]) > startDate &&
      new Date(currentFilter[columnObj?.id]?.range[0])) ||
    startDate
  const updatedEndDate =
    (currentFilter &&
      currentFilter[columnObj?.id]?.range &&
      new Date(currentFilter[columnObj?.id]?.range[1]) < endDate &&
      new Date(currentFilter[columnObj?.id]?.range[1])) ||
    endDate
  const [selectedDate, setSelectedDate] = useState({
    start: startDate,
    end: endDate
  })
  const [calendarView, setCalendarView] = useState({
    start: DATE,
    end: DATE
  }) // date, month, year

  // Text Related
  const options = clientFilter ? clientFilter : sortBy(
    getAggregateValue(DATATYPE_STRING, TYPE_TEXT) || [],
    "value"
  )
  const [selectedItems, setSelectedItems] = useState([])
  const [searchFilterValue, setSearchFilterValue] = useState("")

  const disableBtns = useMemo(
    () =>
      columnObj?.dataType === DATATYPE_STRING && selectedItems?.length == 0
        ? true
        : false,
    [columnObj?.dataType, selectedItems]
  )
  //const filteredItems = useMemo(() => options?.filter(obj => obj?.label?.includes(searchFilterValue)), [aggregateData, searchFilterValue])

  const allChecked = useMemo(
    () =>
      selectedItems?.length
        ? options?.length === selectedItems?.length
          ? true
          : null
        : false,
    [aggregateData, selectedItems]
  )

  useEffect(() => {
    if (showFilterModal) {
      getFilterAggregates(searchFilterValue)
    }
  }, [searchFilterValue])

  //Dynamic Aggregates Updation
  useEffect(() => {
    if (showFilterModal) {
      if (columnObj?.dataType == DATATYPE_DATE) {
        setSelectedDate({
          start: updatedStartDate,
          end: updatedEndDate
        })
      } else if (columnObj?.dataType == DATATYPE_NUMBER) {
        setUpdateRange({ min: updatedMinRange, max: updatedMaxRange })
      } else if (columnObj?.dataType == DATATYPE_STRING) {
        let newSelectedItems = []
        if (selectedItems?.length > 0 && currentFilter) {
          newSelectedItems = selectedItems
        } else if (currentFilter && currentFilter[columnObj?.id]?.length > 0) {
          newSelectedItems = currentFilter[columnObj?.id]?.map(
            (item) => item[columnObj?.id]?.eq
          )
        } else if (selectedItems?.length) {
          newSelectedItems = selectedItems
        } else {
          if (!searchFilterValue) {
            newSelectedItems = options?.map((obj) => obj?.id)
          }
        }
        setClearAllFiletrs(false)
        setSelectedItems(newSelectedItems)
      }
    }
  }, [showFilterModal, currentFilter, clearAllFilters, searchFilterValue])

  const onInputClear = () => {
    setSearchFilterValue("")
  }

  const onInputChange = (value: string) => {
    setSearchFilterValue(value)
  }

  const handleMinMaxRange = (minMax: any) => {
    setUpdateRange(minMax)
  }

  const onClickApplyFilter = () => {
    let filteredValues = {}
    if (columnObj?.dataType === DATATYPE_DATE) {
      filteredValues = { range: [selectedDate?.start, selectedDate?.end] }
    }
    if (columnObj?.dataType === DATATYPE_NUMBER) {
      filteredValues = { range: [updateRange?.min, updateRange?.max] }
    }
    if (columnObj?.dataType === DATATYPE_STRING) {
      filteredValues = {
        selectedItems
      }
    }
    handleFilterChange(filteredValues, columnObj)
  }

  const onRemoveFilter = (filterData?: any) => {
    setClearAllFiletrs(true)
    setSelectedItems([])
    handleRemoveFilter(filterData)
  }

  const onClickDoneFilter = () => {
    onClickApplyFilter()
    setShowFilterModal(false)
    setSearchFilterValue("")
  }

  const onOpenFilter = () => {
    setShowFilterModal(true)
  }
  const onCloseFilterModal = () => {
    setShowFilterModal(false)
    if (columnObj?.dataType == DATATYPE_STRING) {
      setSearchFilterValue("")
      setSelectedItems([])
    }
  }

  const handleTextFilterSelection = (checked, id, type) => {
    const callbackFuncSelected = (state) => {
      if (!type) {
        if (checked) {
          return options?.map((obj) => obj?.id)
        } else {
          return []
        }
      } else {
        if (checked) {
          const newVal = options?.find((obj) => obj?.id === id)?.id
          const newArray = state?.length ? [...state, newVal] : [newVal]
          return newArray
        } else {
          const newArray = state?.filter((val) => val !== id)
          return newArray
        }
      }
    }
    setSelectedItems((state) => callbackFuncSelected(state))
  }

  const modalConfig: any = {
    heading: `Filter > ${columnObj?.label || ""}`,
    modal: showFilterModal,
    handleClose: onCloseFilterModal,
    buttons: [
      {
        text: translate(APPLY),
        btnClassName: styles.filterCloseButtom,
        handleClick: onClickApplyFilter,
        type: BUTTON,
        disabled: disableBtns
      },
      {
        text: translate(DONE),
        btnClassName: styles.filterCloseButtom,
        handleClick: onClickDoneFilter,
        type: BUTTON,
        disabled: disableBtns,
        variant: "blue"
      }
    ]
  }

  return (
    <Fragment>
      <>
        {columnObj && currentFilter && currentFilter[columnObj?.id] && (
          <span className={`${styles.filterColor} icon icon-filter`}></span>
        )}
        {columnObj?.disableActions ? null : (
          <Dropdown autoClose className={styles.dropdown}>
            <Dropdown.Toggle
              className={`${styles.dropdownToggle} ${styles.noBorder}`}
              disabled={disabled}
            >
              {
                <span
                  className="icon-actions"
                  onClick={() => getFilterAggregates()}
                ></span>
              }
            </Dropdown.Toggle>
            <Dropdown.Menu className={styles.dropdownMenu}>
              {(!activeSort || (activeSort && sortOrder === DESC)) && (
                <Dropdown.Item
                  as="button"
                  className={`${styles.dropdownItem}`}
                  onClick={() => handleSortingChange(columnObj.id, ASC)}
                >
                  <span
                    className={`${styles.icon_size} icon icon-sort-ascending`}
                  ></span>
                  <span> <TranslateComponent>Sort Ascending</TranslateComponent> </span>
                </Dropdown.Item>
              )}
              {(!activeSort || (activeSort && sortOrder === ASC)) && (
                <Dropdown.Item
                  as="button"
                  className={`${styles.dropdownItem}`}
                  onClick={() => handleSortingChange(columnObj.id, DESC)}
                >
                  <span
                    className={`${styles.icon_size} icon-sort-descending`}
                  ></span>
                  <span> <TranslateComponent>Sort Descending</TranslateComponent> </span>
                </Dropdown.Item>
              )}
              {!(currentFilter && currentFilter[columnObj?.id]) && (
                <>
                  <Dropdown.Item
                    as="button"
                    className={`${styles.dropdownItem}`}
                    onClick={onOpenFilter}
                    disabled={aggregatesLoading}
                  >
                    {aggregatesLoading ? (
                      <div className={styles.spinner}>
                        <Spinner loading={aggregatesLoading} />
                      </div>
                    ) : (
                      <span
                        className={`${styles.icon_size} icon icon-filter`}
                      ></span>
                    )}
                    <span><TranslateComponent>Add Filter</TranslateComponent></span>
                  </Dropdown.Item>
                </>
              )}
              {currentFilter && currentFilter[columnObj?.id] && (
                <>
                  <Dropdown.Item
                    as="button"
                    className={`${styles.dropdownItem}`}
                    onClick={onOpenFilter}
                    disabled={aggregatesLoading}
                  >
                    {aggregatesLoading ? (
                      <div className={styles.spinner}>
                        <Spinner loading={aggregatesLoading} />
                      </div>
                    ) : (
                      <span
                        className={`${styles.icon_size} icon icon-pencil`}
                      ></span>
                    )}
                    <span><TranslateComponent>Change Filter</TranslateComponent></span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    as="button"
                    className={`${styles.dropdownItem}`}
                    onClick={() => onRemoveFilter(columnObj)}
                  >
                    <span
                      className={`${styles.icon_size} icon icon-close`}
                    ></span>
                    <span><TranslateComponent>Remove Filter</TranslateComponent></span>
                  </Dropdown.Item>
                </>
              )}
              {otherButtons?.length > 0 &&
                otherButtons?.map((otherButton, index) => {
                  const {
                    type = "",
                    label = "",
                    handleClick = () => null,
                    hidden,
                    disabled
                  } = otherButton

                  if (type === "divider") {
                    return (
                      <Dropdown.Divider
                        key={index}
                        className={styles.dropdownDivider}
                      />
                    )
                  } else if (type === "button") {
                    return (
                      <Dropdown.Item
                        disabled={disabled}
                        key={index}
                        as="button"
                        onClick={() => handleClick(otherButton)}
                        className={clsx(styles.dropdownItem, styles.flex, {
                          [styles.disabled]: disabled,
                          [styles.hidden]: hidden
                        })}
                      >
                        <div>{label}</div>
                      </Dropdown.Item>
                    )
                  } else {
                    return <Fragment key={index}></Fragment>
                  }
                })}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </>
      <ModalDialog
        modalConfig={modalConfig}
        bodyClassName={styles.filterBody}
        footerClassName={styles.filterFooter}
        customClassName={styles.customfilter}
      >
        <div className={styles.tableFilterContainer}>
          <div
            className={styles.filterContent}
            onClick={() => onRemoveFilter()}
          >
            <span className={"icon icon-blocked2"} />
            <Typography
              tagType="span"
              styleType="p4"
              className={styles.clearFilter}
              translateText={true}
            >
              Clear all Filters in Table
            </Typography>
          </div>
          {columnObj && columnObj?.dataType === DATATYPE_NUMBER && (
            <MultiRangeSlider
              min={minRange}
              max={maxRange}
              actualMin={updatedMinRange}
              actualMax={updatedMaxRange}
              handleChange={handleMinMaxRange}
            />
          )}
          {columnObj && columnObj?.dataType === DATATYPE_STRING && (
            <TextFilter
              options={options}
              selected={selectedItems}
              allChecked={allChecked}
              handleSelection={handleTextFilterSelection}
              searchFilterValue={searchFilterValue}
              onInputChange={onInputChange}
              onInputClear={onInputClear}
              aggregatesLoading={aggregatesLoading}
              translateValues={columnObj.translateColumnValues}
            />
          )}
          {columnObj && columnObj?.dataType === DATATYPE_DATE && (
            <div className={styles.filterDateWrapper}>
              <DateRangePicker
                useCalendarOnly={true}
                openCalendar={true}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                calendarView={calendarView}
                setCalendarView={setCalendarView}
                pickerCategory={CUSTOM_RANGE}
                maxStartDateProps={endDate}
                maxEndDateProps={endDate}
                minStartDateProps={startDate}
                minEndDateProps={startDate}
              />
            </div>
          )}
        </div>
      </ModalDialog>
    </Fragment>
  )
}
export default FilterModal
