import * as amplitude from "@amplitude/analytics-browser";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";
import { amplitudeKey } from "./aws-exports";
import store from "src/redux/Store.ts"
import { PROPERTIES_TO_SEND_BY_EVENT } from "src/amplitude-categories"

const API_KEY = amplitudeKey 

// Create and Install Session Replay Plugin
const sessionReplayTracking = sessionReplayPlugin();
amplitude.add(sessionReplayTracking);
const identify = new amplitude.Identify()

amplitude.init(API_KEY, null, {
    "config.autocapture.attribution":"false",
    //config.minIdLength -> Default 5 characters for identifiers such as deviceId and userId, can change here
    });
    const identifyEvent = (data) =>
    {
        const userDataToSend = {
            "user id": store?.getState()?.userData?.userInfo?.data?.id,
            "user type": store?.getState()?.userData?.userInfo?.data?.type?.name,
            "role names": store?.getState()?.userData?.roles,
            "client id": "Command Center",
            "job title": store?.getState()?.userData?.userInfo?.data?.jobTitle,
            "primary sales office" : store?.getState()?.userData?.userInfo?.data?.primarySalesOfficeId,
            "version": process.env?.REACT_APP_AWS_JOB_ID
        }
        Object.entries(data ? data : userDataToSend).forEach(([key, value]) => {
            identify.set(key, value);
        })
        amplitude.identify(identify)  
        amplitude.setUserId(store?.getState()?.appData?.userInfo?.data?.id)
    }
    
    const trackEvent = (event, options = {}) => {
        const userDataToSend = {
            "client id": "Command Center",
        }
        const mergedProperties = {...PROPERTIES_TO_SEND_BY_EVENT[event] || {}, ...userDataToSend}
        //const mergedProperties = {}
        amplitude.track(event, {
            ...Object
                .keys(mergedProperties || {})
                .reduce((result, key) => typeof mergedProperties[key] === "function"
                    ? { ...result, [key]: mergedProperties[key]() }
                    : { ...result, [key]: mergedProperties[key] },
                    {}),
            ...options
        })
    }
    
    export { amplitude, trackEvent, identifyEvent };