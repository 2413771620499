import moment from "moment"
import { isArray, isEmpty, isObject, isString, sortBy, toNumber, uniq } from "lodash"
import {
  CHART_CLIENT_ID,
  CHART_EQUIPMENT_TYPE_LIST_TYPE,
  CHART_LEGEND,
  CHART_TYPE,
  CURRENT_FETCH,
  ITEM_SEPERATION,
  SELECTED_TYPE_EQUIPMENT,
  TIMEPERIOD_TYPE,
  chartDateFormat,
  customHpathEquipmentTypes,
  editableProperties,
  editablePropertiesTimeLine,
  possibleYAxis,
} from "../constant"
import { REFERENCE_LINE_TYPE, transformLinechart } from "./ChartCategories/lineChart"
import { transformLineWithBinaryStatesChart } from "./ChartCategories/lineWithBinarystates"
import { transformLineWithControlRangeAndMeanchart } from "./ChartCategories/linewithControlRangeAndMean"
import { transformStackedBarChart } from "./ChartCategories/stackedBar"
import { transformPieChart } from "./ChartCategories/pieChart"
import { transformScatterChart } from "./ChartCategories/scatter"
import { transformScatterWithLimitchart } from "./ChartCategories/scatterWithLimits"
import { transformScatterWithPerformanceCurvechart } from "./ChartCategories/scatterWithPerformanceCurve"
import {
  CUSTOM_RANGE,
  END_DATE,
  FOUR_WEEKS,
  ONE_DAY,
  ONE_WEEK,
  START_DATE
} from "src/blitz/DateRange/constant"
import { checkInValidDate } from "src/blitz/DateRange/helper"
import { generateClientID } from "src/utils/CommonMethods"
import { APPROVED_COLOR_PALLETTE, DEFAULT_SIZE, DOT_SHAPE, Line_CHART_CATEGORY, LINE_TYPES, LineWithControlRangeAndMean_CHART_CATEGORY, NONE, Scatter_CHART_CATEGORY, ScatterWithPerformanceCurve_CHART_CATEGORY, SHAPE_SPAN_CLASS, SHAPES_TYPES, SOLID_LINE, StackedBar_CHART_CATEGORY } from "src/blitz/Charts/constants"
import { tranformParetoChart } from "./ChartCategories/paretoChart"
import LZString from "lz-string"
import { setChartAxisConigList, setChartSettings } from "src/redux/slicers/performanceSlice"
import { getDataFromStack, saveToStack } from "src/stack/utils"
import { CHART_LIST_ALL_KEY, CHART_LIST_ET } from "src/stack/constant"
import { fetchAllList } from "src/stack/Queries/fetchAll"
import { getChartList, listChartSettings } from "src/graphql/queries/chart"
import { checkIsPareto } from "../ChartContainer/ChartContainerHeader/helper"
import { AssignColorsForDefaultProperties, LegendAttributesCombination } from "./colors"
import * as d3 from "d3"
import { refereshYApplicableCharts } from "../ChartContainer/ChartLegends/constants"
import { generateLegendProps_Type } from "../ChartContainer/Types/helper"
import { TIMELINE_PROPERTY_TYPE } from "./ChartCategories/timeLineChart"
import { EXCEPTION_PROPERTY_TYPE } from "./Exceptions/helper"
import { SUPPRESSED_PROPERTY_TYPE } from "./Suppressions/constants"

// Temporary Code: Random Color Picker
const hexCharacters = [
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  "A",
  "B",
  "C",
  "D",
  "E",
  "F"
]
const getCharacter = (index) => hexCharacters[index]
export const generateNewColor = () => {
  let hexColorRep = "#"
  for (let index = 0; index < 6; index++) {
    const randomPosition = Math.floor(Math.random() * hexCharacters.length)
    hexColorRep += getCharacter(randomPosition)
  }
  return hexColorRep
}
export const getColorNotInArray = (colorsArray) => {
  let randomColor = generateNewColor()
  const maxAttempts = 100 //To avoid infiniteLoop
  let attempts = 0
  while (colorsArray?.includes(randomColor) && attempts < maxAttempts) {
    randomColor = generateNewColor()
    attempts++
  }
  return randomColor
}
export const assignNewUniqueColor = (
  availableColors = [],
  colorPallete = []
) => {
  let newUniqueColor = ""
  for (let index = 0; index < availableColors?.length; index++) {
    if (!colorPallete?.includes(availableColors[index])) {
      newUniqueColor = availableColors[index]
      break
    }
  }

  if (newUniqueColor?.length === 0) {
    newUniqueColor = getColorNotInArray(colorPallete)
  }
  return newUniqueColor
}

export const hPathIdsAndStateValidator = {
  h1001: {
    test: function (chillerType, compressorType, cricuitType) {
      if (chillerType === "watercooled") {
        if (
          "centrifugal,helical".includes(compressorType) &&
          cricuitType === "single"
        ) {
          return false
        }
      }

      return true
    }
  },

  h1002: {
    test: function (chillerType, compressorType, cricuitType) {
      if (chillerType === "watercooled") {
        if (
          "centrifugal,helical".includes(compressorType) &&
          cricuitType === "single"
        ) {
          return true
        }
      }

      return false
    }
  },

  h1003: {
    test: function (chillerType, compressorType, cricuitType) {
      if (chillerType === "watercooled") {
        if (
          "centrifugal".includes(compressorType) &&
          cricuitType === "single"
        ) {
          return true
        }
      }

      return false
    }
  },

  h1004: {
    test: function (chillerType, compressorType, cricuitType) {
      if (chillerType === "watercooled") {
        if ("helical".includes(compressorType) && cricuitType === "single") {
          return true
        }
      }

      return false
    }
  },

  h1005: {
    test: function (chillerType) {
      return chillerType === "aircooled" ? false : true
    }
  },

  h1006: {
    test: function (chillerType, compressorType, cricuitType) {
      if (chillerType === "aircooled") {
        if (
          "helical,scroll".includes(compressorType) &&
          cricuitType === "multiple"
        ) {
          return false
        }
      }

      return true
    }
  },

  h1007: {
    test: function (chillerType, compressorType, cricuitType) {
      if (chillerType === "aircooled") {
        if (
          "helical,scroll".includes(compressorType) &&
          cricuitType === "multiple"
        ) {
          return true
        }
      }

      return false
    }
  },

  h1008: {
    test: function (chillerType, compressorType, cricuitType) {
      // allow if type is either multiple or unknown

      if (cricuitType !== "single") {
        return true
      }

      return false
    }
  },

  h1009: {
    test: function (chillerType, compressorType, cricuitType) {
      // allow if type is either single or unknown

      if (cricuitType !== "multiple") {
        return true
      }

      return false
    }
  },

  // To support instance level chart (ex : Motor power performance)

  h1010: {
    test: function (
      chillerType,
      compressorType,
      cricuitType,
      compressorCountType
    ) {
      // allow if circuit & compressor count is either single or unknown

      if (compressorCountType !== "multiple") {
        return true
      }

      return false
    }
  },

  // To support instance level chart (ex : Motor power performance)

  h1011: {
    test: function (
      chillerType,
      compressorType,
      cricuitType,
      compressorCountType,
      instance
    ) {
      if (compressorCountType !== "multiple") {
        return true
      }

      // allow if circuit & compressor count is either multiple or unknown and valid below defined instance

      const allowedInstance = {
        "1A": true,

        "2A": true
      }

      if (
        (allowedInstance[instance] || "centrifugal".includes(compressorType)) &&
        (compressorCountType !== "single" || cricuitType !== "single")
      ) {
        return true
      }

      return false
    }
  },

  h1012: {
    test: function (chillerType, compressorType, cricuitType) {
      if (chillerType === "aircooled" && cricuitType === "multiple") {
        return true
      }

      return false
    }
  },

  h1013: {
    test: function (chillerType) {
      return chillerType === "watercooled" ? true : false
    }
  },

  h1014: {
    test: function (chillerType, compressorType, cricuitType) {
      if (chillerType === "watercooled" && cricuitType === "single") {
        return true
      }

      return false
    }
  },

  h1015: {
    test: function (chillerType, compressorType, cricuitType) {
      if (
        (chillerType === "watercooled" || chillerType === "aircooled") &&
        cricuitType === "multiple"
      ) {
        return true
      }

      return false
    }
  },

  h1016: {
    test: function (chillerType, compressorType) {
      if (chillerType === "aircooled") {
        return "helical,scroll".includes(compressorType) ? true : false
      }

      return true
    }
  },

  h1017: {
    test: function (chillerType, compressorType, cricuitType) {
      if (
        (chillerType === "aircooled" || chillerType === "watercooled") &&
        cricuitType === "multiple"
      ) {
        // if water cooled, dont check compressor type, return true always

        // if air cooled, check compressor type, return value based on condition

        return chillerType === "watercooled" ||
          "helical,scroll".includes(compressorType)
          ? true
          : false
      }

      return false
    }
  },

  h1018: {
    test: function (chillerType, compressorType, cricuitType) {
      if (
        (chillerType === "aircooled" || chillerType === "watercooled") &&
        cricuitType === "single"
      ) {
        // if water cooled, check compressor type is helical or scroll

        // if air cooled, check compressor type is scroll or not

        return (chillerType === "watercooled"
          ? "centrifugal,helical"
          : "scroll"
        ).includes(compressorType)
          ? true
          : false
      }

      return false
    }
  },

  h1019: {
    test: function (chillerType, compressorType, cricuitType) {
      return (
        cricuitType === "single" ? "centrifugal,helical" : "centrifugal"
      ).includes(compressorType)
    }
  },

  h1020: {
    test: function (chillerType, compressorType, cricuitType) {
      if (cricuitType === "single") {
        return "centrifugal,helical".includes(compressorType)
      }

      return false
    }
  }
}

export const extractword = (str, start, end) => {
  const startindex = str.indexOf(start)
  const endindex = str.indexOf(end, startindex)
  if (startindex != -1 && endindex != -1 && endindex > startindex)
    return str.substring(startindex + 1, endindex)
}

export const VAV_BOX = "VAV-BOX"
export const VAS_TYPE = "VAS"

export const getChart = (chartClientId: string, charts: any) => {
  const chart = charts[chartClientId]
  return chart ? chart : {}
}

export const pickLegendClassName = (shape?: string) =>
  SHAPE_SPAN_CLASS[shape] || ""

export const pickColorstyleObject = (
  type: string,
  color: string | string[]
) => {
  if (type === CHART_LEGEND) {
    const colorCode = isString(color) ? color : isArray(color) ? color?.[0] : ""
    return {
      color: colorCode
    }
  } else {
    if (color?.length === 1) {
      return {
        backgroundColor: color?.[0]
      }
    } else {
      return {
        backgroundImage: `linear-gradient(135deg, ${color?.[0]} 50%, ${color?.[1]} 50%)`
      }
    }
  }
}

export const intervalToMinutes = (inputType) => {
  const type = inputType?.slice(-1)
  const val = inputType?.slice(0, inputType?.length - 1)
  const calculatedMinutes =
    parseFloat(val) *
    (type === "m" ? 1 : type === "h" ? 60 : type === "d" ? 24 * 60 : 1)

  return calculatedMinutes
}

export const convertTimeToMinutes = (timeString) => {
  const [hoursString, minutesString] = timeString.split(":")
  const hours = parseInt(hoursString)
  const minutes = parseInt(minutesString)

  const totalMinutes = hours * 60 + minutes
  return totalMinutes
}
export const getTimeIndex = (time, interval) => {
  const intervalsPerDay = (24 * 60) / interval
  const index = Math.floor(time / interval) % intervalsPerDay

  return index
}

const getPropertyKey = (key = "", equipmentType) => {
  return customHpathEquipmentTypes?.includes(equipmentType?.toLowerCase())
    ? /\d[A-Za-z]$/.test(key)
      ? key?.slice(0, -2)
      : /\d$/.test(key)
        ? key?.slice(0, -1)
        : key
    : key
}

export const getChartObject = (obj, propKey, equipmentType) => {
  const parentList = obj?.parameterList?.parent || []
  const weatherList = obj?.weatherList || []
  const propertyKey = getPropertyKey(propKey, equipmentType)
  const settingsList = obj?.settingList || []

  let allChild = []
  let allSibling = []

  if (obj?.parameterList && !isEmpty(obj?.parameterList?.child)) {
    const child = obj?.parameterList?.child || {}
    Object.keys(child)?.map((key) => {
      const childKey = isArray(child?.[key])
        ? child?.[key].map((propertyName) =>
          getPropertyKey(propertyName, equipmentType)
        )
        : []
      allChild.push(...childKey)
    })
  }

  if (obj?.parameterList && !isEmpty(obj?.parameterList?.sibling)) {
    const sibling = obj?.parameterList?.sibling || {}
    Object.keys(sibling)?.map((key) => {
      let siblingKey = []
      if (isArray(sibling?.[key]) && sibling?.[key]?.length > 0) {
        siblingKey = sibling[key].map((propertyName) =>
          getPropertyKey(propertyName, key)
        )
      } else if (typeof sibling?.[key] === "object") {
        Object?.keys(sibling?.[key] || {})?.forEach((objkey) => {
          const siblings = sibling?.[key]?.[objkey] || []
          siblingKey?.push(...siblings)
        })
      }
      if (siblingKey) allSibling?.push(...siblingKey)
    })
  }

  allChild = uniq(allChild)
  allSibling = uniq(allSibling)
  return (
    parentList.includes(propertyKey) ||
    weatherList.includes(propertyKey) ||
    settingsList.includes(propertyKey) ||
    allChild.includes(propertyKey) ||
    allSibling.includes(propertyKey)
  )
}

const tranformFunctions = {
  Line: transformLinechart,
  LineWithBinaryStates: transformLineWithBinaryStatesChart,
  LineWithControlRangeAndMean: transformLineWithControlRangeAndMeanchart,
  StackedBar: transformStackedBarChart,
  Pie: transformPieChart,
  Scatter: transformScatterChart,
  ScatterWithLimits: transformScatterWithLimitchart,
  ScatterWithPerformanceCurve: transformScatterWithPerformanceCurvechart,
  Pareto: tranformParetoChart
}

export const tranformChartData = (type, props) => {
  const selectedTranformFunction = tranformFunctions[type]
  if (selectedTranformFunction) {
    return selectedTranformFunction(props)
  } else {
    console.log("Chart Type Unavailabe error occured")
  }
}

// export const timlinePropertyMapping = {
//   OccupancyActive: {
//     displayName: "Occupancy: Active",
//     colorByValue: {
//       Occupied: "#66ff00",
//       Unoccupied: "#3a3a3a"
//     }
//   },
//   HeatCoolMode: {
//     displayName: "Heat/Cool Mode: Space",
//     colorByValue: {
//       Heat: "#FF0000",
//       Cool: "#0000FF"
//     }
//   },
//   FanOutput: {
//     displayName: "Fan Output",
//     colorByValue: {
//       On: "#e6e688",
//       Off: "#3a3a3a"
//     }
//   },
//   FanSpeedOutput: {
//     displayName: "Fan Speed Output",
//     colorByValue: {
//       true: "#FFFFED"
//     }
//   },
//   SpaceTemp: {
//     displayName: "Space Temp",
//     colorByValue: {
//       true: "#66ff00"
//     }
//   },
//   SpaceTemperatureActive: {
//     displayName: "Space Temperature Active",
//     colorByValue: {
//       true: "#66ff00"
//     }
//   },
//   SpaceTempSetpointActive: {
//     displayName: "Space Temp Setpoint: Active",
//     colorByValue: {
//       true: "#808000"
//     }
//   },
//   SpaceTempCoolingSetpointActive: {
//     displayName: "Space Temp Cooling Setpoint: Active",
//     colorByValue: {
//       true: "#0000FF"
//     }
//   },
//   SpaceTempHeatingSetpointActive: {
//     displayName: "Space Temp Heating Setpoint: Active",
//     colorByValue: {
//       true: "#FF0000"
//     }
//   },
//   CoolOutput: {
//     displayName: "Cool Output %",
//     colorByValue: {
//       true: "#ADD8E6"
//     }
//   },
//   AirValveOutput: {
//     displayName: "Air Valve Output",
//     colorByValue: {
//       true: "#ADD8E6"
//     }
//   },
//   HeatOutput: {
//     displayName: "Heat Output %",
//     colorByValue: {
//       true: "#ffcccb"
//     }
//   },
//   DischargeAirTemp: {
//     displayName: "Discharge Air Temp",
//     colorByValue: {
//       true: "#CC5500"
//     }
//   },
//   SupplyAirTemperature: {
//     displayName: "Supply Air Temperature",
//     colorByValue: {
//       true: "#FFD580"
//     }
//   },
//   ChillerCompRunning: {
//     displayName: "Chiller Comp Running",
//     colorByValue: {
//       On: "#FFFF00",
//       Off: "#3a3a3a"
//     }
//   },
//   ChillerDiagManualResetRequired: {
//     displayName: "Chiller Diag: Manual Reset Required",
//     colorByValue: {
//       Yes: "#8B0000",
//       No: "#3a3a3a"
//     }
//   },
//   ChillerDiagAutomaticReset: {
//     displayName: "Chiller Diag: Automatic Reset",
//     colorByValue: {
//       Yes: "#0000FF",
//       No: "#3a3a3a"
//     }
//   },
//   DiagnosticManualResetRequired: {
//     displayName: "Diag: Manual Reset Required",
//     colorByValue: {
//       Yes: "#8B0000",
//       No: "#3a3a3a"
//     }
//   },
//   DiagnosticAutomaticReset: {
//     displayName: "Diag: Automatic Reset",
//     colorByValue: {
//       Yes: "#0000FF",
//       No: "#3a3a3a"
//     }
//   }
// }

export const formatChartDate = (date: string) => {
  const dateObject = new Date(date)
  const year = dateObject?.getFullYear()
  const month = dateObject?.getMonth() + 1
  const day = dateObject?.getDate()
  return moment(`${month}/${day}/${year}`, "MM/DD/YYYY").format(chartDateFormat)
}

// MM-DD-YYYY
export const formatToURLDate = (date: string) => {
  const dateObject = new Date(date)
  const year = dateObject?.getFullYear()
  const month = dateObject?.getMonth() + 1
  const day = dateObject?.getDate()
  return `${month}-${day}-${year}`
}

type diffType = "d" | "h" | "m" | "s"
export const getDateDifference = (
  endDate: string,
  startDate: string,
  diffType: diffType = "d"
) => {
  const date2: Date = new Date(endDate)
  const date1: Date = new Date(startDate)
  const timeDifference = date2.getTime() - date1.getTime()
  const diff =
    diffType === "d"
      ? Math.floor(timeDifference / (1000 * 60 * 60 * 24))
      : diffType === "m"
        ? Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        : diffType === "h"
          ? Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60))
          : Math.floor((timeDifference % (1000 * 60)) / 1000)

  return diff
}

export const getCurrentDate = () => {
  return moment().format(chartDateFormat)
}

export const getCurrentDateBuilding = (tz) => {
  return moment.tz.zone(tz)
    ? moment.tz(tz)?.format(chartDateFormat)
    : moment(new Date())?.format(chartDateFormat)
}

export const addDate = (date: string, type: any, count: number) =>
  moment(date, chartDateFormat).add(count, type).format(chartDateFormat)

export const getChartFilterState = (
  chartClientId,
  equipmentId,
  index,
  correctedStartDate = "",
  correctedEndDate = "",
  chartId = null,
  timePeriod,
  initialChartStateUpdate
) => {
  return {
    [CHART_CLIENT_ID]: chartClientId,
    [START_DATE]: correctedStartDate,
    [END_DATE]: correctedEndDate,
    [TIMEPERIOD_TYPE]: timePeriod,
    [CHART_TYPE]: null,
    chartId: chartId,
    selectedEquipmentId: equipmentId,
    [SELECTED_TYPE_EQUIPMENT]: null,
    [CHART_EQUIPMENT_TYPE_LIST_TYPE]: {
      loading: false,
      data: null,
      plainEquipmentList: null,
      error: false
    },
    isLoading: false,
    isError: false,
    isFirst: index === 0,
    initialLoad: true,
    [CURRENT_FETCH]: null,
    initialChartStateUpdate
  }
}

export const generateChartsInitialFilterState = (
  isBuildingPerformance = false,
  equipmentIds = [],
  chartIds = [],
  startDates = [],
  endDates = [],
  clientIds = [],
  summaryInfo: any = {},
  initialChartStateUpdate,
  chartURLState
) => {
  const chartsFilter: any = {}
  const chartsFilterPosition: any = []
  const chartsColors: any = {}
  const initialChartIds: any = []
  const initialStartDates: any = []
  const initialEndDates: any = []

  const chartInstances = isBuildingPerformance
    ? startDates?.length > 0
      ? startDates
      : endDates?.length > 0
        ? endDates
        : chartIds?.length > 0
          ? chartIds
          : [null]
    : equipmentIds?.length > 0
      ? equipmentIds
      : [null]

  chartInstances?.forEach((id, index) => {
    const chartClientId =
      clientIds?.length > 0 ? clientIds[index] : generateClientID()
    const startDate = startDates[index]
    const endDate = endDates[index]
    const chartId = chartIds[index]

    const buildingDateTime = moment.tz.zone(summaryInfo?.tz)
      ? moment.tz(summaryInfo?.tz)?.format(chartDateFormat)
      : null

    const finalDate = buildingDateTime
      ? moment(new Date(buildingDateTime))?.isBefore(moment(new Date()))
        ? new Date(buildingDateTime)
        : new Date()
      : new Date()
    const queryStartDate = (startDate || "")?.replace(/-/g, "/")
    const queryEndDate = (endDate || "")?.replace(/-/g, "/")
    const defaultDates =
      checkInValidDate(queryStartDate) ||
      checkInValidDate(queryEndDate) ||
      new Date(queryStartDate) > new Date(queryEndDate) ||
      new Date(queryStartDate) > finalDate ||
      new Date(queryEndDate) > finalDate
    const correctedStartDate = defaultDates
      ? getCurrentDateBuilding(summaryInfo?.tz)
      : formatChartDate(queryStartDate)
    const correctedEndDate = defaultDates
      ? getCurrentDateBuilding(summaryInfo?.tz)
      : formatChartDate(queryEndDate)
    const dateDiff = getDateDifference(endDate, startDate)
    const timePeriod =
      defaultDates || dateDiff === 0
        ? ONE_DAY
        : dateDiff === ONE_WEEK - 1
          ? ONE_WEEK
          : dateDiff === FOUR_WEEKS - 1
            ? FOUR_WEEKS
            : CUSTOM_RANGE
    const correctedQuerySDate = formatToURLDate(correctedStartDate)
    const correctedQueryEDate = formatToURLDate(correctedEndDate)

    let chartFilterObject: any = getChartFilterState(
      chartClientId,
      id,
      index,
      correctedStartDate,
      correctedEndDate,
      chartId,
      timePeriod,
      initialChartStateUpdate
    )
    const legendAttributesCombination = new LegendAttributesCombination({ color: APPROVED_COLOR_PALLETTE, shape: SHAPES_TYPES, }, ["color", "shape"]);
    const assignColorsForDefaultProperties = new AssignColorsForDefaultProperties()
    if (chartURLState?.[chartClientId]) {
      let axis = chartURLState?.[chartClientId]?.axis || []


      if (isArray(axis)) {
        axis = axis?.map((axisObj) => {
          const addedLegends = (chartURLState?.[chartClientId]?.legends || [])?.filter((obj) => obj?.isAdded && obj?.axisName === axisObj?.axisName) || []
          const added: any = {}
          addedLegends?.forEach((obj) => {
            if (!added?.[obj?.equipmentType]) {
              added[obj?.equipmentType] = []
            }
            added[obj?.equipmentType].push(obj?.id)
          })
          return {
            ...axisObj,
            added
          }
        })
      }

      chartFilterObject = {
        ...chartFilterObject,
        added: {
          axisList: axis
        }
      }
    }

    chartsFilter[chartClientId] = chartFilterObject
    chartsFilterPosition.push(chartClientId)
    chartsColors[chartClientId] = {
      legendAttributesCombination,
      assignColorsForDefaultProperties
    }
    initialChartIds.push(chartId)
    initialStartDates.push(correctedQuerySDate)
    initialEndDates.push(correctedQueryEDate)
  })

  return {
    passToState: {
      chartsFilter: chartsFilter,
      chartsFilterPosition: chartsFilterPosition,
      chartsColors
    },
    initialChartIds,
    initialStartDates,
    initialEndDates
  }
}

// Return array of values of a Parameter which are seperated by ","
export const decodeURLSearchParams = (paramVal: any) => {
  return paramVal === null ? [] : decodeURI(paramVal)?.split(",") || []
}

export const encodeURISearchParams = (paramVal: Array<string>) => {
  const list = isArray(paramVal) ? paramVal : []
  return encodeURI(list?.join(","))
}

export const handleFileName = (
  isBuildingPerformance: any,
  chartsFilter: any,
  facilityName: any,
  fileExtension: string,
  index = ""
) => {
  let fileName = ""
  if (isBuildingPerformance) {
    const PARETO = "pareto"
    const FACILITY = "facility"
    const dates = [];
    const categories = []
    const chartTitles = []
    Object.keys(chartsFilter).forEach((key) => {
      const chartFilter = getChart(key, chartsFilter)
      const selectedEquipment = chartFilter?.selectedTypeEquipment?.value
      dates.push(moment(chartFilter?.endDate, chartDateFormat).format("YYYY-MM-DD"))
      categories.push(checkIsPareto(isBuildingPerformance, selectedEquipment) ? PARETO : FACILITY)
      chartTitles.push(chartFilter?.chartType?.title)
    })
    const date = uniq(dates).length === 1 ? dates?.[0] : "Multiple Dates"
    const category = categories?.find((cat) => cat === PARETO) ? "" : "_Facility"
    const chartTitle = uniq(chartTitles).length === 1 ? chartTitles?.[0] : "Multiple Charts"
    fileName = `${facilityName}_${date}${category}_${chartTitle}`
  } else {
    const dates = [];
    const equipmentNames = []
    const chartTitles = []
    Object.keys(chartsFilter).forEach((key) => {
      const chartFilter = getChart(key, chartsFilter)
      dates.push(moment(chartFilter?.endDate, chartDateFormat).format("YYYY-MM-DD"))
      equipmentNames.push(chartFilter?.selectedTypeEquipment?.value?.name)
      chartTitles.push(chartFilter?.chartType?.title)
    })
    const date = uniq(dates).length === 1 ? dates?.[0] : "Multiple Dates"
    const equipmentName = uniq(equipmentNames).length === 1 ? equipmentNames?.[0] : "Multiple Equipment"
    const chartTitle = uniq(chartTitles).length === 1 ? chartTitles?.[0] : "Multiple Charts"
    fileName = `${facilityName}_${date}_${equipmentName}_${chartTitle}`
  }
  return `${fileName}${index}.${fileExtension}`
}

export const constructSearchParam = (id, value) => {
  return value ? `${id}=${value}` : ""
}

export const buildCreateFindingFromChartTCURL = (
  traneConnectURL,
  organisationId,
  buildingId,
  encodedccChartURL,
  encodedS3Locations,
  encodedEquipmentIds,
  encodedChartTitles
) => {
  const {
    absoluteURL = "",
    findingsPath = "",
    findingParameters = {}
  } = traneConnectURL

  let urlParams = ""
  const urlArray = [
    constructSearchParam(findingParameters?.accountId, organisationId),
    constructSearchParam(findingParameters?.buildingId, buildingId),
    constructSearchParam(findingParameters?.equipmentId, encodedEquipmentIds),
    constructSearchParam(findingParameters?.chartTitle, encodedChartTitles),
    constructSearchParam(findingParameters?.chartImage, encodedS3Locations),
    constructSearchParam(findingParameters?.chartLink, encodedccChartURL)
  ]
  urlArray?.forEach((item: string) => {
    if (Boolean(item)) {
      urlParams += `${item}&`
    }
  })
  const urlTC = `${absoluteURL}/${findingsPath}${Boolean(urlParams) ? "?" : ""
    }${urlParams?.slice(0, -1)}`
  return urlTC
}

export const getMomentLocalTimeStamp = (tz) => {
  const localTimeStamp = Boolean(tz)
    ? moment(
      moment.tz(moment(), tz).format("MM-DD-YYYY HH:mm"),
      "MM-DD-YYYY HH:mm"
    )
    : moment()
  return localTimeStamp
}

export const decodeChartState = (encodedString) => {
  if (encodedString) {
    const decompressed =
      LZString.decompressFromEncodedURIComponent(encodedString)

    return JSON.parse(decompressed)
  }
}
export const groupByEquipmentTypeAndId = (data = []) => {
  return data.reduce((acc, item) => {
    const { equipmentType, equipmentId } = item;

    if (!acc[equipmentType]) {
      acc[equipmentType] = {};
    }

    if (!acc[equipmentType][equipmentId]) {
      acc[equipmentType][equipmentId] = [];
    }

    acc[equipmentType][equipmentId].push(item);

    return acc;
  }, {});
}


export const fetchChartAxisConfigListAll = async (dispatch, isHeadLess) => {
  let chartList: any = []
  try {
    dispatch(setChartAxisConigList({ type: "Loading" }))
    const stackKey: any = getDataFromStack(CHART_LIST_ALL_KEY)
    const stackData: any = getDataFromStack(stackKey);
    if (!stackData) {
      const response = await fetchAllList({ query: getChartList, isHeadLess, stackKey, path: "data.listCharts", stackExpiry: CHART_LIST_ET });
      if (response?.list) {
        chartList = response?.list || []
        chartList = sortBy(chartList || [], "title")
        saveToStack(CHART_LIST_ALL_KEY, chartList, null, CHART_LIST_ET)
      } else {
        throw "Error Occured While fetching chart configuration"
      }
    } else {
      chartList = stackData
    }
    const options = []
    chartList?.forEach((obj) => {
      const axisConfig = isArray(JSON.parse(obj?.axisConfig))
        ? JSON.parse(obj?.axisConfig)
        : []
      const newObj = {
        ...obj,
        axisConfig: sortBy(axisConfig, ["axisName"])
      }
      const isCustomTitle = isString(obj?.title) && obj?.title?.toLowerCase()?.includes("xx")
      let newTitle = obj?.title
      if (isCustomTitle) {
        newTitle = isString(obj?.title) ? obj?.title.replace(/xx/gi, "") : obj?.title
        // newObj.title = newTitle
      }
      options.push({
        title: newTitle,
        value: newObj,
        type: "button"
      })
    })
    dispatch(setChartAxisConigList({ type: "Success", data: options || [] }))
  } catch (err) {
    dispatch(setChartAxisConigList({ type: "Failure" }))
  }
}

export const fetchChartSettings = async (dispatch, isHeadLess): Promise<any> => {
  try {
    dispatch(setChartSettings({ type: "Loading" }))
    const variables: any = {}
    if (!isHeadLess) {
      variables.authMode = "AMAZON_COGNITO_USER_POOLS"
    }
    const chartSettings = await fetchAllList({ query: listChartSettings, variables, isHeadLess, path: "data.listChartSettings" })
    if (!chartSettings?.error) {
      const list = chartSettings?.list?.map((obj) => {
        const newObj: any = { ...obj }
        const settings = JSON.parse(obj?.settings)
        const multiSettings = JSON.parse(obj?.multiSettings)
        newObj.settings = settings
        newObj.multiSettings = multiSettings
        return newObj
      })
      dispatch(setChartSettings({ type: "Success", data: list }))
    }
  } catch (errorData) {
    dispatch(setChartSettings({ type: "Failure" }))
  }
}

// Legend ID Generator 
export const legendIDGenerator = (id = "", key = "", instance = "") => {
  return `${id}${id ? ITEM_SEPERATION : ""}${key}${instance}`
}


export const updateAxisList = (transformedData, newAxisList, type, oldAxisList = [], isAdded = false) => {

  let updatedAxisList = newAxisList

  if ([Line_CHART_CATEGORY, Scatter_CHART_CATEGORY]?.includes(type)) {
    const data = transformedData?.chartData?.data || {}
    const legends = transformedData?.chartProps?.legends
    updatedAxisList = updatedAxisList?.map((obj) => {
      let calMin = Infinity
      let calMax = -Infinity
      Object.keys(legends)?.forEach((id) => {
        const legendObj = legends?.[id]
        if (legendObj?.axisName === obj?.axisName) {
          const lData = data?.[legendObj?.id]?.data || []
          const calculatedRange = d3.extent(lData) || []
          const min = calculatedRange?.[0]
          const max = calculatedRange?.[1]
          if (min < calMin) {
            calMin = min
          }
          if (max > calMax) {
            calMax = max
          }
        }
      })
      const range = [calMin, calMax]
      return {
        ...obj,
        defaultRange: range,
        range: range
      }
    })
  }
  if ([LineWithControlRangeAndMean_CHART_CATEGORY, StackedBar_CHART_CATEGORY]?.includes(type)) {
    updatedAxisList = updatedAxisList?.map((obj) => {
      const isY = possibleYAxis?.includes(obj?.axisName)
      if (isY) {
        const range = [0, 100]
        return {
          ...obj,
          range: range,
          defaultRange: range
        }
      } else {
        return obj
      }
    })
  }
  if (type === ScatterWithPerformanceCurve_CHART_CATEGORY) {
    const curveData = transformedData?.chartProps?.curveData || {}
    const legends = transformedData?.chartProps?.legends || []
    const newData = transformedData?.chartData?.data || {}
    const axisLimits: any = {
      x: [],
      y: []
    }
    const xValues = []
    const yValues = []
    for (const axisObj of updatedAxisList) {
      if (axisObj?.axisType === "characteristicCurve") {
        Object.values(curveData)?.forEach((arrValues) => {
          if (Array.isArray(arrValues)) {
            arrValues?.forEach((obj) => {
              if (isFinite(toNumber(obj?.curveXAxisValue))) {
                xValues.push(toNumber(obj?.curveXAxisValue))
              }
              if (isFinite(toNumber(obj?.curveYAxisValue))) {
                yValues.push(toNumber(obj?.curveYAxisValue))
              }
            })
          }
        })
      } else if (axisObj?.axisType === "x") {
        const legendArr = legends?.filter((obj) => obj?.axisName === "x")
        legendArr?.forEach((obj) => {
          const data = newData?.[obj?.id]?.data || []
          xValues.push(...data)
        })
      } else if (axisObj?.axisType === "y") {
        const legendArr = legends?.filter((obj) => obj?.axisName === "y")
        legendArr?.forEach((obj) => {
          const data = newData?.[obj?.id]?.data || []
          yValues.push(...data)
        })
      }
    }

    Object.keys(axisLimits)?.forEach((key) => {
      const axisLimit = key === "x" ? d3.extent(xValues) : d3.extent(yValues)
      if (key === "x") {
        const minValue = axisLimit?.[0]
        const maxValue = axisLimit?.[1]
        const min = minValue < 0 ? (minValue === -Infinity ? 0 : minValue) : 0
        const max =
          maxValue > 100 ? (minValue === Infinity ? 100 : maxValue) : 100
        axisLimits[key] = [min, max]
      } else {
        const minValue = axisLimit?.[0]
        const maxValue = axisLimit?.[1]
        const sameRange = minValue === maxValue
        const finalMin =
          minValue === -Infinity
            ? maxValue === Infinity
              ? 0
              : maxValue * 0.5
            : sameRange
              ? minValue - minValue * 0.5
              : minValue
        const finalMax =
          maxValue === Infinity
            ? minValue === -Infinity
              ? 10
              : minValue * 1.5
            : sameRange
              ? maxValue + maxValue * 0.5
              : maxValue
        axisLimits[key] = [finalMin, finalMax]
      }
    })

    updatedAxisList = updatedAxisList?.map((obj) => {
      if (obj?.axisName === "timeline") {
        return obj
      }
      return {
        ...obj,
        range: axisLimits?.[obj?.axisName]
      }
    })
  }

  if (!isAdded) {
    // Retaining The axis list if userEdited Range present or calculating New if the fetch call is new
    const isAnyAxisRangeEdited =
      oldAxisList?.filter((axis) => axis?.isRangeEdited)?.length > 0
    if (
      refereshYApplicableCharts?.includes(type) &&
      oldAxisList &&
      oldAxisList?.length > 0 &&
      isAnyAxisRangeEdited
    ) {
      updatedAxisList = updatedAxisList?.map((item, index) =>
        oldAxisList?.[index]?.isRangeEdited
          ? {
            ...item,
            range: oldAxisList[index]?.range || item.range,
            isRangeEdited: oldAxisList[index]?.isRangeEdited
          }
          : { ...item }
      )
    }
  }

  return updatedAxisList

}

export const generateLegend = (props: generateLegendProps_Type) => {
  const { legendId = "", name = "", axisName = "", symbol = "", type = "", isAdded = false, legendEquipmentType = "", propertyKey = "", value, show = true, batch = true, oldLegends, propertyType = "", additionalAttributes } = props;

  let legendObj: any = {}
  if (oldLegends && oldLegends?.[legendId]) {
    legendObj = oldLegends?.[legendId]
  } else {
    legendObj = {
      id: legendId,
      name: name,
      axisName: axisName,
      symbol: symbol,
      show: show,
      visible: true,
      defaultSettings: {}
    }

    if (isObject(additionalAttributes)) {
      legendObj = {
        ...legendObj,
        ...(additionalAttributes || {})
      }
    }

    if (type === "timeline") {
      legendObj.propertyName = propertyKey
      legendObj.batch = batch
      if (propertyType) {
        legendObj.propertyType = propertyType
      }
    } else if (type === REFERENCE_LINE_TYPE) {
      legendObj.value = value
    } else {
      legendObj.lineType = SOLID_LINE
      legendObj.strokeWidth = DEFAULT_SIZE
      legendObj.shapeSize = DEFAULT_SIZE
      if (type) {
        legendObj.type = type
      }
      legendObj.isAdded = isAdded
      legendObj.equipmentType = legendEquipmentType
    }
  }

  return legendObj
}

export const legendName = (props: any = {}, nameConfig: any = []) => {
  const { building, selectedEquipment, propertyName, equipmentName } = props
  const [p = true, e = true] = nameConfig;
  if (p && e) {
    return building || selectedEquipment ? `#$ ${propertyName}` : `${equipmentName || ""} #$ ${propertyName || ""}`
  } else if (e && !p) {
    return equipmentName
  } else if (p && !e) {
    return `#$ ${propertyName}`
  } else {
    return `${equipmentName || ""} #$ ${propertyName || ""}`
  }
}

export const getMarkerIntervals = (startDate, endDate, maxNumber = 8, dataIntervalMinutes = 15) => {

  if (maxNumber < 1 || maxNumber > 10) {
    throw new Error("maxNumber must be between 1 and 10");
  }

  startDate = new Date(startDate);
  endDate = new Date(endDate);

  const durationMs = endDate - startDate;

  const totalMinutes = durationMs / (1000 * 60);
  const totalDays = totalMinutes / (60 * 24);

  let bestInterval;
  let numMarkers;
  if (totalDays < 1) {
    bestInterval = dataIntervalMinutes;
    numMarkers = Math.floor(totalMinutes / bestInterval);
    if (numMarkers > maxNumber) {
      bestInterval = Math.ceil(totalMinutes / maxNumber / 5) * 5;
      numMarkers = Math.floor(totalMinutes / bestInterval);
    }
  } else {
    if (totalDays > 7) {
      let intervalFound = false;
      for (let days = 1; days <= Math.floor(totalDays); days++) {
        numMarkers = Math.floor(totalDays / days);
        if (numMarkers >= 1 && numMarkers <= maxNumber) {
          bestInterval = days;
          intervalFound = true;
          break;
        }
      }

      if (!intervalFound) {
        for (let days = 1; days <= Math.floor(totalDays); days++) {
          numMarkers = Math.floor(totalDays / days);
          if (numMarkers >= 1 && numMarkers <= maxNumber) {
            bestInterval = days;
            break;
          }
        }
      }
    } else {
      const idealInterval = totalMinutes / maxNumber;

      bestInterval = Math.ceil(idealInterval / 5) * 5;

      numMarkers = Math.floor(totalMinutes / bestInterval);

      if (numMarkers > maxNumber) {
        bestInterval = Math.floor(totalMinutes / maxNumber / 5) * 5;
        numMarkers = Math.floor(totalMinutes / bestInterval);
      }
    }
  }

  const markerTimes = [];

  for (let i = 0; i < numMarkers; i++) {
    let markerTime;
    if (bestInterval >= 1 && bestInterval <= totalDays) {
      markerTime = new Date(startDate.getTime() + (i * bestInterval * 24 * 60 * 60 * 1000)); // Interval in days
      markerTime.setHours(0, 0, 0, 0);
    } else {
      markerTime = new Date(startDate.getTime() + (i * bestInterval * 60 * 1000)); // Interval in minutes
    }
    const formattedMarkerTime = moment(markerTime).format("YYYY-MM-DDTHH:mm:ss")
    markerTimes.push(formattedMarkerTime);
  }

  return {
    bestInterval: bestInterval,
    markerTimes: markerTimes
  };
}

export const assignAttributeStateToTLLegends = (timeLineLegends: any = {}, exceptionLegends: any = {}, suppressionLegends: any = {}, assignedAttributes, legendAttributesCombination, oldTimeLineLegends) => {
  let timeLineLegendArray = [...Object.values(timeLineLegends || {}), ...Object.values(exceptionLegends || {}), ...Object.values(suppressionLegends || {})]
  timeLineLegendArray = sortBy(timeLineLegendArray, "name")
  timeLineLegendArray?.forEach((legend: any) => {
    if (!(oldTimeLineLegends && oldTimeLineLegends?.[legend?.id])) {
      const defaultAttribute = assignedAttributes?.find((obj) => obj?.id === legend?.id);
      if (legend?.propertyType === TIMELINE_PROPERTY_TYPE) {
        if (defaultAttribute?.legendAttribute?.color || defaultAttribute?.legendAttribute?.multiColor) {
          if (timeLineLegends?.[legend?.id]) {
            timeLineLegends[legend?.id].assignedColor = defaultAttribute?.legendAttribute?.color
            timeLineLegends[legend?.id].multiColor = defaultAttribute?.legendAttribute?.multiColor
          }
        } else {
          if (legendAttributesCombination?.getTimeLineColor) {
            const color1 = legendAttributesCombination?.getTimeLineColor()
            const color2 = legendAttributesCombination?.getTimeLineColor()
            if (timeLineLegends?.[legend?.id]) {
              timeLineLegends[legend?.id].color = [color1, color2]
            }
          }
        }
        const keys = Object.keys(timeLineLegends[legend?.id] || {}) || []
        editablePropertiesTimeLine?.forEach((attribute: string) => {
          if (keys?.includes(attribute)) {
            timeLineLegends[legend?.id].defaultSettings[attribute] = timeLineLegends?.[legend?.id]?.[attribute]
          }
        })
      }
      if (legend?.propertyType === EXCEPTION_PROPERTY_TYPE) {
        if (defaultAttribute?.legendAttribute?.color) {
          if (exceptionLegends?.[legend?.id]) {
            exceptionLegends[legend?.id].color = [defaultAttribute?.legendAttribute?.color?.[0]]
          }
        } else {
          if (legendAttributesCombination?.getTimeLineColor) {
            const color = legendAttributesCombination?.getTimeLineColor()
            if (exceptionLegends?.[legend?.id]) {
              exceptionLegends[legend?.id].color = [color]
            }
          }
        }
        const keys = Object.keys(exceptionLegends[legend?.id] || {}) || []
        editablePropertiesTimeLine?.forEach((attribute: string) => {
          if (keys?.includes(attribute)) {
            exceptionLegends[legend?.id].defaultSettings[attribute] = exceptionLegends?.[legend?.id]?.[attribute]
          }
        })
      }
      if (legend?.propertyType === SUPPRESSED_PROPERTY_TYPE) {
        if (defaultAttribute?.legendAttribute?.color) {
          if (suppressionLegends?.[legend?.id]) {
            suppressionLegends[legend?.id].color = [defaultAttribute?.legendAttribute?.color?.[0]]
          }
        } else {
          if (legendAttributesCombination?.getTimeLineColor) {
            const color = legendAttributesCombination?.getTimeLineColor()
            if (suppressionLegends?.[legend?.id]) {
              suppressionLegends[legend?.id].color = [color]
            }
          }
        }
        const keys = Object.keys(suppressionLegends[legend?.id] || {}) || []
        editablePropertiesTimeLine?.forEach((attribute: string) => {
          if (keys?.includes(attribute)) {
            suppressionLegends[legend?.id].defaultSettings[attribute] = suppressionLegends?.[legend?.id]?.[attribute]
          }
        })
      }
    }
  })
}

export const assignAttributeStateToLegends = (legends: any = {}, oldLegends, assignedAttributes, legendAttributesCombination, isBuildingLevelEquipCharts = false) => {
  let legendArray = isArray(legends) ? legends : Object.values(legends) || []
  legendArray = sortBy(legendArray, "name")
  legendArray?.forEach((legend: any) => {
    if (!(oldLegends && oldLegends?.[legend?.id])) {
      const defaultAttribute = assignedAttributes?.find((obj) => obj?.id === legend?.id);
      let legendAttr: any = {
        color: generateRandomApprovedColor()
      }
      if (!defaultAttribute && legendAttributesCombination?.getLegendAttributeCombination && !legend?.xAxis) {
        legendAttr = legendAttributesCombination?.getLegendAttributeCombination()
      }
      legends[legend?.id] = {
        ...legends[legend?.id],
        color: legend?.xAxis ? null : (legend?.isAdded || (isBuildingLevelEquipCharts && !defaultAttribute) || !defaultAttribute ? legendAttr?.color : defaultAttribute?.legendAttribute?.color),
        shape: legend?.xAxis ? NONE : (legend?.isAdded || (isBuildingLevelEquipCharts && !defaultAttribute) || !defaultAttribute ? legendAttr?.shape : defaultAttribute?.legendAttribute?.shape) || DOT_SHAPE,
      }
      const keys = Object.keys(legends[legend?.id] || {}) || []
      editableProperties?.forEach((attribute: string) => {
        if (keys?.includes(attribute)) {
          legends[legend?.id].defaultSettings[attribute] = legends?.[legend?.id]?.[attribute]
        }
      })
    }
  })
}


export const generateRandomApprovedColor = () => {
  const randomIndex = Math.floor(Math.random() * APPROVED_COLOR_PALLETTE?.length)
  return APPROVED_COLOR_PALLETTE?.[randomIndex]
}
