import { createSlice } from "@reduxjs/toolkit"
import { isArray, isEmpty, xor } from "lodash"
import {
  CUSTOM_RANGE,
  END_DATE,
  FOUR_WEEKS,
  ONE_DAY,
  ONE_WEEK,
  START_DATE
} from "src/blitz/DateRange/constant"
import { checkInValidDate } from "src/blitz/DateRange/helper"
import {
  formatChartDate,
  getCurrentDate,
  getDateDifference,
  tranformChartData,
  updateAxisList
} from "src/components/shared/Charts/Helper/common"
import { MEAN_OF_ALL_ID } from "src/components/shared/Charts/Helper/ChartCategories/linewithControlRangeAndMean"
import {
  CHART_CATEGORY_LIST_TYPE,
  CHART_EQUIPMENT_PROPERTIES_LIST_ALL,
  CHART_EQUIPMENT_TYPE_LIST_TYPE,
  CHART_TIMELINE_LEGEND,
  CHART_TYPE,
  CURRENT_FETCH,
  DATE_SUBMIT_TYPE,
  FACILITY_CATEGORY,
  ITEM_SEPERATION,
  SELECTED_TYPE_EQUIPMENT,
  TIMEPERIOD_TYPE,
  possibleYAxis
} from "src/components/shared/Charts/constant"
import { isInfinityRange } from "src/blitz/Charts/helper"
import { possibleYAxesNames } from "src/components/shared/Charts/ChartContainer/ChartLegends/constants"
import { refereshYApplicableCharts } from "src/components/shared/Charts/ChartContainer/ChartLegends/constants"
import {
  LineWithControlRangeAndMean_CHART_CATEGORY,
  Line_CHART_CATEGORY,
  Scatter_CHART_CATEGORY,
  StackedBar_CHART_CATEGORY
} from "src/blitz/Charts/constants"
import * as d3 from "d3"
import { SUPPRESSED_PROPERTY_TYPE } from "src/components/shared/Charts/Helper/Suppressions/constants"
import { getDefaultTypeEquipment } from "src/components/shared/Charts/Helper/DropDowns/Type/type"
import {
  processParetoChartData,
  processPerformanceChartData
} from "../helper/performanceslice"
import { structureFromConfig } from "src/components/shared/Charts/Helper/Payload/Performance/generateChartPayload"
import { siUnitMapping } from "src/common/UOMConversions"
import { AssignColorsForDefaultProperties, LegendAttributesCombination, resetColorSetup } from "src/components/shared/Charts/Helper/colors"

const initialState = {
  excelExportLoading: false,
  exportComplete: false,
  chartsFilter: {},
  chartsProps: {},
  chartsData: {},
  chartsColors: {},
  chartsFilterPosition: [],
  summaryInfo: {
    data: null,
    error: false,
    loading: false
  },
  isHeadLess: false,
  brush: {},
  dataStore: {},
  dataKeys: {},
  syncChart: false,
  //The state is required to sync all brush position's with the selected chart's position
  syncChartBy: null,
  fitChart: false,
  showExportPrintChartModal: false,
  exportData: {},
  chartAxisConigList: {
    loading: false,
    data: null,
    error: false
  },
  configListDimensions: {
    loading: false,
    data: null,
    error: false
  },
  initialLoadVariable: true,
  dateChangeFromExternal: {},
  query: {},
  listAnalyticConfigs: {
    data: null,
    loading: false,
    error: false
  },
  [CHART_EQUIPMENT_PROPERTIES_LIST_ALL]: {}, // All equipment's all Properties by Chart Client ID
  isBuildingPerformance: false,
  chartSettings: {
    data: [],
    error: false,
    loading: false
  },
  bc: "black"
}

export const performanceSlice = createSlice({
  name: "performance",
  initialState: initialState,
  reducers: {
    setInitailLoadVariable: (state, action) => {
      state.initialLoadVariable = action.payload
    },
    setQueryVariable: (state, action) => {
      state.query = action.payload
    },
    setExcelExportLoading: (state, action) => {
      state.excelExportLoading = action.payload
    },
    setExportComplete: (state, action) => {
      state.exportComplete = action.payload
    },
    setInitialChartsFilter: (state, action) => {
      const {
        chartsFilter = {},
        chartsColors = {},
        chartsFilterPosition = [],
        chartsLegendsAttributes = {},
        syncChart = false,
        chartURLState
      } = action.payload

      return {
        ...state,
        chartsFilter: chartsFilter,
        chartsColors,
        chartsFilterPosition: chartsFilterPosition,
        chartsLegendsAttributes: chartsLegendsAttributes,
        syncChart,
        chartURLState
      }
    },
    setchartCategoryList: (state, action) => {
      const {
        type,
        data = [],
        chartType = null,
        chartClientId
      } = action.payload

      const updatedChartsFilter = { ...state.chartsFilter }
      const chartObj = updatedChartsFilter[chartClientId]

      if (chartObj) {
        switch (type) {
          case "Loading":
            chartObj[CHART_CATEGORY_LIST_TYPE] = {
              loading: true,
              data,
              error: false
            }
            break
          case "Success":
            chartObj[CHART_CATEGORY_LIST_TYPE] = {
              loading: false,
              data,
              error: false
            }
            break
          case "Failure":
            chartObj[CHART_CATEGORY_LIST_TYPE] = {
              loading: false,
              data,
              error: true
            }
            break
          default:
            break
        }
        if (Boolean(chartType)) {
          chartObj[CHART_TYPE] = chartType
        }
      }
      state.chartsFilter = updatedChartsFilter
    },
    setChartTypeEquipmentList: (state, action) => {
      const {
        type,
        data = [],
        plainEquipmentList = [],
        chartClientId,
        updated
      } = action.payload

      const updatedChartsFilter = { ...state.chartsFilter }
      const chartObj = updatedChartsFilter?.[chartClientId]

      if (chartObj) {
        switch (type) {
          case "Loading":
            chartObj[CHART_EQUIPMENT_TYPE_LIST_TYPE] = {
              loading: true,
              data,
              plainEquipmentList,
              error: false,
              updated
            }
            break
          case "Success":
            chartObj[CHART_EQUIPMENT_TYPE_LIST_TYPE] = {
              loading: false,
              data,
              plainEquipmentList,
              error: false,
              updated
            }
            break
          case "Failure":
            chartObj[CHART_EQUIPMENT_TYPE_LIST_TYPE] = {
              loading: false,
              data,
              plainEquipmentList,
              error: true,
              updated
            }
            break
          default:
            break
        }
      }
      state.chartsFilter = updatedChartsFilter
    },
    setchartsFilter: (state, action) => {
      const { updateObject, propName, chartClientId } = action.payload

      let updatedChartsFilter = { ...state.chartsFilter }
      const checkSyncDateAndTime =
        (propName === DATE_SUBMIT_TYPE || propName === TIMEPERIOD_TYPE) &&
        state.syncChart
      if (checkSyncDateAndTime) {
        updatedChartsFilter = Object.keys(updatedChartsFilter)?.reduce(
          (acc, chartId) => {
            acc[chartId] = { ...updatedChartsFilter[chartId], ...updateObject }
            return acc
          },
          {}
        )
      } else if (propName === CHART_EQUIPMENT_PROPERTIES_LIST_ALL) {
        const chartObj = updatedChartsFilter[chartClientId]
        if (chartObj) {
          updatedChartsFilter = {
            ...updatedChartsFilter,
            [chartClientId]: {
              ...chartObj,
              [CHART_EQUIPMENT_PROPERTIES_LIST_ALL]: {
                ...(chartObj?.[CHART_EQUIPMENT_PROPERTIES_LIST_ALL]),
                ...updateObject
              }
            }
          }
        }
      } else {
        const chartObj = updatedChartsFilter[chartClientId]
        if (chartObj) {
          updatedChartsFilter = {
            ...updatedChartsFilter,
            [chartClientId]: { ...chartObj, ...updateObject }
          }
        }
      }
      state.chartsFilter = updatedChartsFilter
    },
    setchartsDataParams: (state, action) => {
      const {
        type = "",
        chartProps = {},
        chartClientId,
        newFetch,
        isBuildingPerformance
      } = action.payload

      if (state.chartsFilter?.[chartClientId]?.[CURRENT_FETCH] === newFetch) {
        const chartFilter = state.chartsFilter?.[chartClientId] || {}

        const isPareto =
          isBuildingPerformance &&
          chartFilter?.[SELECTED_TYPE_EQUIPMENT]?.value &&
          chartFilter?.[SELECTED_TYPE_EQUIPMENT]?.value !== FACILITY_CATEGORY

        if (isPareto) {
          const listAnalyticConfigs = state.listAnalyticConfigs?.data || {}
          const processedData = processParetoChartData(
            type,
            chartProps,
            listAnalyticConfigs,
            state,
            chartClientId,
            state.isHeadLess,
            state.bc
          )
          state.chartsProps[chartClientId] =
            processedData?.transformedProps || {}
          state.chartsData[chartClientId] = processedData?.transformedData || {}
        } else {
          const payloadObject = structureFromConfig(
            chartFilter?.[CHART_TYPE]?.value,
            chartFilter?.[SELECTED_TYPE_EQUIPMENT],
            chartProps?.buildingId,
            chartFilter?.chillerApplicableProps,
            chartFilter?.equipmentProperties
          )
          const plainEquipmentList = chartFilter?.[CHART_EQUIPMENT_TYPE_LIST_TYPE]?.plainEquipmentList || [];
          const buildingId = state?.summaryInfo?.data?.buildingId
          const equipmentId = chartFilter?.[SELECTED_TYPE_EQUIPMENT]?.value?.id
          const legendAttributesCombination = state?.chartsColors[chartClientId]?.legendAttributesCombination
          const assignColorsForDefaultProperties = state?.chartsColors[chartClientId]?.assignColorsForDefaultProperties
          let assignedAttributes = state.chartsFilter?.[chartClientId]?.assignedAttributes || []
          if (assignColorsForDefaultProperties?.assignDefaultLegendColors && !assignColorsForDefaultProperties?.assigned) {
            assignedAttributes = assignColorsForDefaultProperties?.assignDefaultLegendColors(payloadObject, chartFilter?.[CHART_TYPE]?.value?.axisConfig, state?.chartSettings?.data, legendAttributesCombination, { buildingId, equipmentId, equipmentList: plainEquipmentList })
          }
          const processedData = processPerformanceChartData(
            state,
            chartClientId,
            chartProps,
            type,
            assignedAttributes,
            legendAttributesCombination
          )
          state.chartsData[chartClientId] = processedData?.transformedData || {}
          state.chartsFilter[chartClientId] = { ...chartFilter, initialChartStateUpdate: false, assignedAttributes }
          state.chartsProps[chartClientId] =
            processedData?.transformedProps || {}
          state.chartsData[chartClientId] = processedData?.transformedData || {}
        }
      }
    },
    setchartsDataParamsAdded: (state, action) => {
      const {
        type = "",
        chartProps = {},
        chartClientId,
        newFetch
      } = action.payload

      if (state.chartsFilter?.[chartClientId]?.[CURRENT_FETCH] === newFetch) {
        const added = JSON.parse(
          JSON.stringify(state.chartsFilter?.[chartClientId]?.added || {})
        )
        const legendAttributesCombination = state?.chartsColors[chartClientId]?.legendAttributesCombination
        const chartPropsNew = {
          ...chartProps,
          added: added,
          legendAttributesCombination
        }
        const transformedObj = tranformChartData(type, chartPropsNew)
        const transformedProps = transformedObj?.chartProps || {}
        const transformedValues = transformedObj?.chartData || {}
        const updatedChartProps: any = {
          ...(state.chartsProps?.[chartClientId] || {}),
          legends: {
            ...(state.chartsProps?.[chartClientId]?.legends || {}),
            ...(transformedProps?.legends || {})
          },
          axisList: transformedProps?.axisList || {}
        }
        const updatedChartData = {
          data: {
            ...(state.chartsData?.[chartClientId]?.data || {}),
            ...transformedValues?.data
          }
        }
        const transformedData = {
          chartData: updatedChartData,
          chartProps: updatedChartProps
        }
        let newAxisList = transformedProps?.axisList || []
        const oldAxisList = state.chartsProps?.[chartClientId]?.axisList || []
        newAxisList = updateAxisList(transformedData, newAxisList, type, oldAxisList, true)

        updatedChartProps.axisList = newAxisList || []

        state.chartsProps[chartClientId] = updatedChartProps || {}
        state.chartsData[chartClientId] = updatedChartData || {}
      }
    },
    setChartProps: (state, action) => {
      const { chartProp = {}, chartClientId } = action.payload

      state.chartsProps[chartClientId] = chartProp || {}
    },
    setchartsData: (state, action) => {
      state.chartsData = action.payload
    },
    setBrushModel: {
      reducer(state, action) {
        const { chartClientId, ...rest } = action.payload

        if (state.brush[chartClientId]) return

        state.brush = {
          ...state.brush,
          [chartClientId]: { ...rest, chartClientId }
        }
      },
      prepare(chartClientId): any {
        return {
          payload: {
            isSyncEnabled: false,
            isBrushMoved: false,
            startTime: null,
            endTime: null,
            canUseBrushData: false,
            chartClientId
          }
        }
      }
    },
    setDateChange: (state, action) => {
      state.dateChangeFromExternal = action.payload
    },
    setBrushState: {
      reducer(state, { payload }) {
        const { isSyncEnabled, chartClientId, ...rest } = payload

        let newState: any = { ...state.brush }

        const merge = (state, id, brushes, props) =>
          (state[id] = { ...brushes[id], ...props })

        // update by brush id
        if (!isSyncEnabled && chartClientId) {
          merge(newState, chartClientId, newState, {
            ...rest,
            isSyncEnabled
          })
        }

        // update all brushes
        if (isSyncEnabled) {
          newState = Object.keys(newState).reduce((av, clientId) => {
            merge(av, clientId, newState, {
              ...rest,
              isSyncEnabled
            })

            return av
          }, {})
        }

        state.brush = newState
      },
      prepare({ chartClientId, ...rest }): any {
        const defaults = {
          isSyncEnabled: false,
          isBrushMoved: false,
          startTime: null,
          endTime: null,
          canUseBrushData: false
        }

        return {
          payload: {
            ...defaults,
            ...rest,
            chartClientId
          }
        }
      }
    },
    setSyncChart: (state, action) => {
      const { checked, syncChartBy } = action.payload
      state.syncChart = checked
      state.syncChartBy = syncChartBy
    },
    setFitChart: (state, action) => {
      state.fitChart = action.payload
    },
    removeChartAction: (state, action) => {
      const chartClientId = action.payload

      const updatedChartsFilter = { ...state.chartsFilter }
      const updatedchartsProps = { ...state.chartsProps }
      const updatedChartsData = { ...state.chartsData }

      if (updatedChartsFilter[chartClientId]) {
        delete updatedChartsFilter[chartClientId]
      }
      if (updatedchartsProps[chartClientId]) {
        delete updatedchartsProps[chartClientId]
      }
      if (updatedChartsData[chartClientId]) {
        delete updatedChartsData[chartClientId]
      }
      return {
        ...state,
        chartsFilter: updatedChartsFilter,
        chartsProps: updatedchartsProps,
        chartsData: updatedChartsData
      }
    },
    addChartAction: (state, action) => {
      const { newChartClientId, chartClientId } = action.payload

      const newChart = {
        ...state.chartsFilter[chartClientId],
        isFirst: false,
        chartClientId: newChartClientId,
        initialLoad: false
      }
      const chartsColors = state?.chartsColors;
      const chartColor = chartsColors?.[chartClientId]

      const legendAttributesCombination = new LegendAttributesCombination(chartColor?.legendAttributesCombination);
      const assignColorsForDefaultProperties = new AssignColorsForDefaultProperties(chartColor?.assignColorsForDefaultProperties?.assigned)

      return {
        ...state,
        chartsFilter: {
          ...state.chartsFilter,
          [newChartClientId]: newChart
        },
        chartsProps: {
          ...state.chartsProps,
          [newChartClientId]: {
            ...state.chartsProps[chartClientId]
          }
        },
        chartsData: {
          ...state.chartsData,
          [newChartClientId]: {
            ...state.chartsData[chartClientId]
          }
        },
        chartsColors: {
          ...state.chartsColors,
          [newChartClientId]: {
            legendAttributesCombination,
            assignColorsForDefaultProperties
          }
        }
      }
    },
    clearChartData: (state) => {
      return {
        ...initialState,
        query: state.query
      }
    },
    setShowExportPrintChartModal: (state, action) => {
      state.showExportPrintChartModal = action.payload
    },
    setExportData: (state, action) => {
      state.exportData = action.payload
    },
    setChartAxisConigList: (state, action) => {
      const { type, data = null } = action.payload
      switch (type) {
        case "Loading":
          state.chartAxisConigList = {
            loading: true,
            data,
            error: false
          }
          break
        case "Success":
          state.chartAxisConigList = {
            loading: false,
            data,
            error: false
          }
          break
        case "Failure":
          state.chartAxisConigList = {
            loading: false,
            data,
            error: true
          }
          break
        default:
          break
      }
    },
    setConfigListDimensions: (state, action) => {
      const { type, data = null } = action.payload
      switch (type) {
        case "Loading":
          state.configListDimensions = {
            loading: true,
            data,
            error: false
          }
          break
        case "Success":
          state.configListDimensions = {
            loading: false,
            data,
            error: false
          }
          break
        case "Failure":
          state.configListDimensions = {
            loading: false,
            data,
            error: true
          }
          break
        default:
          break
      }
    },
    clearChartsDataParams: (state, action) => {
      const { data, params, axisList, chartAdditionalInfo, chartClientId } =
        action.payload

      const updatedchartsProps = { ...state.chartsProps }
      const updatedChartsData = { ...state.chartsData }
      const updatedChartsFilter = { ...state.chartsFilter }

      if (updatedchartsProps[chartClientId]) {
        updatedchartsProps[chartClientId] = {
          params,
          axisList,
          chartAdditionalInfo
        }
      }

      if (updatedchartsProps[chartClientId]) {
        updatedChartsData[chartClientId] = { data }
      }

      if (updatedChartsFilter[chartClientId]) {
        updatedChartsFilter[chartClientId] = {
          ...updatedChartsFilter[chartClientId],
          added: null
        }
      }

      state.chartsProps = updatedchartsProps
      state.chartsData = updatedChartsData
      state.chartsFilter = updatedChartsFilter
    },
    setData: {
      reducer(state, action) {
        const { dataKey, data } = action.payload

        if (!dataKey) return

        state.dataStore = {
          ...state.dataStore,
          [dataKey]: data
        }

        state.dataKeys = {
          ...state.dataKeys,
          [dataKey]: true
        }
      },
      prepare({ config = {}, dataKey, data }): any {
        // if no data key, don't perform any action.
        if (!dataKey) {
          return {
            payload: {}
          }
        }

        const {
          applyTransformOnData = false,
          chartType = null,
          chartProps = {}
        } = config

        let transformedData = data

        if (applyTransformOnData && chartType) {
          transformedData = tranformChartData(chartType, {
            ...chartProps,
            inputData: data
          })
        }

        return {
          payload: {
            data: transformedData,
            dataKey
          }
        }
      }
    },
    setSummaryInfo: (state, action) => {
      const { type, data = {} } = action.payload
      switch (type) {
        case "Loading":
          state.summaryInfo = {
            loading: true,
            data: null,
            error: false
          }
          break
        case "Success":
          state.summaryInfo = {
            loading: false,
            data,
            error: false
          }
          break
        case "Failure":
          state.summaryInfo = {
            loading: false,
            data: null,
            error: true
          }
          break
      }
    },
    setIsHeadLess: (state, action) => {
      state.isHeadLess = action.payload
    },
    setBCChart: (state, action) => {
      state.bc = action.payload
    },
    setURLToFilter: (state, action) => {
      const {
        equipmentIds = [],
        chartIds = [],
        startDates = [],
        endDates = [],
        clientIds = [],
        isBuildingPerformance = false
      } = action.payload

      const isAdded = clientIds?.length > state.chartsFilterPosition?.length

      let updatedChartsFilter = { ...state.chartsFilter }
      const updatedChartsData = { ...state.chartsData }
      const updatedchartsProps = { ...state.chartsProps }

      updatedChartsFilter = Object.keys(updatedChartsFilter)?.reduce(
        (acc, clientId) => {
          const chartIndex = clientIds?.findIndex((id) => id === clientId)
          if (chartIndex >= 0) {
            const startDate = startDates[chartIndex]
            const endDate = endDates[chartIndex]
            const equipmentId = equipmentIds[chartIndex]
            const chartId = chartIds[chartIndex]

            let newType = false;
            if (!isBuildingPerformance) {
              newType = equipmentId !== state?.chartsFilter?.[clientId]?.[SELECTED_TYPE_EQUIPMENT]?.value?.id
            }
            const newChartId = chartId !== state?.chartsFilter?.[clientId]?.chartId
            const chartColor = state?.chartsColors?.[clientId]
            if (newType || newChartId) {
              resetColorSetup(chartColor, [false, false, true])
            }
            const queryStartDate = (startDate || "")?.replace(/-/g, "/")
            const queryEndDate = (endDate || "")?.replace(/-/g, "/")
            const defaultDates =
              checkInValidDate(queryStartDate) ||
              checkInValidDate(queryEndDate) ||
              new Date(queryStartDate) > new Date(queryEndDate) ||
              new Date(queryStartDate) > new Date() ||
              new Date(queryEndDate) > new Date()
            const correctedStartDate = defaultDates
              ? getCurrentDate()
              : formatChartDate(queryStartDate)
            const correctedEndDate = defaultDates
              ? getCurrentDate()
              : formatChartDate(queryEndDate)
            const dateDiff = getDateDifference(
              correctedEndDate,
              correctedStartDate
            )
            const timePeriod =
              defaultDates || dateDiff === 0
                ? ONE_DAY
                : dateDiff === ONE_WEEK - 1
                  ? ONE_WEEK
                  : dateDiff === FOUR_WEEKS - 1
                    ? FOUR_WEEKS
                    : CUSTOM_RANGE
            let defaultSelectedEquipment = {}

            if (!isBuildingPerformance) {
              const equipmentDropDownOptions =
                updatedChartsFilter[clientId]?.chartTypeEquipmentList?.data
              const data =
                updatedChartsFilter[clientId]?.chartTypeEquipmentList
                  ?.plainEquipmentList
              defaultSelectedEquipment = getDefaultTypeEquipment(
                false,
                equipmentDropDownOptions,
                equipmentId,
                data,
                chartId,
                state?.chartAxisConigList?.data
              )
            } else {
              defaultSelectedEquipment =
                updatedChartsFilter[clientId]?.[SELECTED_TYPE_EQUIPMENT]
            }

            const defaultSelectedChart = state.chartAxisConigList?.data?.find(
              (obj) => obj?.value?.id === chartId
            )

            const updateObject = {
              [START_DATE]: correctedStartDate,
              [END_DATE]: correctedEndDate,
              chartId: chartId,
              selectedEquipmentId: equipmentId,
              [SELECTED_TYPE_EQUIPMENT]: defaultSelectedEquipment,
              [CHART_TYPE]: defaultSelectedChart
            }
            if (
              !(
                updatedChartsFilter[clientId]?.[TIMEPERIOD_TYPE] ===
                CUSTOM_RANGE
              )
            ) {
              updateObject[TIMEPERIOD_TYPE] = timePeriod
            }
            acc[clientId] = {
              ...updatedChartsFilter[clientId],
              ...updateObject
            }

            const allParamsSelected =
              Boolean(startDate) &&
              Boolean(endDate) &&
              Boolean(chartId) &&
              (isBuildingPerformance ? true : Boolean(equipmentId))
            if (!allParamsSelected) {
              delete updatedChartsData[clientId]
              delete updatedchartsProps[clientId]
            }
          } else {
            delete updatedChartsData[clientId]
            delete updatedchartsProps[clientId]
          }
          return acc
        },
        {}
      )

      if (isAdded) {
        const newClientIds = xor(clientIds, state.chartsFilterPosition)
        newClientIds?.forEach((clientId) => {
          const chartIndex = clientIds?.findIndex((id) => id === clientId)
          const startDate = startDates[chartIndex]
          const endDate = endDates[chartIndex]
          const equipmentId = equipmentIds[chartIndex]
          const chartId = chartIds[chartIndex]

          const queryStartDate = (startDate || "")?.replace(/-/g, "/")
          const queryEndDate = (endDate || "")?.replace(/-/g, "/")
          const defaultDates =
            checkInValidDate(queryStartDate) ||
            checkInValidDate(queryEndDate) ||
            new Date(queryStartDate) > new Date(queryEndDate) ||
            new Date(queryStartDate) > new Date() ||
            new Date(queryEndDate) > new Date()
          const correctedStartDate = defaultDates
            ? getCurrentDate()
            : formatChartDate(queryStartDate)
          const correctedEndDate = defaultDates
            ? getCurrentDate()
            : formatChartDate(queryEndDate)
          const dateDiff = getDateDifference(endDate, startDate)
          const timePeriod =
            defaultDates || dateDiff === 0
              ? ONE_DAY
              : dateDiff === ONE_WEEK - 1
                ? ONE_WEEK
                : dateDiff === FOUR_WEEKS - 1
                  ? FOUR_WEEKS
                  : CUSTOM_RANGE
          let defaultSelectedEquipment = {}

          if (!isBuildingPerformance) {
            const equipmentDropDownOptions =
              updatedChartsFilter[clientId]?.chartTypeEquipmentList?.data
            const data =
              updatedChartsFilter[clientId]?.chartTypeEquipmentList
                ?.plainEquipmentList
            defaultSelectedEquipment = getDefaultTypeEquipment(
              false,
              equipmentDropDownOptions,
              equipmentId,
              data,
              chartId,
              state?.chartAxisConigList?.data
            )
          } else {
            defaultSelectedEquipment =
              updatedChartsFilter[clientId]?.[SELECTED_TYPE_EQUIPMENT]
          }

          const defaultSelectedChart = state.chartAxisConigList?.data?.find(
            (obj) => obj?.value?.id === chartId
          )

          const updateObject = {
            [START_DATE]: correctedStartDate,
            [END_DATE]: correctedEndDate,
            [TIMEPERIOD_TYPE]: timePeriod,
            chartId: chartId,
            selectedEquipmentId: equipmentId,
            [SELECTED_TYPE_EQUIPMENT]: defaultSelectedEquipment,
            [CHART_TYPE]: defaultSelectedChart,
            initialLoad: true
          }

          updatedChartsFilter[clientId] = updateObject
        })
      }

      return {
        ...state,
        chartsFilterPosition: clientIds,
        chartsFilter: updatedChartsFilter,
        chartsData: updatedChartsData,
        chartsProps: updatedchartsProps
      }
    },
    setChartsFilterPosition: (state, action) => {
      state.chartsFilterPosition = action.payload
    },
    setLegendSwitchState: (state, action) => {
      const chartClientId = action.payload?.chartClientId
      const legend = action.payload?.legend
      const legendType = action.payload?.type
      const chartType =
        state?.chartsFilter?.[chartClientId]?.chartType?.value?.type
      const legendsArray = isArray(
        state?.chartsProps?.[chartClientId]?.[legendType]
      )
      if (legendsArray) {
        const newArray = state.chartsProps[chartClientId]?.[legendType]?.map(
          (obj) => {
            if (obj?.id === legend?.id) {
              return {
                ...obj,
                show: !legend?.show
              }
            } else {
              return obj
            }
          }
        )
        state.chartsProps[chartClientId][legendType] = newArray
      } else {
        if (
          !isEmpty(
            state?.chartsProps?.[chartClientId]?.[legendType][legend?.id]
          )
        ) {
          if (legendType === CHART_TIMELINE_LEGEND) {
            if (state.chartsProps[chartClientId].timeToggleIndicator && !state.chartsProps[chartClientId][legendType][legend?.id].show) {
              state.chartsProps[chartClientId].timeToggleIndicator = false;
            }
            state.chartsProps[chartClientId][legendType][legend?.id].batch =
              !legend?.show
            state.chartsProps[chartClientId][legendType][legend?.id].show =
              !legend?.show
          } else {
            state.chartsProps[chartClientId][legendType][legend?.id].show =
              !legend?.show
          }
        }
      }
      if (
        legendType === "legends" &&
        refereshYApplicableCharts?.includes(chartType)
      ) {
        const legends = state?.chartsProps?.[chartClientId]?.[legendType]
        const currentAxisList =
          state?.chartsProps?.[chartClientId]?.axisList || []
        const indexToUpdateAxis = currentAxisList?.findIndex(
          (axis) => axis?.axisName === legend?.axisName
        )
        let remainingLegendKeys = []
        if (typeof legends === "object") {
          remainingLegendKeys = Object?.keys(legends)?.filter(
            (key) =>
              legends[key]?.axisName === legend?.axisName && legends[key]?.show
          )
        } else if (legendsArray) {
          remainingLegendKeys = legends?.filter(
            (item) => item?.axisName === legend?.axisName && item?.show
          )
        }
        if (
          remainingLegendKeys?.length > 0 &&
          !currentAxisList[indexToUpdateAxis]?.isRangeEdited &&
          !(currentAxisList[indexToUpdateAxis]?.displayName === "Percent")
        ) {
          const currentChartData = state?.chartsData?.[chartClientId] || {}

          const estimatedRange = [Infinity, -Infinity]
          remainingLegendKeys?.forEach((legendkey) => {
            if (currentChartData?.data[legendkey]?.data?.length > 0) {
              const minLegendValue = Math.min(
                ...currentChartData?.data[legendkey]?.data?.filter(
                  (item) => item !== null && typeof item === "number"
                )
              )
              const maxLegendValue = Math.max(
                ...currentChartData?.data[legendkey]?.data?.filter(
                  (item) => item !== null && typeof item === "number"
                )
              )
              estimatedRange[0] =
                estimatedRange[0] > minLegendValue
                  ? minLegendValue
                  : estimatedRange[0]
              estimatedRange[1] =
                estimatedRange[1] < maxLegendValue
                  ? maxLegendValue
                  : estimatedRange[1]
            }
          })
          if (!isInfinityRange(estimatedRange)) {
            currentAxisList[indexToUpdateAxis] = {
              ...currentAxisList[indexToUpdateAxis],
              range: estimatedRange
            }
            state.chartsProps[chartClientId].axisList = currentAxisList
          }
        }
      }
    },
    setStackedBarSort: (state, action) => {
      const chartClientId = action.payload?.chartClientId
      const selectedVal = action.payload?.selectedVal
      if (!isEmpty(state?.chartsProps?.[chartClientId]?.behaviour?.sortBehaviour)) {
        if (!state?.chartsProps?.[chartClientId]?.behaviour) {
          state.chartsProps[chartClientId].behaviour = {}
        }
        state.chartsProps[chartClientId].behaviour.sortBehaviour = selectedVal
      }
    },
    setHideToggleSwitch: (state, action) => {
      const chartClientId = action.payload?.chartClientId
      const checked = action.payload?.checked
      if (!isEmpty(state?.chartsProps?.[chartClientId])) {
        state.chartsProps[chartClientId]["toggleSwitch"] = checked
        if (!isEmpty(state?.chartsProps?.[chartClientId]?.legends)) {
          const newObject = {}
          Object.keys(state.chartsProps[chartClientId]?.legends)?.map((key) => {
            const obj = state.chartsProps[chartClientId]?.legends[key]
            if (obj?.id === MEAN_OF_ALL_ID) {
              const show = !obj?.show
              newObject[key] = {
                ...obj,
                show: show ? checked : true
              }
            } else {
              newObject[key] = {
                ...obj,
                show: checked
              }
            }
          })
          state.chartsProps[chartClientId].legends = {
            ...newObject
          }
        }
      }
    },
    setHideTimelineToggleSwitch: (state, action) => {
      const chartClientId = action.payload?.chartClientId
      const { timeToggleIndicator } = action.payload?.chartProp

      if (!isEmpty(state?.chartsProps?.[chartClientId])) {
        if (!isEmpty(state?.chartsProps?.[chartClientId]?.timeLineLegends)) {
          const newObject = {}
          Object.keys(state.chartsProps[chartClientId]?.timeLineLegends)?.map(
            (key) => {
              const obj =
                state.chartsProps[chartClientId]?.timeLineLegends?.[key]
              const show = timeToggleIndicator ? obj?.batch ? !obj?.show : obj?.show : false
              newObject[key] = {
                ...obj,
                show: show
              }

              // if (show) {
              //   timeToggleIndicator = !show
              // }
            }
          )

          state.chartsProps[chartClientId].timeToggleIndicator =
            !timeToggleIndicator
          state.chartsProps[chartClientId].timeLineLegends = {
            ...newObject
          }
        }
      }
    },
    setSuppressionTimelineToggleSwitch: (state, action) => {
      const chartClientId = action.payload?.chartClientId
      if (!isEmpty(state?.chartsProps?.[chartClientId])) {
        if (!isEmpty(state?.chartsProps?.[chartClientId]?.timeLineLegends)) {
          const newObject = {}
          Object.keys(state.chartsProps[chartClientId]?.timeLineLegends)?.map(
            (key) => {
              const obj =
                state.chartsProps[chartClientId]?.timeLineLegends?.[key]
              if (obj?.propertyType === SUPPRESSED_PROPERTY_TYPE) {
                newObject[key] = {
                  ...obj,
                  visible: !obj?.visible
                }
              } else {
                newObject[key] = {
                  ...obj
                }
              }
            }
          )
          state.chartsFilter[chartClientId].suppressionToggleIndicator =
            !state.chartsFilter[chartClientId].suppressionToggleIndicator
          state.chartsProps[chartClientId].timeLineLegends = {
            ...newObject
          }
        }
      }
    },
    setAddedProperties: (state, action) => {
      const {
        chartClientId,
        equipmentType,
        selectedEquipments,
        selectedProperties,
        configCombination
      } = action.payload

      const dimensions = state.configListDimensions?.data || []
      let updatedChartsFilter = { ...state.chartsFilter }
      const chartObj = updatedChartsFilter?.[chartClientId] || {}

      const addedAxisList = chartObj?.added?.axisList || []

      const configAxisList = chartObj?.[CHART_TYPE]?.value?.axisConfig || []
      const configYaxes = configAxisList
        ?.filter((obj) => obj?.axisType?.toLowerCase() === "y")
        ?.map((obj) => obj?.axisName?.toLowerCase())

      if (isArray(selectedProperties)) {
        selectedProperties?.forEach((propObj) => {
          const pid = propObj?.targetKey
          const dimensionDetails = dimensions?.find(
            (obj) => obj?.value?.UoM === propObj?.targetMeasureUnit
          )
          const addedAxis = addedAxisList?.find(
            (obj) => obj?.UoM === propObj?.targetMeasureUnit
          )

          const addedYaxes = addedAxisList
            ?.filter((obj) => obj?.axisType?.toLowerCase() === "y")
            ?.map((obj) => obj?.axisName?.toLowerCase())

          if (addedAxis) {
            if (!addedAxis?.added) {
              addedAxis.added = {
                [equipmentType]: []
              }
            } else {
              if (!addedAxis?.added?.[equipmentType]) {
                addedAxis.added = {
                  ...addedAxis?.added,
                  [equipmentType]: []
                }
              }
            }

            if (isArray(selectedEquipments)) {
              selectedEquipments?.forEach((equipObj) => {
                const eid = equipObj?.id
                const item = `${eid}${ITEM_SEPERATION}${pid}`
                const availableInConfig = (configCombination?.[equipmentType] || [])?.includes(item)
                if (!availableInConfig) {
                  addedAxis.added[equipmentType].push(item)
                }
              })
            }
          } else {
            if (!possibleYAxesNames?.every((axisName) => addedYaxes?.includes(axisName))) {
              const configAxis = configAxisList?.find(
                (obj) => obj?.UoM === propObj?.targetMeasureUnit
              )
              const axis: any = {
                symbol: dimensionDetails?.value?.Symbol || "",
                displayName: dimensionDetails?.value?.Name,
                axisType: "y",
                added: {
                  [equipmentType]: []
                },
                UoM: dimensionDetails?.value?.UoM,
                symbolList: {
                  IP: dimensionDetails?.value?.Symbol,
                  SI: dimensions?.find(
                    (obj) => obj?.value?.UoM === siUnitMapping.get(propObj?.targetMeasureType)
                  )?.value?.Symbol
                }
              }
              if (configAxis) {
                axis.axisName = configAxis?.axisName;
              } else {
                const axisName =
                  possibleYAxesNames?.filter(
                    (i) => !configYaxes?.includes(i) && !addedYaxes?.includes(i)
                  )?.[0] || ""
                axis.axisName = axisName
                axis.isAdded = true
              }
              if (isArray(selectedEquipments)) {
                selectedEquipments?.forEach((equipObj) => {
                  const eid = equipObj?.id
                  const item = `${eid}${ITEM_SEPERATION}${pid}`
                  const availableInConfig = (configCombination?.[equipmentType] || [])?.includes(item)
                  if (!availableInConfig) {
                    axis.added[equipmentType].push(item)
                  }
                })
              }
              addedAxisList.push(axis)
            }
          }
        })
      }

      const addedObject = {
        added: {
          axisList: addedAxisList
        }
      }
      if (chartObj) {
        updatedChartsFilter = {
          ...updatedChartsFilter,
          [chartClientId]: { ...chartObj, ...addedObject }
        }
      }
      state.chartsFilter = updatedChartsFilter
    },
    handleRemoveAllAddedProperties: (state, action) => {
      const { chartClientId } = action.payload || {}
      let updatedChartsFilter = { ...state.chartsFilter }
      let updatedChartsProps = { ...state.chartsProps }
      let updatedChartsData = { ...state.chartsData }

      const chartFilterObj = updatedChartsFilter?.[chartClientId]
      const chartPropsObj = updatedChartsProps?.[chartClientId]
      const chartDataObj = updatedChartsData?.[chartClientId]

      const type = chartFilterObj?.chartType?.value?.type
      const newLegends = {}
      const newData = {}
      Object.keys(chartPropsObj?.legends)?.forEach((id) => {
        const legendObj = chartPropsObj?.legends?.[id]
        const dataObj = chartDataObj?.data?.[id] || []
        if (!legendObj?.isAdded) {
          newLegends[id] = legendObj
          newData[id] = dataObj
        }
      })

      const updateDataObject = {
        data: newData
      }

      const axisList = chartPropsObj?.axisList || []
      let newAxisList = axisList?.filter((obj) => !obj?.isAdded) || []

      if (type === Line_CHART_CATEGORY || type === Scatter_CHART_CATEGORY) {
        const data = updateDataObject?.data || {}
        const legends = newLegends || {}
        newAxisList = newAxisList?.map((obj) => {
          let calMin = Infinity
          let calMax = -Infinity
          Object.keys(legends)?.forEach((id) => {
            const legendObj = legends?.[id]
            if (legendObj?.axisName === obj?.axisName) {
              const lData = data?.[legendObj?.id]?.data || []
              const calculatedRange = d3.extent(lData) || []
              const min = calculatedRange?.[0]
              const max = calculatedRange?.[1]
              if (min < calMin) {
                calMin = min
              }
              if (max > calMax) {
                calMax = max
              }
            }
          })
          const range = [calMin, calMax]
          return {
            ...obj,
            defaultRange: range,
            range: range
          }
        })
      }
      if (
        type === LineWithControlRangeAndMean_CHART_CATEGORY ||
        type === StackedBar_CHART_CATEGORY
      ) {
        newAxisList = newAxisList?.map((obj) => {
          const isY = possibleYAxis?.includes(obj?.axisName)
          if (isY) {
            const range = [0, 100]
            return {
              ...obj,
              range: range,
              defaultRange: range
            }
          } else {
            return obj
          }
        })
      }
      const updateFilterObject = {
        added: null
      }
      const updatePropsObject = {
        legends: newLegends,
        axisList: newAxisList
      }

      if (chartFilterObj) {
        updatedChartsFilter = {
          ...updatedChartsFilter,
          [chartClientId]: { ...chartFilterObj, ...updateFilterObject }
        }
      }
      if (chartPropsObj) {
        updatedChartsProps = {
          ...updatedChartsProps,
          [chartClientId]: { ...chartPropsObj, ...updatePropsObject }
        }
      }
      if (chartDataObj) {
        updatedChartsData = {
          ...updatedChartsData,
          [chartClientId]: { ...chartDataObj, ...updateDataObject }
        }
      }

      return {
        ...state,
        chartsFilter: updatedChartsFilter,
        chartsProps: updatedChartsProps,
        chartsData: updatedChartsData
      }
    },
    setInitialChartsProps: (state, action) => {
      return {
        ...state,
        chartsProps: action.payload
      }
    },
    setAnalyticConfigs: (state, action) => {
      const { type, data = {} } = action.payload
      switch (type) {
        case "Loading":
          state.listAnalyticConfigs = {
            ...state.listAnalyticConfigs,
            loading: true,
            error: undefined,
          }
          break
        case "Success":
          state.listAnalyticConfigs = {
            loading: false,
            data,
            error: false
          }
          break
        case "Failure":
          state.listAnalyticConfigs = {
            ...state.listAnalyticConfigs,
            loading: false,
            error: true
          }
          break
      }
    },
    setIsBuildingPerformance: (state, action) => {
      state.isBuildingPerformance = action.payload
    },
    setChartSettings: (state, action) => {
      const { type = "Sucesss", data = [] } = action.payload
      switch (type) {
        case "Loading":
          state.chartSettings = {
            loading: true,
            data: [],
            error: undefined
          }
          break
        case "Success":
          state.chartSettings = {
            loading: false,
            data,
            error: false
          }
          break
        case "Failure":
          state.chartSettings = {
            loading: false,
            data,
            error: true
          }
          break
      }
    },
  }
})

export const {
  setchartCategoryList,
  setChartTypeEquipmentList,
  setchartsFilter,
  setchartsData,
  setBrushModel,
  setBrushState,
  setSyncChart,
  setFitChart,
  removeChartAction,
  addChartAction,
  setchartsDataParams,
  clearChartData,
  setShowExportPrintChartModal,
  setExportData,
  setChartAxisConigList,
  setConfigListDimensions,
  clearChartsDataParams,
  setSummaryInfo,
  setIsHeadLess,
  setInitialChartsFilter,
  setURLToFilter,
  setInitailLoadVariable,
  setData,
  setQueryVariable,
  setDateChange,
  setChartsFilterPosition,
  setExcelExportLoading,
  setExportComplete,
  setLegendSwitchState,
  setStackedBarSort,
  setHideToggleSwitch,
  setHideTimelineToggleSwitch,
  setSuppressionTimelineToggleSwitch,
  setChartProps,
  setAddedProperties,
  setchartsDataParamsAdded,
  handleRemoveAllAddedProperties,
  setInitialChartsProps,
  setAnalyticConfigs,
  setIsBuildingPerformance,
  setBCChart,
  setChartSettings
} = performanceSlice.actions

export const selectChartAxisConigList = (state: any) =>
  state.performance.chartAxisConigList
export const selectconfigListDimension = (state: any) =>
  state.performance.configListDimensions
export const selectchartsFilter = (state: any) => state.performance.chartsFilter
export const selectchartsProps = (state: any) => state.performance.chartsProps
export const selectchartsData = (state: any) => state.performance.chartsData
export const selectSyncChart = (state: any) => state.performance.syncChart
export const selectSyncChartBy = (state: any) => state.performance.syncChartBy
export const selectSummaryInfo = (state: any) => state.performance.summaryInfo
export const selectIsHeadLess = (state: any) => state.performance.isHeadLess
export const selectChartsFilterPosition = (state: any) =>
  state.performance.chartsFilterPosition
export const selectInitialLoadVariable = (state: any) =>
  state.performance.initialLoadVariable
export const selectDateChangeFromExternal = (state: any) =>
  state.performance.dateChangeFromExternal

// Expose brush state
export const selectBrush = (state: any, chartClientId?: any) =>
  state.performance.brush[chartClientId] || {}

export const selectData = (state: any) => state.performance.dataStore
export const selectDataKeys = (state: any) => state.performance.dataKeys

export const selectFitChart = (state: any) => state.performance.fitChart
export const selectAllChartsData = (state: any) => state.performance
export const getShowExportPrintChartModal = (state: any) =>
  state.performance.showExportPrintChartModal
export const getData = (state: any) => state.performance.exportData
export const getQuery = (state: any) => state.performance.query
export const selectListAnalyticConfigs = (state: any) =>
  state.performance.listAnalyticConfigs
export const selectIsBuildingPerformance = (state: any) =>
  state.performance.isBuildingPerformance
export const selectCurrentChartEquipmentList =
  (chartClientId: any) => (state: any) =>
    state.performance?.chartsFilter?.[chartClientId]?.[
    CHART_EQUIPMENT_TYPE_LIST_TYPE
    ]
export const selectBCChart = (state: any) => state?.bc
export const selectChartState = (state: any) => state?.performance?.chartURLState
export const selectChartSettings = (state: any) =>
  state.performance.chartSettings
export const selectChartsColors = (state: any) => state.performance.chartsColors
export const selectExcelExportLoading = (state: any) => state?.excelExportLoading
export const selectExportComplete = (state: any) => state?.exportComplete