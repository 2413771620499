import {
  useImperativeHandle,
  forwardRef,
  useEffect,
  useState,
  useMemo
} from "react"
import { SearchRegex, isJsonString } from "src/utils/CommonMethods"
import momentTimeZone from "moment-timezone"
import { useSelector, useDispatch } from "react-redux"
import { t } from "src/translations/help"
import { API } from "aws-amplify"
import { getDateFormatWithEnglishLocale } from "src/utils/CommonMethods"
import { isArray, isEmpty, uniqBy, isString, uniq } from "lodash"
import {
  selectBuildingSummary,
  selectBuildingId
} from "src/redux/slicers/buildingSummarySlice"
import { useMutation } from "src/hooks/APIHooks"
import { getPropsByEquipmentType, generateRawDataReport, generateEHRDataReport } from "./../graphql"
import { ReportList } from "./ReportList"
import {
  getFileFormatDetails,
  getSelectedEquipments,
  getSelectedProperties,
  getEquipmentLoader,
  getPropertiesLoader,
  setEquipmentSearchText,
  getEquipments,
  getProperties,
  setPropertySearchText,
  setReportCheck,
  setIsValid,
  setReportFormat,
  setSelectedEquipments,
  setSelectedProperties,
  getFilter,
  setRawDataProperties,
  setIsDataExceeded,
  setReportStatus,
  getReportStatus
} from "src/redux/slicers/reportSlice"
import { sortByAlphanumericSpecialChars } from "src/blitz/DataGridTable/helper"
import { getMinutesDifference } from "src/blitz/DateRange/helper"
import {
  DATA_INTERVAL_MINUTES,
  MAX_DATA_CELLS_QUANTITY,
  CHARACTERISTIC,
  REPORT_STATE,
  IsMultipleSingleFormat,
  CHILLER
} from "../constants"
import styles from "./RawDataReport.module.scss"
import clsx from "clsx"
import { EmptyReport } from "./../EmptyReport"
import { ACTIONS } from "src/constants"
import { downloadFileUsingS3 } from "src/utils/CommonMethods"
import { selectLanguage } from "src/redux/slicers/homeSlice"
import { selectEquipmentSummary } from "src/redux/slicers/equipmentSummarySlice"
import {
  selectchartsFilter,
  selectconfigListDimension,
  selectAllChartsData
} from "src/redux/slicers/performanceSlice"
import { possibleYAxesNames } from "../../Charts/ChartContainer/ChartLegends/constants"
import { ASC, ITEM_SEPERATION, NAME } from "../../Charts/constant"
import { structureFromConfig } from "../../Charts/Helper/Payload/Performance/generateChartPayload"
import { RECHECK_INTERVAL, RETRY_COUNT } from "src/constants/common"


const RawDataReport = forwardRef((props: any, ref: any) => {
  const { selectedEquipmentType, selectedLanguage, selectedMetric } = props
  useImperativeHandle(ref, () => ({
    onClickGenerate: generateReportHandler
  }))

  const dispatch = useDispatch()
  const [selectAllEquip, setSelectAllEquip] = useState(false)
  const [selectAllProps, setSelectAllProps] = useState(false)
  const buildingId = useSelector(selectBuildingId)
  const { value: langDir } = useSelector(selectLanguage)
  const { data: selectedEquipment } = useSelector(selectEquipmentSummary)
  const { data: buildingSummary } = useSelector(selectBuildingSummary)

  const equipLoading = useSelector(getEquipmentLoader)
  const propsLoading = useSelector(getPropertiesLoader)
  const equipments = useSelector(getEquipments)
  const properties = useSelector(getProperties)
  const selectedEquipments: any = useSelector(getSelectedEquipments)
  const selectedProperties: any = useSelector(getSelectedProperties)
  const [reportInfo, setReportInfo] = useState(REPORT_STATE)
  const { cancelled, error } = useSelector(getReportStatus)
  const { startDate, endDate, language, systemOfMeasure } = useSelector(getFilter)
  const {
    equipmentSearchText,
    propertySearchText,
    includeWeatherData,
    includeOutofRange,
    includeNonStandard,
    viewFormat
  } = useSelector(getFileFormatDetails)

  const configListDimensions = useSelector(selectconfigListDimension)
  const chartsFilter = useSelector(selectchartsFilter)
  const { chartsProps } = useSelector(selectAllChartsData)
  const [activeProperties, setActiveProperties] = useState(null)
  const [selectedAxes, setSelectedAxes] = useState([])
  const [chartID, setChartID] = useState("")

  const isValid = useMemo(
    () => selectedProperties?.length > 0 && selectedEquipments?.length > 0,
    [selectedEquipments, selectedProperties]
  )

  useEffect(() => {
    dispatch(setIsValid(isValid))
  }, [isValid])

  const isDataExceeded = useMemo(() => {
    const weatherPros = includeWeatherData ? 4 : 0
    const totalProperties = selectedProperties.length + weatherPros
    return (
      MAX_DATA_CELLS_QUANTITY <
      selectedEquipments.length *
      totalProperties *
      (getMinutesDifference(startDate, endDate) / DATA_INTERVAL_MINUTES)
    )
  }, [
    selectedEquipments,
    selectedProperties,
    startDate,
    endDate,
    includeWeatherData
  ])

  useEffect(() => {
    dispatch(setIsDataExceeded(isDataExceeded))
  }, [isDataExceeded])

  const { onSubmit: generateReportQuery } = useMutation({
    query: generateEHRDataReport,
    onSuccess: (response: any) => {
      if (response?.data?.generateEHRDataReport) {
        const { body } = JSON.parse(response?.data?.generateEHRDataReport)
        setReportInfo({
          url: body,
          retryCount: 0
        })
      }
    },
    onError: () => {
      dispatch(setReportStatus(ACTIONS.ERROR))
    }
  })

  useEffect(() => {
    setSelectAllEquip(false)
    setSelectAllProps(false)
  }, [selectedEquipmentType])

  useEffect(() => {
    dispatch(setRawDataProperties({ type: "Success", data: [] }))
    dispatch(setReportStatus(ACTIONS.RESET))
  }, [])

  useEffect(() => {
    if (
      buildingId &&
      selectedEquipmentType?.value !== "" &&
      equipments?.length > 0 &&
      selectedEquipmentType?.value !== CHILLER
    ) {
      fetchPropertiesByEquipmentId()
    }
  }, [buildingId, selectedEquipmentType, equipments])

  useEffect(() => {
    if (Boolean(selectedEquipmentType?.value)) {
      if (
        selectedEquipmentType?.value === CHILLER &&
        selectedEquipments?.length > 0 && equipments?.length > 0
      ) {
        const selectedEquipmentObjects = equipments?.filter((equipment) =>
          selectedEquipments?.includes(equipment?.id)
        )
        const chillerFilter = []
        let compCondTypeFilter = []
        let instanceFilter = []
        selectedEquipmentObjects?.forEach(({ compCondType, instance }) => {
          const selectedChillerFilter = []

          if (compCondType && isJsonString(compCondType)) {
            compCondTypeFilter = JSON.parse(
              compCondType?.replace(/'/g, "\"")
            )?.map((item) => ({ compCondType: { matchPhrasePrefix: item } }))
          } else if (compCondType && compCondType?.includes("[")) {
            compCondTypeFilter = [
              {
                compCondType: {
                  matchPhrasePrefix: compCondType.replace(/[\[\]]/g, "")
                }
              }
            ]
          } else if (compCondType && compCondType !== " ") {
            compCondTypeFilter = [
              { compCondType: { matchPhrasePrefix: compCondType } }
            ]
          }
          if (instance && isJsonString(instance)) {
            instanceFilter = JSON.parse(instance?.replace(/'/g, "\""))?.map(
              (item) => ({ instance: { eq: item } })
            )
          } else if (instance && isArray(instance)) {
            instanceFilter = instance?.map((item) => ({ instance: { eq: item } }))
          }

          if (compCondTypeFilter?.length > 0) {
            selectedChillerFilter?.push({ or: compCondTypeFilter })
          }
          if (instanceFilter?.length > 0) {
            selectedChillerFilter?.push({ or: instanceFilter })
          }
          if (selectedChillerFilter?.length > 0) {
            chillerFilter.push({ and: selectedChillerFilter })
          }
        })
        fetchPropertiesByEquipmentId(chillerFilter)
      }
    }
  }, [selectedEquipments, selectedEquipmentType])

  const filteredEquipments = useMemo(() => {
    const selectedActiveEquipMents = []
    let tempEquipment = equipments.reduce((acc, item) => {
      const newItem = { ...item }
      // Change the color if the equipment ID is in addedEquipments
      if (activeProperties?.addedEquipments?.includes(item.id)) {
        newItem.textColor = "blue"
        selectedActiveEquipMents.push(item?.id)
      }
      // Add the item to the accumulator
      acc.push(newItem)
      return acc
    }, [])
    // Filter based on the search text if it exists
    if (equipmentSearchText) {
      tempEquipment = tempEquipment.filter(({ name }) =>
        SearchRegex(name, equipmentSearchText)
      )
    }
    if (selectedActiveEquipMents?.length > 0) {
      dispatch(setSelectedEquipments(selectedActiveEquipMents))
    } else {
      if (
        selectedEquipment &&
        selectedEquipment?.type === selectedEquipmentType?.value
      ) {
        dispatch(setSelectedEquipments([selectedEquipment?.id]))
      }
    }

    // Sort the filtered (or unfiltered) equipment list
    tempEquipment = sortByAlphanumericSpecialChars("name", "Asc", tempEquipment)
    return tempEquipment
  }, [equipments, equipmentSearchText, activeProperties?.addedEquipments])

  const getPropertyAvilable = (chartID: any, targetKey: any) => {
    const legendObjects = chartsProps?.[chartID]?.legends || {}
    const timeLineLegends = chartsProps?.[chartID]?.timeLineLegends || {}
    const matchingLegendKey = Object.keys(legendObjects).find((key) =>
      key.includes(targetKey)
    )
    const timeLineKey = Object.keys(timeLineLegends).find((key) =>
      key.includes(targetKey)
    )
    if (matchingLegendKey) {
      return legendObjects[matchingLegendKey]?.show
    } else if (timeLineKey) {
      return timeLineLegends[timeLineKey]?.show
    }
  }

  const filteredProperties = useMemo(() => {
    const filteredProperties = []
    const propertiesRaw = properties || []
    let { selectedProperties = [], remainingProperties = [] } =
      propertiesRaw?.reduce(
        (acc, item) => {
          if (SearchRegex(item?.name, propertySearchText)) {
            const obj: any = {
              ...item,
              disabled: false
            }
            if (activeProperties?.addedProperties?.includes(item?.targetKey)) {
              if (getPropertyAvilable(chartID, item?.targetKey)) {
                obj.textColor = "blue"
                acc.selectedProperties.push(obj)
              } else {
                acc.remainingProperties.push(obj)
              }
            } else {
              acc.remainingProperties.push(obj)
            }
          }
          return acc
        },
        {
          selectedProperties: [],
          remainingProperties: []
        }
      )

    selectedProperties = sortByAlphanumericSpecialChars(
      NAME,
      ASC,
      selectedProperties
    )
    remainingProperties = sortByAlphanumericSpecialChars(
      NAME,
      ASC,
      remainingProperties
    )
    filteredProperties.push(...selectedProperties, ...remainingProperties)
    return filteredProperties
  }, [properties, propertySearchText, activeProperties])

  const fetchPropertiesByEquipmentId = async (filterVariable = null) => {
    dispatch(setRawDataProperties({ type: "Loading" }))
    try {
      const { data }: any = await API.graphql({
        query: getPropsByEquipmentType,
        variables: {
          filter:
            filterVariable && filterVariable?.length > 0
              ? {
                equipmentType: { eq: selectedEquipmentType?.value },
                isRDR: { eq: 1 },
                sourceEquipmentType: { eq: "NA" },
                sourceFamilyType: { eq: "NA" },
                or: [...filterVariable]
              }
              : {
                equipmentType: { eq: selectedEquipmentType?.value },
                isRDR: { eq: 1 },
                sourceEquipmentType: { eq: "NA" },
                sourceFamilyType: { eq: "NA" }
              }
        }
      })
      const uniqueProperties: any = uniqBy(
        data?.searchPropertyMetadata?.items,
        "tisDisplayName"
      )
      const newProperties = uniqueProperties?.map(
        ({
          id,
          targetKey,
          propertyType,
          tisDisplayName,
          targetMeasureUnit
        }) => {
          let propName = targetKey
          try {
            propName =
              propertyType === CHARACTERISTIC
                ? `${tisDisplayName} ***`
                : tisDisplayName
          } catch (error) {
            console.warn(error)
          }
          return {
            name: propName ? propName : targetKey,
            targetKey,
            id: id + propName,
            value: id + propName,
            propertyType,
            targetMeasureUnit
          }
        }
      )
      dispatch(setRawDataProperties({ type: "Success", data: newProperties }))
    } catch (e) {
      dispatch(setRawDataProperties({ type: "Failure" }))
    }
  }

  // **** Below method is invoked by ref **** /
  const generateReportHandler = () => {
    if (isValid && !isDataExceeded) {
      dispatch(setReportStatus(ACTIONS.LOADING))
      const equipids = equipments?.filter(({ id }) =>
        selectedEquipments.includes(id)
      )
      const propids = properties?.filter(({ id }) =>
        selectedProperties.includes(id)
      )
      const { parameterList, characteristicList } = propids?.reduce(
        (previousprops, { targetKey, propertyType }) => {
          if (propertyType === CHARACTERISTIC) {
            previousprops.characteristicList.push(targetKey)
          } else {
            previousprops.parameterList.push(targetKey)
          }
          return previousprops
        },
        { parameterList: [], characteristicList: [] }
      )

      const deviceSerial = {}
      for (const obj of equipids) {
        const { deviceId, name, id } = obj
        if (!deviceSerial[deviceId]) {
          deviceSerial[deviceId] = {}
        }
        deviceSerial[deviceId] = {
          ...deviceSerial[deviceId],
          [id]: name
        }
      }

      const formattedStartDate = getDateFormatWithEnglishLocale(startDate)
      const formattedEndDate = getDateFormatWithEnglishLocale(endDate)

      // let equipmentTypeUpdated = selectedEquipmentType?.value
      // if (equipmentTypeUpdated && equipmentTypeUpdated.includes("VAV Box")) {
      //     equipmentTypeUpdated = "VAV-BOX"
      // } else if (equipmentTypeUpdated && equipmentTypeUpdated.includes("Load Valve")) {
      //     equipmentTypeUpdated = "LoadValve"
      // }
      const { value, label } = selectedEquipmentType
      generateReportQuery({
        requestBody: JSON.stringify({
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          format: viewFormat,
          deviceSerial,
          parameterList,
          characteristicList,
          isWeather: includeWeatherData ? 1 : 0,
          isNonStandard: includeNonStandard ? 1 : 0,
          isOutOfRange: includeOutofRange ? 1 : 0,
          buildingId: { [buildingId]: buildingSummary?.name },
          equipment: { [value]: label },
          langDir: langDir,
          localTime: buildingSummary?.tz
            ? momentTimeZone().locale("en").tz(buildingSummary?.tz)?.format("LLLL")
            : momentTimeZone().locale("en").tz("UTC")?.format("LLLL"),
          report: "rdr",
          systemOfMeasure: selectedMetric?.metricCode,
          language: selectedLanguage?.langCode
        })
      })
    }
  }

  useEffect(() => {
    let timeout = null
    if (cancelled || !reportInfo.url || error) {
      clearTimeout(timeout)
    } else if (reportInfo.retryCount === 0) {
      timeout = setTimeout(() => {
        checkReportIsFound()
      }, 0)
    } else {
      timeout = setTimeout(() => {
        checkReportIsFound()
      }, RECHECK_INTERVAL)
    }
    return () => {
      // clears timeout before running the new effect
      clearTimeout(timeout)
    }
  }, [cancelled, reportInfo])

  const checkReportIsFound = async () => {
    try {
      const response = await fetch(reportInfo.url)
      if (response?.status === 404) {
        setReportInfo({
          ...reportInfo,
          retryCount: reportInfo.retryCount + 1
        })
        if (reportInfo.retryCount + 1 >= RETRY_COUNT) {
          dispatch(setReportStatus(ACTIONS.ERROR))
        }
      } else if (response?.status === 200) {
        dispatch(setReportStatus(ACTIONS.COMPLETE))
        downloadFileUsingS3(reportInfo.url, buildingSummary?.name)
      } else {
        dispatch(setReportStatus(ACTIONS.ERROR))
      }
    } catch (e) {
      console.log(e)
      dispatch(setReportStatus(ACTIONS.ERROR))
    }
  }

  const handleCheckAction = (name: string, checked: boolean) => {
    dispatch(
      setReportCheck({
        [name]: checked
      })
    )
  }

  const onEquipmentInputSearch = (value) => {
    dispatch(setEquipmentSearchText(value))
  }

  const onPropertyInputSearch = (value) => {
    dispatch(setPropertySearchText(value))
  }

  const onViewFormatChange = (format: string) => {
    if (viewFormat === IsMultipleSingleFormat) {
      dispatch(setSelectedProperties([]))
    }
    if (format === IsMultipleSingleFormat) {
      handleCheckAction("includeWeatherData", false)
      dispatch(setSelectedProperties([]))
      setSelectAllProps(false)
    }
    dispatch(setReportFormat(format))
    dispatch(setReportStatus(ACTIONS.RESET))
  }

  // ***** for ALL check **
  const onselectProperties = (checked: boolean) => {
    dispatch(setReportStatus(ACTIONS.RESET))
    const updateSelectedProperties = checked
      ? filteredProperties?.map(({ id }) => id)
      : []
    dispatch(setSelectedProperties(updateSelectedProperties))
    setSelectAllProps((prev) => !prev)
  }

  const handleChangeEquipment = (checked, equipId: string) => {
    dispatch(setReportStatus(ACTIONS.RESET))
    let updateSelectedEquipments = []
    if (equipId === "all-check") {
      updateSelectedEquipments = checked
        ? filteredEquipments?.map(({ id }) => id)
        : []
      dispatch(setSelectedEquipments(updateSelectedEquipments))
      setSelectAllEquip((prev) => !prev)
    } else {
      updateSelectedEquipments = checked
        ? [...selectedEquipments, equipId]
        : selectedEquipments.filter((id: string) => id !== equipId)
      dispatch(setSelectedEquipments(updateSelectedEquipments))
      const isSelected = updateSelectedEquipments.length
        ? filteredEquipments?.length === updateSelectedEquipments?.length
          ? true
          : null
        : false
      setSelectAllEquip(isSelected)
    }
  }

  const handleChangeProperty = (checked, equipId: string, type?: string) => {
    dispatch(setReportStatus(ACTIONS.RESET))
    if (type === "radio") {
      dispatch(setSelectedProperties([equipId]))
    } else if (equipId === "all-check") {
      onselectProperties(checked)
    } else {
      const updatedProperties = checked
        ? [...selectedProperties, equipId]
        : selectedProperties.filter((id: string) => id !== equipId)
      dispatch(setSelectedProperties(updatedProperties))
      const isSelectedProperties = updatedProperties.length
        ? filteredProperties?.length === updatedProperties?.length
          ? true
          : null
        : false
      setSelectAllProps(isSelectedProperties)
    }
  }

  const leftOptions = [
    {
      label: t("reports.standardXlsx"),
      name: "report format",
      value: "xlsx",
      radioId: "standard",
      testId: "rdr-standard-xlsx-radio-input"
    },
    {
      label: t("reports.multiStandard"),
      name: "report format",
      value: "mxlsx",
      radioId: "single",
      testId: "rdr-single-mxlsx-radio-input"
    },
    {
      label: t("reports.standardCsv"),
      name: "report format",
      value: "csv",
      radioId: "standardCsv",
      testId: "rdr-standard-csv-radio-input"
    }
  ]

  const rightOptions = [
    {
      label: t("reports.includeWeatherData"),
      name: "includeWeatherData",
      id: "include-weather",
      checked: includeWeatherData,
      disabled: viewFormat === IsMultipleSingleFormat
    },
    {
      label: t("reports.includeOutofRange"),
      name: "includeOutofRange",
      id: "out-of-range",
      checked: includeOutofRange
    },
    {
      label: t("reports.includeNonStandard"),
      name: "includeNonStandard",
      id: "non-standard",
      checked: includeNonStandard
    }
  ]
  const config = [
    {
      title: t("reports.selectSystemOrEquipment"),
      data: filteredEquipments,
      mainData: equipments,
      loading: equipLoading,
      handleChange: handleChangeEquipment,
      footerConfig: {
        type: "radio",
        options: leftOptions,
        selected: viewFormat,
        handleChange: onViewFormatChange
      },
      searchText: equipmentSearchText,
      selectedItems: selectedEquipments,
      onChangeSearch: onEquipmentInputSearch,
      isAllChecked: selectAllEquip
    },
    {
      title: t("reports.selectPropertiesAndCharacteristics"),
      data: filteredProperties,
      mainData: properties,
      loading: propsLoading,
      handleChange: handleChangeProperty,
      footerConfig: {
        type: "checkbox",
        options: rightOptions,
        handleChange: ({ target: { checked, name } }) => {
          handleCheckAction(name, checked)
        }
      },
      searchText: propertySearchText,
      selectedItems: selectedProperties,
      onChangeSearch: onPropertyInputSearch,
      isAllChecked: selectAllProps,
      showSelectedCount: true,
      characteristicsInfo: t("reports.characteristicsInfo"),
      emptyMessage: t("reports.propertiesEmptyMessage"),
      showEmptyMessage: isEmpty(selectedEquipments),
      disableSelectAll: viewFormat === IsMultipleSingleFormat,
      listType: viewFormat === IsMultipleSingleFormat ? "radio" : "checkbox",
      translateValues: true
    }
  ]
  useEffect(() => {
    if (selectedEquipment) {
      const chartId = Object.keys(chartsFilter).find(
        (key: any) => chartsFilter[key].isFirst
      )
      setChartID(chartId)
      getChartPropertys(chartId)
    }
  }, [chartsFilter])

  const getChartPropertys = (chartId: any) => {
    const chartFilter = chartsFilter[chartId]
    const defaultEquipment = chartFilter?.selectedTypeEquipment?.value
    const added = chartFilter?.added
    const details: any = {
      defaultEquipment:
        isString(defaultEquipment) || !Boolean(defaultEquipment)
          ? null
          : defaultEquipment,
      addedEquipments: [],
      addedProperties: [],
      axes: [],
      dimensions: [],
      other: {
        configPayloadStructured: {}
      }
    }
    const payloadObject = structureFromConfig(
      chartFilter?.chartType?.value,
      chartFilter?.selectedTypeEquipment,
      buildingId,
      chartFilter?.chillerApplicableProps,
      chartFilter?.equipmentProperties
    )
    details.other.configPayloadStructured = payloadObject
    const equipmentTypeList = payloadObject?.equipment || []
    equipmentTypeList?.forEach((obj) => {
      const equipmentIDs = obj?.eId?.map((obj) => obj?.id)
      details.addedEquipments.push(...equipmentIDs)
      details.addedProperties.push(...(obj?.pId || []))
    })
    const filteredAxes =
      payloadObject?.axes?.filter(
        (obj) => obj?.axisType?.toLowerCase() === "y"
      ) || []

    details.axes.push(...filteredAxes)
    details.dimensions = configListDimensions?.data || []
    details.other.chartFilter = chartFilter

    if (added) {
      const addedAxisList = (added?.axisList || [])
      addedAxisList?.forEach((obj) => {
        const added = obj?.added || {}
        Object.keys(added)?.forEach((equipType) => {
          const addedLegends = added?.[equipType] || []
          addedLegends?.forEach((item) => {
            const arr = (item || "")?.split(ITEM_SEPERATION)
            const eID = arr?.[0] || ""
            const pID = arr?.[1] || ""

            if (Boolean(pID)) {
              details.addedProperties.push(pID)
            }
            if (Boolean(eID)) {
              details.addedEquipments.push(eID)
            }
          })
        })
      })
      details.addedEquipments = uniq(details?.addedEquipments)
      details.addedProperties = uniq(details?.addedProperties)

      const yAxisList = addedAxisList?.filter((obj) =>
        possibleYAxesNames?.includes(obj?.axisName)
      )

      yAxisList?.forEach((obj) => {
        if (!details.axes?.find((o) => o?.axisName === obj?.axisName)) {
          details.axes.push(obj)
        }
      })
    }
    setActiveProperties(details)
    setSelectedAxes(details?.axes?.map((obj) => obj?.UoM) || [])
  }

  return (
    <div className={clsx(styles.rawDataWrapper)}>
      {selectedEquipmentType?.value !== "" ? (
        <>
          <ReportList config={config} />
        </>
      ) : (
        <EmptyReport message="Select your report options above" />
      )}
    </div>
  )
})

RawDataReport.displayName = "RawDataReport"
export default RawDataReport
