import {
  configureStore,
  combineReducers,
  getDefaultMiddleware
} from "@reduxjs/toolkit"
import reduxThunk from "redux-thunk"
import logger from "redux-logger"
import awsConfig from "../aws-exports.js"
// import { RootState } from "./RootState"
import {
  sessionSlice,
  homeSlice,
  buildingSummarySlice,
  equipmentSummarySlice,
  reportSlice,
  performanceSlice,
  translationSlice,
  translationCombinedSlice,
  applicationSlice
} from "./slicers"
import { userData } from "./slicers/userData"
import { useDispatch, useSelector } from "react-redux"
import type { TypedUseSelectorHook } from "react-redux";
import { RootState } from "./RootState"

const reducer = combineReducers({
  userData: userData.reducer,
  application: applicationSlice.reducer,
  session: sessionSlice.reducer,
  home: homeSlice.reducer,
  building: buildingSummarySlice.reducer,
  equipment: equipmentSummarySlice.reducer,
  reports: reportSlice.reducer,
  performance: performanceSlice.reducer,
  translationSlice: translationSlice.reducer,
  translationCombinedSlice: translationCombinedSlice.reducer
})
const isLocalHost = window?.location?.host?.includes("localhost:") ?? false
const isDev = awsConfig?.appEnvironment?.BUILD_ENV === "dev"


const middleware = [...getDefaultMiddleware({ serializableCheck: false }), reduxThunk]
const store = configureStore({
  reducer,
  middleware: isDev ? isLocalHost ? middleware : [...middleware, logger] : middleware,
  devTools: isDev
})

export type AppDispatch = typeof store.dispatch
export type TStore = ReturnType<typeof store.getState>
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store
