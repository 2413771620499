import { getConfig } from "src/config"
import { COMMAND_CENTER_PARETO_CHART_REDIRECTION } from "src/constants/common"
import { CHART_ID_PARAM } from "../../constant"
import { END_DATE, START_DATE } from "src/blitz/DateRange/constant"
import {
  format,
  isBefore,
  isSameDay,
  subDays,
  addDays,
  parse,
  getTime
} from "date-fns"
import { split, toNumber } from "lodash"
import { getChartId } from "src/components/equipmentSummary/helper"

const URL_DATE_FORMAT = "MM-dd-yyyy"
const CRITICAL_COLOR = "#FF001A"
const CAUTIONARY_COLOR = "#F4FF00"
const ATS_COLOR = "#FFFFFF"
const ATS_EXPORT = "#808080"
const chartDateFormat = "yyyy/MM/dd"
const criticalColor = ["ur", "lr", "ll_ul"]
export const tranformParetoChart = (propsObj: any = {}) => {
  const {
    inputData,
    selectedTypeEquipment: equipmentType,
    chartInfo = {},
    buildingId = "",
    buildingName = "",
    startDate = "",
    endDate = "",
    listAnalyticConfigs = {},
    equipmentProperties = {},
    equipmentList,
    isHeadLess,
    bc = "black"
  } = propsObj

  const response = inputData?.data?.pareto || {}

  const chartTitle = chartInfo?.title || "Daily Run Time"

  const { externalLinks } = getConfig()
  const absoluteURL =
    externalLinks?.[COMMAND_CENTER_PARETO_CHART_REDIRECTION]?.absoluteURL
  // const summaryURL = `${absoluteURL}/building/${buildingId}/summary`
  const summaryURL = `/building/${buildingId}/summary`

  const equipmentIndexes = Object.entries(response?.index || {})

  const automatedTestKeys = response?.at || {}
  const data = response?.results || {}

  const automatedTestKey = automatedTestKeys?.[chartTitle]

  const equipMentTypeAnalitics = listAnalyticConfigs[equipmentType] || []
  const exceptionInput = equipMentTypeAnalitics.find((input) => {
    return input.uiATName === chartTitle
  })
  const endTimestamp = parse(endDate, chartDateFormat, new Date())

  const axis = { ...(chartInfo?.axisConfig?.[0] || {}) }
  const resolution = axis?.resolution
  const range =
    axis?.UoM?.toLowerCase() === "percent" ||
    axis?.symbol === "percent" ||
    axis?.symbol === "%"
      ? [0, 100]
      : []
  axis.range = range
  axis.axisName = "y1"
  axis.axisType = "y"

  const removedSpacesChartTitle = (chartTitle || "").replace(/ /g, "")

  const commonFieldsLegend = {
    axisName: "y1",
    isAdded: false,
    lineType: "solid",
    shape: "dot",
    shapeSize: "1",
    show: true,
    strokeWidth: "1",
    symbol: axis?.symbol,
    type: "line",
    visible: true
  }
  const limits: any = {
    critical: "CriticalLimit",
    cautionary: "CautionaryLimit",
    uy: "UpperCautionaryLimit",
    ur: "UpperCriticalLimit",
    ly: "LowerCautionaryLimit",
    lr: "LowerCriticalLimit",
    v: "AutomatedTestResult"
  }
  const limitsAvailability: any = {
    ll_ul: false,
    ur: false,
    uy: false,
    lr: false,
    ly: false,
    v: false
  }
  const legends = {
    [`${removedSpacesChartTitle}CriticalLimit`]: {
      color: CRITICAL_COLOR,
      id: `${removedSpacesChartTitle}CriticalLimit`,
      name: `#$ ${chartTitle} Critical Limit`,
      chartSpecificProps: {
        name: "critical"
      },
      ...commonFieldsLegend
    },
    [`${removedSpacesChartTitle}CautionaryLimit`]: {
      color: CAUTIONARY_COLOR,
      id: `${removedSpacesChartTitle}CautionaryLimit`,
      name: `#$ ${chartTitle} Cautionary Limit`,
      chartSpecificProps: {
        name: "cautionary"
      },
      ...commonFieldsLegend
    },
    [`${removedSpacesChartTitle}UpperCautionaryLimit`]: {
      color: CAUTIONARY_COLOR,
      id: `${removedSpacesChartTitle}UpperCautionaryLimit`,
      name: `#$ ${chartTitle} Upper Cautionary Limit`,
      chartSpecificProps: {
        name: "uy"
      },
      ...commonFieldsLegend
    },
    [`${removedSpacesChartTitle}UpperCriticalLimit`]: {
      color: CRITICAL_COLOR,
      id: `${removedSpacesChartTitle}UpperCriticalLimit`,
      name: `#$ ${chartTitle} Upper Critical Limit`,
      chartSpecificProps: {
        name: "ur"
      },
      ...commonFieldsLegend
    },
    [`${removedSpacesChartTitle}LowerCautionaryLimit`]: {
      color: CAUTIONARY_COLOR,
      id: `${removedSpacesChartTitle}LowerCautionaryLimit`,
      name: `#$ ${chartTitle} Lower Cautionary Limit`,
      chartSpecificProps: {
        name: "ly"
      },
      ...commonFieldsLegend
    },
    [`${removedSpacesChartTitle}LowerCriticalLimit`]: {
      color: CRITICAL_COLOR,
      id: `${removedSpacesChartTitle}LowerCriticalLimit`,
      name: `#$ ${chartTitle} Lower Critical Limit`,
      chartSpecificProps: {
        name: "lr"
      },
      ...commonFieldsLegend
    },
    [`${removedSpacesChartTitle}AutomatedTestResult`]: {
      color: isHeadLess && bc === "white" ? ATS_EXPORT : ATS_COLOR,
      id: `${removedSpacesChartTitle}AutomatedTestResult`,
      name: "#$ Automated Test Result",
      chartSpecificProps: {
        name: "ats"
      },
      ...commonFieldsLegend
    }
  }

  let transformedData = []

  let currentTimestamp = parse(startDate, chartDateFormat, new Date())
  while (
    isSameDay(currentTimestamp, endTimestamp) ||
    isBefore(currentTimestamp, endTimestamp)
  ) {
    const dateKey = format(currentTimestamp, "yyy-MM-dd")

    const dateDataObj = data?.[dateKey] || {}
    Object.keys(dateDataObj)?.forEach((key) => {
      const obj = dateDataObj?.[key]
      const dataObj = obj?.[automatedTestKey] || {}
      const dataObjKeys: any = Object.keys(dataObj)

      const criticalLimit = isFinite(toNumber(dataObj?.ll_ul))
      const criticalUpperIsFinite = isFinite(toNumber(dataObj?.ur))
      const cautionaryUpperIsFinite = isFinite(toNumber(dataObj?.uy))
      const criticalLowerIsFinite = isFinite(toNumber(dataObj?.lr))
      const cautionaryLowerIsFinite = isFinite(toNumber(dataObj?.ly))
      const atsIsFinite = isFinite(toNumber(dataObj?.v))

      const anyOneIsAvailable =
        criticalUpperIsFinite ||
        cautionaryUpperIsFinite ||
        criticalLowerIsFinite ||
        cautionaryLowerIsFinite ||
        atsIsFinite

      const isCriticalAvailable =
        (dataObjKeys || [])?.find((key) => key === "ll_ul") && criticalLimit

      if (isCriticalAvailable || anyOneIsAvailable) {
        const equipmentID = equipmentIndexes?.find(
          (arr) => arr?.[1] === key
        )?.[0]
        const equipmentObj = equipmentList?.find(
          (obj) => obj?.id === equipmentID
        )
        const equipmentName = equipmentObj?.name || ""
        let buildingPath = summaryURL
        // let equipmentPath = `${absoluteURL}/building/${buildingId}/equipment/${equipmentID}/performance`
        let equipmentPath = `/building/${buildingId}/equipment/${equipmentID}/performance`

        const urlEndDate = format(currentTimestamp, URL_DATE_FORMAT)
        const urlStartDateBuilding = format(
          subDays(currentTimestamp, 14),
          URL_DATE_FORMAT
        )

        const urlStartDateEquipment = format(
          subDays(currentTimestamp, 7),
          URL_DATE_FORMAT
        )

        const buildingSP = new URLSearchParams()
        const equipmentSP = new URLSearchParams()

        let chartId = ""
        if (exceptionInput) {
          const equipProps =
            equipmentProperties?.[equipmentObj?.type]?.[equipmentID] || []
          chartId = getChartId(exceptionInput, equipProps)
        }
        buildingSP.set(START_DATE, urlStartDateBuilding)
        buildingSP.set(END_DATE, urlEndDate)

        equipmentSP.set(START_DATE, urlStartDateEquipment)
        equipmentSP.set(END_DATE, urlEndDate)

        equipmentSP.set(CHART_ID_PARAM, chartId)

        buildingPath = buildingPath + "?" + buildingSP.toString()
        equipmentPath = equipmentPath + "?" + equipmentSP.toString()
        if (!chartId) {
          equipmentPath = ""
        }
        const tm = isFinite(toNumber(dataObj?.ts))
          ? toNumber(dataObj?.ts)
          : 85500
        const timeString = timeNumberToString(tm)
        const concatDt = `${dateKey} ${timeString}`
        const parsedDt = parse(concatDt, "yyyy-MM-dd hh:mm a", new Date())
        const timestamp = getTime(parsedDt)
        const dataOccurance: any = {
          facility: buildingName,
          facilityUrl: buildingPath,
          equipmentName: equipmentName,
          equipmentUrl: equipmentPath,
          equipmentId: equipmentID,
          timestamp,
          limits: [],
          uom: axis?.symbol
        }
        if (atsIsFinite) {
          let value = dataObj?.v

          if (resolution) {
            const decimalPlaces =
              split(axis?.resolution || "", ".")?.[1]?.length || 0
            const formattedVal = toNumber(dataObj?.v).toFixed(decimalPlaces)
            value = formattedVal
          }

          const atsObj = {
            color: isHeadLess && bc === "white" ? ATS_EXPORT : ATS_COLOR,
            value: value,
            name: "ats"
          }
          dataOccurance.value = atsObj
          limitsAvailability.v = true
        }
        if (isCriticalAvailable) {
          const criticalObj = {
            value: dataObj?.["ll_ul"],
            name: "critical",
            zIndex: 1,
            color: CRITICAL_COLOR
          }
          limitsAvailability.ll_ul = true
          dataOccurance.limits.push(criticalObj)
        } else if (anyOneIsAvailable) {
          const limitKeys = Object.keys(limits)?.filter(
            (key) => key !== "critical" && key !== "cautionary" && key !== "v"
          )
          limitKeys?.forEach((key: string) => {
            if (isFinite(toNumber(dataObj?.[key]))) {
              const criticalObj = {
                value: dataObj?.[key],
                name: key,
                zIndex: criticalColor?.includes(key) ? 0 : 1,
                color: criticalColor?.includes(key)
                  ? CRITICAL_COLOR
                  : CAUTIONARY_COLOR
              }
              dataOccurance.limits.push(criticalObj)
              limitsAvailability[key] = true
            }
          })
        }
        transformedData.push(dataOccurance)
      }
    })
    currentTimestamp = addDays(currentTimestamp, 1)
  }

  const newLegends: any = {}

  if (limitsAvailability?.ll_ul) {
    const id = `${removedSpacesChartTitle}${limits?.critical}`
    newLegends[id] = legends?.[id]
  } else if (
    limitsAvailability?.lr &&
    limitsAvailability?.ly &&
    limitsAvailability?.ur &&
    limitsAvailability?.uy
  ) {
    let id = `${removedSpacesChartTitle}${limits?.ur}`
    newLegends[id] = legends?.[id]
    id = `${removedSpacesChartTitle}${limits?.uy}`
    newLegends[id] = legends?.[id]
    id = `${removedSpacesChartTitle}${limits?.lr}`
    newLegends[id] = legends?.[id]
    id = `${removedSpacesChartTitle}${limits?.ly}`
    newLegends[id] = legends?.[id]
  } else if (
    (limitsAvailability?.lr && limitsAvailability?.ly) ||
    (limitsAvailability?.ur && limitsAvailability?.uy)
  ) {
    let id = `${removedSpacesChartTitle}${limits?.critical}`
    newLegends[id] = legends?.[id]
    id = `${removedSpacesChartTitle}${limits?.cautionary}`
    newLegends[id] = legends?.[id]
  }

  if (limitsAvailability?.v) {
    const id = `${removedSpacesChartTitle}${limits?.v}`
    newLegends[id] = legends?.[id]
  }

  transformedData = transformedData?.map((obj) => {
    const newObj = {
      ...(obj || {})
    }

    const newLimits = newObj?.limits?.map((limitObj) => {
      const newLimitObj = {
        ...limitObj
      }
      if (
        !limitsAvailability?.ll_ul &&
        !(
          limitsAvailability?.lr &&
          limitsAvailability?.ly &&
          limitsAvailability?.ur &&
          limitsAvailability?.uy
        )
      ) {
        if (newLimitObj.name === "lr" || newLimitObj?.name === "ur") {
          newLimitObj.name = "critical"
        }
        if (newLimitObj.name === "ly" || newLimitObj?.name === "uy") {
          newLimitObj.name = "cautionary"
        }
      }
      return newLimitObj
    })

    newObj.limits = newLimits

    return newObj
  })
  const noDataAvailable = !(
    limitsAvailability.ll_ul ||
    limitsAvailability.ur ||
    limitsAvailability.lr ||
    limitsAvailability.ly ||
    limitsAvailability.v
  )
  return {
    chartProps: {
      buildingName,
      legends: newLegends,
      axisList: [axis],
      limitsAvailability,
      resolution,
      noDataAvailable: noDataAvailable,
      isdataprocessed : true
    },
    chartData: {
      rawData: inputData,
      data: transformedData
    }
  }
}

export const timeNumberToString = (totalSeconds = 0) => {
  const minutes = Math.floor((totalSeconds % 3600) / 60)
  let hours = Math.floor(totalSeconds / 3600)
  const period = hours >= 12 ? "PM" : "AM"
  hours = hours % 12
  hours = hours ? hours : 12
  const timeString = `${hours}:${minutes.toString().padStart(2, "0")} ${period}`

  return timeString
}
